import moment from "moment";
import { useState } from "react";
import FindPatient from "../../_generic/components/FindPatient";

function SearchForAppointment({ onHide, calendar, onShow }) {
  const [patientBookings, setPatientBookings] = useState([]);

  const onChange = (patient) => {
    if (!patient) {
      setPatientBookings([]);
      return;
    }

    const patientID = patient._id;

    const foundBookings = calendar.filter((c) => c.patient._id === patientID);

    setPatientBookings(foundBookings);
  };

  return (
    <div className="searchForAppointment">
      <div>
        <i onClick={onHide} className="close fa-solid fa-circle-xmark"></i>
        <h2>Find Patient</h2>
        <FindPatient onChange={onChange} />
        <div className="patientBookingList">
          {patientBookings.sort(function(a, b){
  return moment(b.startDateTime).format('X')-moment(a.startDateTime).format('X')
}).map((pb) => {
            return <PatientBookingRow onShow={onShow} key={pb._id} booking={pb} />;
          })}
        </div>
      </div>
    </div>
  );
}

function PatientBookingRow({ booking, onShow}) {
  return (
    <div className="flexcenter" onClick={() => onShow(booking)}>
      <div>
        <i className="fa-solid fa-calendar-days"></i>
      </div>
      <div className="flexgrow">
        <div className="title">{booking.title}</div>
        <div className="startTime">{moment.utc(booking.startDateTime).format("Do MMM YYYY [at] hh:mma")}</div>
      </div>
      <div>
      <i className="fa-solid fa-chevron-right"></i>
      </div>
    </div>
  );
}

export default SearchForAppointment;
