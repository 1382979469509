import React from "react";

function EmailCellRenderer(params) {
  return (
    <React.Fragment>
      <i className="fas fa-envelope" style={{ marginRight: "5px" }} />{" "}
      {params.value}
    </React.Fragment>
  );
}

export default EmailCellRenderer;
