import React, { useState } from "react";
import ImageView from "../ImageView";

function NotesListAttachments({ attachments }) {
  const [largeViewSource, setLargeViewSource] = useState();

  return (
    <React.Fragment>
      {largeViewSource && (
        <ImageView
          src={largeViewSource}
          onHide={() => setLargeViewSource(null)}
        />
      )}
      <div className="notesAttachments">
        {attachments.map((at) => (
          <div
            className="square coverimage"
            style={{ backgroundImage: "url(" + at + ")" }}
            onClick={() => setLargeViewSource(at)}
          ></div>
        ))}
      </div>
    </React.Fragment>
  );
}

export default NotesListAttachments;
