import { callAPI } from "./api";

function createUser(accountObject) {
  return callAPI("/auth", { method: "POST", body: { user: accountObject } });
}

function resetPassword(email) {
  return callAPI("/auth", {
    method: "PUT",
    body: {
      type: "resetPassword",
      email,
      callbackURL: window.location.origin + "/changePassword",
    },
  });
}

function changePassword(email, codeGiven, newPassword) {
  return callAPI("/auth", {
    method: "PUT",
    body: { type: "changePassword", email, codeGiven, newPassword },
  });
}

function attemptLogin(loginObject) {
  return callAPI("/auth", {
    method: "PUT",
    body: { type: "login", user: loginObject },
  });
}

export { createUser, resetPassword, changePassword, attemptLogin };
