import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SettingSection from "../_generic/SettingsSection";
import ReferAFriend from "./components/ReferAFriend";
import CopyAssessmentURL from "./components/CopyAssessmentURL";
import GrafoButton from "../../../_generic/GrafoButton";
import React, { useEffect, useState } from "react";
import EditProfile from "./components/EditProfile";
import moment from "moment";
import {useUI} from "../../../../_generic/UIContext";
import { useData } from "../../_generic/contexts/DataContext";
import CopyBookingURL from "./components/CopyBookingURL";

function YourProfile() {
  const UI = useUI();
  const data = useData();
  const user = data.user;

  const [signatureConsent, setSignatureConsent] = useState(false);
  const [selectedForm, setSelectedForm] = useState("");
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initialize = async () => {
    const forms = await data.getFormTemplates();
    setFormData(forms);
  };

  const url =
  window.location.origin +
  "/new?physio=" +
  user._id +
  (signatureConsent ? "&signatureConsent=true" : "") +
  ("&form=" + selectedForm);

  return (
    <React.Fragment>
      <SettingSection title="Refer a Customer">
        <ReferAFriend user={user} UI={UI} />
      </SettingSection>

      {user.isCalendarEnabled && <SettingSection title="Appointment Bookings">
        <CopyBookingURL userID={user._id} UI={UI} />
      </SettingSection>}

      <SettingSection title="Your Assessment URL">
        Invite your clients to fill in their details before your first
        appointment. Copy the link below.
        <div style={{ marginTop: "20px" }}>
          <FormControl style={{ display: "inline-block" }}>
            <TextField value={url} style={{ marginRight: "15px" }} />
          </FormControl>
          <FormControl style={{ display: "inline-block" }}>
            {!selectedForm && (
              <InputLabel id="demo-simple-select-label">No Form</InputLabel>
            )}
            <Select
              placeholder="Select Form"
              value={selectedForm || ""}
              onChange={(e) => setSelectedForm(e.target.value)}
              style={{ minWidth: "200px" }}
            >
              {formData.map((part) => (
                <MenuItem key={part._id} value={part._id}>
                  {part.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ display: "block" }}>
            <FormControlLabel
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                display: "block",
              }}
              control={
                <Checkbox
                  onChange={(e) => {
                    setSignatureConsent(e.target.checked);
                  }}
                  checked={signatureConsent}
                />
              }
              label={
                "Ask for consent via a digital signature rather than a checkbox?"
              }
            />
          </FormControl>
          <CopyAssessmentURL url={url} />
        </div>
      </SettingSection>
      <SettingSection title="Profile Details">
        <EditProfile />
      </SettingSection>

      <SettingSection title="Your Account">
        <div className="accountSection" style={{ marginBottom: "30px" }}>
          <div className="subTitle">{user.firstName + " " + user.lastName}</div>
          <div className="date">
            Joined on {moment.utc(user.creation).format("Do MMMM YYYY")}
          </div>
        </div>

        <GrafoButton
          onClick={() => {
            localStorage.removeItem("grafoToken");
            window.location.href = "/login";
          }}
        >
          Logout
        </GrafoButton>
        <GrafoButton
          onClick={() =>
            (window.location.href = "https://www.grafonote.com/contact")
          }
          style={{
            backgroundColor: "#bebebe",
            color: "grey",
            marginLeft: "15px",
          }}
        >
          Get Help
        </GrafoButton>
      </SettingSection>
    </React.Fragment>
  );
}

export default YourProfile;
