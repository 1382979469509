import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

function RemoveConfirmation({ onHide, onConfirm }) {
    return (
      <Dialog
        open={true}
        onClose={onHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this question?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once you delete this question, we can't bring it back.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onHide}>No, Cancel</Button>
          <Button style={{ color: "var(--blue)" }} onClick={onConfirm} autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  export default RemoveConfirmation;