
async function loadServices(setServices, UI, dataContext) {
  //load it
  try {
    const foundServices = await dataContext.getServices();
    setServices(foundServices.sort((a, b) => a.title.localeCompare(b.title)));
  } catch (err) {
    UI.showToast(
      err?.message ||
        err ||
        "There is an error with loading services, please contact support"
    );
    
  }
}

async function saveServices(serviceList, UI, dataContext, setServices) {
  try {
    UI.showLoading();

    //check for errors
    let newServiceList = serviceList.filter(sl => {
        if(sl.status === "archived" && sl._id.indexOf("new") > -1){
            return false;
        }

        if(sl.title.trim() === ""){
            throw new Error("Please make sure that you fill in a Service Name for each service");
        }

        return true;
    })


    const data = await dataContext.updateServices(newServiceList);
    setServices(data);

    UI.hideLoading();
    UI.showSuccess();

  } catch (err) {
    UI.hideLoading();
    UI.showToast(err?.message || err);
  }
}

//e.g GBP
async function updateCurrency(currencyString, DataContext) {
  await DataContext.updateUserObject({ currency: currencyString });
}

let minuteOptions = [15, 30, 45, 60, 90, 120, 150, 180];

minuteOptions = minuteOptions.map((opt) => {
  let label;

  const timeInHours = opt / 60;
  const fullHours = Math.floor(timeInHours);
  const leftoverMinutes = timeInHours - fullHours;

  if (fullHours > 0) {
    if (leftoverMinutes > 0) {
      label = fullHours + "h " + leftoverMinutes * 60 + "m";
    } else {
      label = fullHours + " hour" + (fullHours > 1 ? "s" : "");
    }
  } else {
    label = opt + " mins";
  }

  return { label, value: opt };
});

export { loadServices, minuteOptions, updateCurrency, saveServices };
