import { Tabs, Tab } from "@mui/material";
import settingTabs from "./_generic/settingsTabs";
import React, { useState } from "react";
import isMobile from "../../../services/isMobile";
import { useData } from "../_generic/contexts/DataContext";

function SettingsMain() {
  const [selected, setSelected] = useState(settingTabs[0].key);

  const data = useData();
  const user = data.user;

  const SelectedComponent = settingTabs.find(
    (s) => s.key === selected
  ).component;

  return (
    <React.Fragment>
      <Tabs
        value={selected}
        onChange={(event, newvalue) => {
          setSelected(newvalue);
        }}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
        className="viewTabs profileTabs"
      >
        {settingTabs
          .filter((itm) => (itm.filter ? itm.filter(user) : true))
          .map((itm, key) => {
            return (
              <Tab
                style={{ textTransform: "none", fontWeight: 600 }}
                key={key}
                value={itm.key}
                label={
                  isMobile && itm.mobileLabel ? itm.mobileLabel : itm.label
                }
              />
            );
          })}
      </Tabs>
      <SelectedComponent />
    </React.Fragment>
  );
}

export default SettingsMain;
