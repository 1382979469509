import { getServices } from "../../../../../services/services";

async function getServicesData(services, setServices, UI, updateServices, override = false) {
  let returnData = [];

  if (!override && services) {
    return services;
  }

  UI.showLoading();
  const { data, status } = await getServices();
  returnData = data;
  UI.hideLoading();

  if (status !== 200) {
    throw new Error(
      data.error ||
        data.message ||
        "There was an error in loading the services, please contact support"
    );
  }

  //if empty, insert the first one
  if (!returnData.length && updateServices) {
    UI.showLoading();
    const firstInsert = {
      _id: "new_" + new Date().valueOf(),
      title: "Initial Assessment",
      durationInMins: 30,
      price: 20,
      description:
        "This is an initial introductory session to learn more about you and decide what the next steps are.",
    };

    const firstInsertedData = await updateServices([firstInsert]);
    returnData = firstInsertedData;
    UI.hideLoading();
  }

  setServices(returnData);

  return returnData;
}

export default getServicesData;
