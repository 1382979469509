const paymentPlanTemplate = [
    {
        key:"basic",
        name: "Basic plan",
        description: "Basic features & limited accessibility",
        price: 19.99,
        callToAction: "Downgrade",
        features: [
            "15% discount for yearly",
            "Desktop & Mobile access",
            "Assessment notes",
            "Unlimited form templates",
            "Custom assessment link",
            "Complete Patient Management",
            "Unlimited Free Support"
        ],
        isEnabled: (user) =>  !user.isCalendarEnabled
    },
    {
        key:"pro",
        name: "Pro plan",
        description: "Access to all features",
        tag: "Popular",
        callToAction:"Upgrade",
        icon: "fa-solid fa-bolt",
        price: 29.99,
        features: [
            "All from the Basic Plan",
            "Online booking for patients",
            "Manage appointments",
            "Add services",
            "Add opening hours",
            "Customize your currency",
            "Change your timezone"
        ],
        isEnabled: (user) =>  user.isCalendarEnabled
    }
]

export default paymentPlanTemplate;