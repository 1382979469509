import { getTempStatus } from "../../../Admin/logic/transformStatus";
import React from "react";

function StatusCellRenderer(params){

    const isActive = params.value === "active";
    const { status } = getTempStatus(params.row);

    return (
        <React.Fragment>
          <span
            style={{ fontWeight: 600, color: isActive ? "green" : "red" }}
          >
            <i className={"fas " + (isActive ? "fa-check" : "fa-times")} />{" "}
            {status || "INACTIVE"}
          </span>
        </React.Fragment>
      );
}

export default StatusCellRenderer;