import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";
import { Paper } from "@mui/material";
import isMobile from "../../../../services/isMobile";

function GrafoTable({ data, fields, onClick}) {
  const keys = fields || (data && data.length > 0 ? Object.keys(data[0]) : "a");


  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: isMobile ? "auto" : 650 }} aria-label="simple table">
        <TableBody>
          {data.map((row, key) => (
            <TableRow
            className="hoverRow" 
              key={key}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
           
              {keys.map((key) => (
                <TableCell key={key} className={key} onClick={() => onClick(row)}>{row[key]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}



export default GrafoTable;
