import { TextField } from "@mui/material";

function ProfileInput({
    placeholder,
    title,
    value,
    onChange,
    style,
    disabled,
    className,
    type,
    prefix
  }) {
    return (
      <div
      className={className}
        style={
          style ? { ...style, marginBottom: "35px" } : { marginBottom: "35px" }
        }
      >
        <div style={{ fontWeight: 600, fontSize: "12px", marginBottom: "10px" }}>
          {title}
        </div>
        <TextField
          InputProps={prefix ? {
          startAdornment:
          prefix,
          } : {}}
          disabled={disabled}
          value={value}
          placeholder={placeholder || title}
          fullWidth={true}
          onChange={(e) => onChange(e.target.value)}
          type={type || "text"}
        />
      </div>
    );
  }

  export default ProfileInput;