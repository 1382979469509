import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormEditor from "./FormEditor";
import FormError from "./FormError";

function FormManage({ onChange, form, onBack, template, data }) {

  const [formTemplates, setFormTemplates] = useState(template ? [template] : []);
  const [showFormError, setFormError] = useState(false);

  const currentTemplate =
    template || !form || formTemplates.find((f) => {
      return String(form.form) === f._id
    });

  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeValues = (key, value) => {
    let newValues = form || {};
    newValues[key] = value;
    onChange({ ...newValues });
  };

  const initialize = async () => {

    if (template) {
      return;
    }

    const loadedFormTemplates = await data.getFormTemplates();

    if (!loadedFormTemplates || !loadedFormTemplates.length) {
      setFormError(true);
    }
    setFormTemplates(loadedFormTemplates);

    let formID = localStorage.getItem("lastFormLoaded") || loadedFormTemplates[0]._id;

    const newform = loadedFormTemplates.find(
      (f) => String(formID) === String(f._id)
    ) || loadedFormTemplates[0]

    if (!newform) {
      return;
    }

    changeValues("form", formID);
    changeValues("title", newform.title);

  };

  if (showFormError) {
    return <FormError />;
  }

  return (
    <React.Fragment>
      {formTemplates.length > 0 && !template && (
        <FormTemplateSelector
          onBack={onBack}
          data={formTemplates}
          value={!currentTemplate || currentTemplate._id}
          onChange={(formID) => {
            changeValues("form", formID);

            localStorage.setItem("lastFormLoaded", formID);

            const newform = formTemplates.find(
              (f) => String(formID) === String(f._id)
            );

            changeValues("title", newform.title);


          }}
        />
      )}
      {formTemplates.length === 0 && (
        <div>
          You need to create a form first, click the forms tab to get started!
        </div>
      )}

      {currentTemplate && (
        <FormEditor
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          answers={form.answers}
          template={currentTemplate}
          onChange={(value) => {
            changeValues("answers", value)
          }}
        />
      )}
    </React.Fragment>
  );
}

function FormTemplateSelector({ data, value, onChange, onBack }) {
  return (
    <React.Fragment>
      <h2
        onClick={() => {
          if (onBack) {
            onBack();
          }
        }}
      >
        {onBack && <i onClick={onBack} className="fas fa-arrow-left"></i>} Assessment
        Questionnaire
      </h2>
      <FormControl>
        <InputLabel id="demo-simple-select-label">Select</InputLabel>
        <Select
          size="small"
          placeholder="Select Form"
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          style={{ minWidth: "200px" }}
          label="Select"
        >
          {data.map((part) => (
            <MenuItem key={part._id} value={part._id}>
              {part.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

export default FormManage;
