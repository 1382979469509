
import React from "react";

import ViewAssessmentList from "./viewAssessment/ViewAssessmentList";

function AssessmentsMain({ patient, onCreate, onEditAssessment }) {

  return (
    <React.Fragment>
      <ViewAssessmentList
        editAssessment={(ass) => onEditAssessment(ass)}
        onCreate={onCreate}
        patient={patient}
      />
    </React.Fragment>
  );
}

export default AssessmentsMain;
