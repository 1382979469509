import React, { useState, useEffect } from "react";
import currencies from "../../../../_generic/currencies";
import { useData } from "../../_generic/contexts/DataContext";
import SettingSection from "../_generic/SettingsSection";
import CurrencySelector from "./components/CurrencySelector";
import { updateCurrency } from "./components/serviceLogic";
import ServiceManager from "./components/ServiceManager";

function ManageServices() {
  const data = useData();
  const { user } = data;
  const [currency, setCurrency] = useState(user.currency || "GBP");

  const selectedCurrency = currencies.find((c) => c.key === currency);

  useEffect(() => {
    if (user.currency !== currency) {
      updateCurrency(currency, data);
    }
    // eslint-disable-next-line
  }, [currency]);

  return (
    <React.Fragment>
      <SettingSection title="Your Services">
        Add & manage the services you offer. Click Save to confirm your changes.
        <CurrencySelector
          currency={currency}
          currencies={currencies}
          onChange={setCurrency}
        />
        <ServiceManager currencyItem={selectedCurrency} />
      </SettingSection>
    </React.Fragment>
  );
}

export default ManageServices;
