function numberAsCurrency(number, currency){
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      });
      
    let numToReturn = formatter.format(number);

    if(numToReturn.slice(numToReturn.length - 3) === ".00"){
        numToReturn = numToReturn.slice(0, numToReturn.length - 3);
    }

    return numToReturn
}

module.exports = { numberAsCurrency }