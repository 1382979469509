import { getTempStatus } from "../../../Admin/logic/transformStatus";
import React from "react";

function ExpiryCellRenderer(params) {
  const { expiry } = getTempStatus(params.row);

  if (typeof expiry !== "number") {
    return "";
  }

  return <React.Fragment>{expiry} days</React.Fragment>;
}

export default ExpiryCellRenderer;
