import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function capitalizeFirstLetter(str) {
  return str[0].toUpperCase() + str.slice(1);
}

function ConfirmChangePlan({ onConfirm, onCancel, planType }) {
  return (
    <Dialog
      open={true}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to change to the <b>{capitalizeFirstLetter(planType)} plan</b>?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Once you agree to the changes,
          we'll charge you for the rest of the month based on the new price (sometimes this will show up in your next monthly charge). You can change your plan at any time in the future.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "red", fontWeight:600, textTransform:"capitalize" }} onClick={onCancel}>
          Cancel
        </Button>
        <Button style={{ color: "green", fontWeight:600, textTransform:"capitalize" }} onClick={onConfirm} autoFocus>
          Agree - Change to {capitalizeFirstLetter(planType)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmChangePlan;
