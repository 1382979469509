import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function ConfirmRemoval({ onCancel, onConfirm, service }) {
  return (
    <Dialog
      open={true}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Remove Service?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove the <b>{service.title.trim() !== "" ? service.title : "New Service"}</b>? This
          service will no longer show to you or your patients, but will still
          show in all existing calendar appointments.<br /><br />Remember to click the Save button at the top of this page once you're done with the rest of your changes.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{ color: "red", textTransform:"capitalize" }}>
          No, keep my service
        </Button>
        <Button onClick={onConfirm} autoFocus style={{textTransform:"capitalize"}}>
          Yes, remove it
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmRemoval;
