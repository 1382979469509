
import AssessmentsMain from "../pages/assessments/AsessmentsMain";
import ClientFormsMain from "../pages/forms/ClientFormsMain";
import GeneralMain from "../pages/general/GeneralMain";
import NotesListMain from "../pages/notes/NotesList/NotesListMain";

const tabConfig = [
    {
        key: "notes",
        label: "Notes",
        component: NotesListMain
    },
    {
        key: "assessments",
        label: "Assessments",
        mobileLabel: "Assess..",
        component: AssessmentsMain
    },
    {
        key: "general",
        label: "General Information",
        mobileLabel: "Info",
        component: GeneralMain
    },
    {
        key: "forms",
        label: "Forms",
        component: ClientFormsMain
    },
];

export default tabConfig;