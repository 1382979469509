function MassageAssessmentSummaryPain({ assessment }) {
	const painPoints = assessment.painPoints.filter(
		(p) =>
			p.type === "img" && p.id !== "leftShoulder" && p.id !== "rightShoulder"
	);

	console.log({ painPoints });

	return (
		<div>
			{painPoints.map((p, key) => {
				return (
					<div
						key={key}
						style={{ marginBottom: 20 }}
						className="flexcenter">
						<img
							style={{ width: 20, height: 20, marginRight: 10 }}
							src={p.src}
						/>
						<div>{p.label}</div>
					</div>
				);
			})}
		</div>
	);
}

export default MassageAssessmentSummaryPain;
