function EditAppointmentSection({ children, title }) {
  return (
    <div>
      <div className="title">{title}</div>
      <div>{children}</div>
    </div>
  );
}

export default EditAppointmentSection;
