import React, { useEffect, useState } from "react";
import { Select, MenuItem } from "@mui/material";
import SettingSection from "../_generic/SettingsSection";
import OpeningHours from "./components/OpeningHours";
import { useData } from "../../_generic/contexts/DataContext";
import allTimezones from "./components/timezoneList";
import moment from "moment-timezone";
import { useUI } from "../../../../_generic/UIContext";

function CalendarSettings() {
  const UI = useUI();
  const data = useData();
  const { user } = data;

  const [selectedTimezone, setSelectedTimezone] = useState(
    user.calendarSettings?.timezone ||
      Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ||
      "Europe/London"
  );

  useEffect(() => {
    if (selectedTimezone !== user.calendarSettings?.timezone) {
      changeTimezone();
    }
    // eslint-disable-next-line
  }, [selectedTimezone]);

  const changeTimezone = async () => {
    try {
      let newCalSettings = user.calendarSettings || {};
      newCalSettings.timezone = selectedTimezone;

      await data.updateUserObject({
        calendarSettings: newCalSettings,
      });
    } catch (err) {
      UI.showToast(
        err?.message ||
          err ||
          "There was an error with updating your timezone, please refresh the page and try again"
      );
    }
  };

  return (
    <React.Fragment>
      <SettingSection title="Time & Calendar Settings">
        Choose the time zone to suit your business. Daylight savings changes
        will automatically apply based on your selected timezone.
        <div>
          <Select
            style={{ marginTop: "20px" }}
            onChange={(e) => setSelectedTimezone(e.target.value)}
            value={selectedTimezone}
          >
            {allTimezones.map((tz) => (
              <MenuItem key={tz} value={tz}>
                {tz}
              </MenuItem>
            ))}
          </Select>
          <div style={{ color: "grey", fontSize: "13px", marginTop: "10px" }}>
            Current time: {moment().tz(selectedTimezone).format("hh:mma")}{" "}
          </div>
        </div>
      </SettingSection>
      <SettingSection title="Opening Hours">
        <OpeningHours />
      </SettingSection>
    </React.Fragment>
  );
}

export default CalendarSettings;
