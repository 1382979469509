import { Tab, Tabs } from "@mui/material";
import isMobile from "../../../../services/isMobile";

function ClientViewTabs({ tabsList, selected, onChange, isNew }) {


  return (
    <Tabs
      value={tabsList[selected].key || ""}
      onChange={(event, newvalue) => onChange(tabsList.findIndex(obj => obj.key === newvalue))}
      textColor="primary"
      indicatorColor="primary"
      aria-label="secondary tabs example"
      className="viewTabs"
    >
      {tabsList.filter((itm, key) => !isNew || key === 1 ? true : false).map((itm, key) => {
        return <Tab style={{textTransform:"none", fontWeight:600}} key={key} value={itm.key} label={isMobile && itm.mobileLabel ? itm.mobileLabel : itm.label} />
      })}
    </Tabs>
  );
}

export default ClientViewTabs;
