import CopyToClipboard from "react-copy-to-clipboard";
import { useUI } from "../../../../../_generic/UIContext";
import GrafoButton from "../../../../_generic/GrafoButton";

function CopyAssessmentURL({ url }) {
    const UI = useUI();
  
    return (
      <CopyToClipboard
        options={{ debug: true }}
        text={url}
        onCopy={() => UI.showSuccess("Copied!")}
      >
        <GrafoButton className="grafoButton">Copy Link</GrafoButton>
      </CopyToClipboard>
    );
  }

export default CopyAssessmentURL;