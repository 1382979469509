import React, { useEffect, useState } from "react";
import { useUI } from "../../../../../_generic/UIContext";
import GrafoButton from "../../../../_generic/GrafoButton";
import { useData } from "../../../_generic/contexts/DataContext";

import { loadServices, saveServices } from "../components/serviceLogic";
import ServiceRow from "./ServiceRow";

function ServiceManager({ currencyItem }) {
  const [services, setServices] = useState([]);

  const [hasChanged, setHasChanged] = useState(false);

  const data = useData();

  const UI = useUI();

  useEffect(() => {
    loadServices(setServices, UI, data);
    // eslint-disable-next-line
  }, []);

  const onChange = (allValues) => {
    setHasChanged(true);

    let newServices = services;
    let serviceIndex = newServices.findIndex(
      (service) => String(service._id) === allValues._id
    );

    if(serviceIndex > -1){
      newServices[serviceIndex] = allValues;
    } else {
      newServices.unshift(allValues)
    }

    setServices([...newServices]);
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <GrafoButton
        style={{ marginRight: "10px", border: "1px solid var(--blue)" }}
        onClick={() => saveServices(services, UI, data, setServices)}
        disabled={!hasChanged}
      >
        <i className="fa-solid fa-floppy-disk"></i> Save All Changes
      </GrafoButton>

      <AddServiceButton onChange={onChange} />

      <hr style={{ marginTop: "50px", marginBottom: "50px" }} />
      {services.filter(s => s.status !== "archived").map((s) => (
        <ServiceRow
          key={s._id}
          currencyItem={currencyItem}
          service={s}
          onChange={onChange}
        />
      ))}

      <AddServiceButton onChange={onChange} />
    </div>
  );
}

function AddServiceButton({ onChange }) {
  return (
    <GrafoButton
      className="grafoButton transparent"
      onClick={() => {
        onChange({
          _id: "new_" + new Date().valueOf(),
          title: "",
          durationInMins: 30,
          price: "",
          description: "",
        });
      }}
    >
      + Add Service
    </GrafoButton>
  );
}

export default ServiceManager;
