import { MenuItem, Select } from "@mui/material";
import React from "react";
import timeSelectionList from "./timeSelectionList";

function TimeSelection({
  times,
  shortTimes,
  onChange,
  onAdd,
  errorIndex,
  hideAdd,
  readOnly
}) {
  const timeChanged = (e, i, type) => {
    let newTimes = times;
    newTimes[i][type] = e.target.value;
    onChange(newTimes);
  };

  const onRemove = (index) => {
    let newTimes = times;
    newTimes.splice(index, 1);
    onChange(newTimes);
  };

  return (
    <div>
      {times &&
        times.map((t, i) => {
          return (
            <div
              className={
                "timeRowContainer " + (errorIndex === i ? "errorTime" : "")
              }
              key={i}
            >
              <SelectionItem
                shortTimes={shortTimes}
                value={t.start}
                onChange={(e) => {
                  timeChanged(e, i, "start");
                }}
                readOnly={readOnly}
              />{" "}
              -{" "}
              <SelectionItem
              readOnly={readOnly}
                shortTimes={shortTimes}
                value={t.end}
                onChange={(e) => {
                  timeChanged(e, i, "end");
                }}
              />{" "}
              {!hideAdd && (
                <React.Fragment>
                  {i === 0 ? (
                    <i
                      className="fa-solid fa-fw fa-plus timeSelectionIcon"
                      onClick={onAdd}
                    ></i>
                  ) : (
                    <i
                      onClick={onRemove}
                      className="fa-solid fa-fw fa-xmark timeSelectionIcon"
                    ></i>
                  )}
                </React.Fragment>
              )}
            </div>
          );
        })}
    </div>
  );
}

function SelectionItem({ value, onChange, shortTimes, readOnly }) {
  return (
    <Select readOnly={readOnly} className="timeSelectionSelect" onChange={onChange} value={value}>
      {timeSelectionList(shortTimes).map((tls, i) => {
        return (
          <MenuItem key={i} value={tls.value}>
            {tls.label}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default TimeSelection;
