import React, { useEffect, useState } from "react";

import routes from "./_generic/config/routes";
import RouterManager from "../../_generic/RouteManager";
import Menu from "./_generic/layout/Menu";
import { useLocation, useNavigate } from "@reach/router";
import { getCurrentPath } from "./_generic/utils/routeHelpers";
import { DataProvider, useData} from "./_generic/contexts/DataContext";
import mixpanel from "../../services/_mixpanel";

function AuthenticatedContainer() {
  const location = useLocation();

  useEffect(() => {
    mixpanel.track("Page View: " + location.pathname);
  }, [location]);

  return (
    <DataProvider>
      <AuthProviderContainer />
    </DataProvider>
  );
}

function AuthProviderContainer() {


  const [showMobMenu, setShowMobMenu] = useState(false);

  return (
    <React.Fragment>
      <div className="flex outerContainer">
        <Menu showMobMenu={showMobMenu} onHide={() => setShowMobMenu(false)} />
        <div className="flexgrow contentContainer">
          <NavBar toggleMenuBar={() => {
            //this is for mob only
            setShowMobMenu(!showMobMenu);
          }} />
          <div className="mainContent">
            <RouterManager routes={routes} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function NavBar({toggleMenuBar}) {
  const navigate = useNavigate();
  const location = useLocation();
  const foundRoute = getCurrentPath(location.pathname);

  const data = useData();
  const user = data?.user;

  useEffect(() => {
    window.Intercom("update");
  }, [location]);

  return (
    <div className="topMenuBar paddingX flexcenter">
      <div className="flexgrow" onClick={toggleMenuBar}><i className="fas fa-bars mobileMenuClick" />{foundRoute?.name || "Dashboard"}</div>
      <div
        className="right flexcenter profile"
        onClick={() => navigate("/settings")}
      >
        <span className="name" style={{ marginRight: "10px" }}>
          {user?.firstName + " " + user?.lastName}
        </span>{" "}
        <i className="fas fa-cog" />
      </div>
    </div>
  );
}

export default AuthenticatedContainer;
