import {
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import SelectServices from "./SelectServices";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import TimeSelection from "../../../Settings/calendarSettings/components/TimeSelection";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EditAppointmentSection from "./EditAppointmentSection";
import GrafoButton from "../../../../_generic/GrafoButton";
import FindPatient from "../../../_generic/components/FindPatient";
import { cancelAppointment, createAppointment, editAppointmentTime } from "./editAppointmentLogic";
import { useUI } from "../../../../../_generic/UIContext";
import { useData } from "../../../_generic/contexts/DataContext";
import { Link, useNavigate } from "@reach/router";

function EditAppointment({ value, onHide }) {
  const UI = useUI();
  const data = useData();

  const navigate = useNavigate();
  const isNew = !value._id;

  const [startDate, setStartDate] = useState(
    moment.utc(value.startDateTime) ||
      moment.utc().set({ hours: 9, minutes: 30 })
  );

  const [durationInMins, setDurationInMins] = useState(
    value.durationInMins || 30
  );
  const [patient, setPatient] = useState(value.patient);
  const [service, setService] = useState(value.service);
  const [customTitle, setCustomTitle] = useState(value.customTitle);
  const [customEmailContent, setCustomEmailContent] = useState(
    value.emailContent
  );
  const [showCustomCalendarContent, setShowCustomCalendarContent] =
    useState(false);

  const end = moment.utc(startDate).add(durationInMins, "minutes");
  const timeInFormat = [
    { start: moment.utc(startDate).format("HH:mm"), end: end.format("HH:mm") },
  ];

  const isSaveButtonActive =
    patient &&
    service &&
    startDate &&
    moment.utc(startDate).isAfter(moment.utc()) &&
    durationInMins;

  return (
    <div className="editAppointment">
      <div>
        <i onClick={onHide} className="close fa-solid fa-circle-xmark"></i>
        <div>
          <h2>{isNew ? "New Appointment" : "View Appointment"}</h2>
        </div>

        <EditAppointmentSection
          title={
            <React.Fragment>
              Patient {isNew && <Link to="/?addNew=true">(Add New +)</Link>}
            </React.Fragment>
          }
        >
          {!isNew && (
            <div onClick={() => {
                navigate("/?loadPatient=" + value.patient._id);
            }}>
              {value.patient?.firstName
                ? value.patient?.firstName + " " + value.patient.lastName
                : "No patient selected"} <i style={{color:"var(--blue)", cursor:"pointer"}} className="fa-solid fa-square-arrow-up-right"></i>
            </div>
          )}
          {isNew && (
            <FindPatient
              onChange={(val) => setPatient(val)}
              includeNone={true}
            />
          )}
        </EditAppointmentSection>

        <EditAppointmentSection title="Service">
          {isNew && (
            <SelectServices
              defaultValue={isNew ? null : value.service}
              onChange={setService}
              allowNone={true}
              readOnly={!isNew}
            />
          )}
          {!isNew && <div>{value.service?.title || "No service selected"}</div>}
        </EditAppointmentSection>

        {service === "none" && (
          <EditAppointmentSection title="Custom Title">
            <TextField
              fullWidth
              placeholder="Custom Title"
              value={customTitle}
              readOnly={!isNew}
              onChange={(e) => setCustomTitle(e.target.value)}
            />
          </EditAppointmentSection>
        )}

        <EditAppointmentSection title="Schedule">
          <div className="schedulecontainer" onClick={() => {
                if(!isNew){
                    UI.showToast("You can't edit these settings. If you need to change it, cancel this appointment and re-create it")
                }
            }}>
            <div className="datepickercontainer">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={moment.utc(startDate).toDate()}
                  minDate={new Date()}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  inputFormat="dd/MM/yyyy"
                  readOnly={!isNew}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div ><TimeSelection
              hideAdd={true}
              times={timeInFormat}
              shortTimes={true}
              readOnly={!isNew}
              onChange={(val) => {
                editAppointmentTime(
                  val,
                  startDate,
                  setStartDate,
                  setDurationInMins,
                  service
                );
              }}
            /></div>
          </div>
        </EditAppointmentSection>

        <EditAppointmentSection>
          {isNew && (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    setShowCustomCalendarContent(e.target.checked)
                  }
                />
              }
              label="Show Custom Calendar Content"
            />
          )}
          {showCustomCalendarContent && (
            <TextareaAutosize
              readOnly={!isNew}
              placeholder="Extra information - this will be seen by the customer"
              value={customEmailContent || ""}
              onChange={(e) => setCustomEmailContent(e.target.value)}
            ></TextareaAutosize>
          )}
          {!isNew && (
            <div
              style={{
                fontSize: "11px",
                backgroundColor: "#f6f5f5",
                borderRadius: "5px",
                padding: "10px",
                wordBreak: "break-all"
              }}
              dangerouslySetInnerHTML={{ __html: value.emailContent }}
            ></div>
          )}
        </EditAppointmentSection>
        {isNew && (
          <GrafoButton
            disabled={!isSaveButtonActive}
            onClick={() =>
              createAppointment(
                {
                  patient: patient._id,
                  service: service._id !== "none" ? service._id : null,
                  timezone:
                    data.user.calendarSettings?.timezone || "Europe/London",
                  startDateTime: moment.utc(startDate).format(),
                  durationInMins,
                  customTitle,
                  price: service.price,
                  cancellationLink:
                    window.location.origin + "/bookings?cancellation=true",
                  currency: data.user.currency || "GBP",
                  emailContent:
                    showCustomCalendarContent && customEmailContent
                      ? customEmailContent.trim() !== ""
                        ? customEmailContent
                        : null
                      : null,
                },
                UI
              )
            }
          >
            Submit
          </GrafoButton>
        )}

        {!isNew && (
          <GrafoButton onClick={async () => {
            cancelAppointment(value._id, UI);
          }} style={{ backgroundColor: "red" }}>
            Cancel Event
          </GrafoButton>
        )}
      </div>
    </div>
  );
}

export default EditAppointment;
