import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import calendarConfig from "./calendarConfig";
import moment from "moment";
import EditAppointment from "./components/EditAppointment/EditAppointment";
import { useData } from "../_generic/contexts/DataContext";
import ChangePlan from "../Settings/payments/ChangePlan";
import { getCalendarDataAuth } from "../../../services/calendar";
import { useUI } from "../../../_generic/UIContext";
import SearchForAppointment from "./components/SearchForAppointment";

function CalendarMain() {
	const UI = useUI();
	const { user } = useData();
	const [bookings, setBookings] = useState([]);
	const [showSearch, setShowSearch] = useState(false);
	const [manageAppointment, setManageAppointment] = useState();
	const calendarRef = useRef();

	//   const showDate = (specifiedDate) => {
	//     calendarRef.current.getApi().changeView("timeGridDay", specifiedDate);
	//   }

	useEffect(() => {
		initialize();
	}, []);

	const initialize = async () => {
		const { data, status } = await getCalendarDataAuth();

		if (status !== 200) {
			UI.showToast(
				data?.error ||
					data?.message ||
					"There was an error with loading your calendar data, please contact support"
			);
			return;
		}

		setBookings(
			data.map((d) => {
				return {
					...d,
					id: d._id,
					start: moment.utc(d.startDateTime).format("YYYY-MM-DD HH:mm"),
					end: moment
						.utc(d.startDateTime)
						.add(d.durationInMins, "minutes")
						.format("YYYY-MM-DD HH:mm"),
					duration: "00:" + d.durationInMins,
					title:
						d.patient?.firstName + " " + d.patient?.lastName + " | " + d.title,
				};
			})
		);
	};

	if (!user.isCalendarEnabled) {
		return (
			<ChangePlan
				customDescription={
					"You are currently on the basic plan. Upgrade below to have access to the calendar feature."
				}
			/>
		);
	}

	return (
		<React.Fragment>
			{!!manageAppointment && (
				<EditAppointment
					onHide={() => setManageAppointment(null)}
					value={manageAppointment}
				/>
			)}

			{showSearch && (
				<SearchForAppointment
					onShow={(booking) => {
						setManageAppointment(booking);
						setShowSearch(false);
					}}
					calendar={bookings}
					onHide={() => setShowSearch(false)}
				/>
			)}

			<FullCalendar
				ref={calendarRef}
				plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
				initialView={calendarConfig.initialView}
				customButtons={calendarConfig.customButtons(setShowSearch)}
				headerToolbar={calendarConfig.headerToolbar}
				dayMaxEvents={calendarConfig.dayMaxEvents}
				events={bookings}
				eventClick={(info) => {
					const id = info.event.id;
					setManageAppointment(bookings.find((b) => b._id == id));
				}}
				height={calendarConfig.height}
				dateClick={(info) => {
					let showDate = moment.utc(info.date.toString().slice(0, 24));

					setManageAppointment({
						startDateTime: moment.utc(showDate).set({ hours: 9, minutes: 30 }),
					});
				}}
			/>
		</React.Fragment>
	);
}

export default CalendarMain;
