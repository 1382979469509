
import moment from "moment";
import React, { useEffect, useState, lazy } from "react";
import { Suspense } from "react";
import { getAssociationUserData } from "../../../services/associations";
import { useUI } from "../../../_generic/UIContext";
import DateCellRenderer from "../_generic/components/cellRenderers/DateCellRenderer";
import EmailCellRenderer from "../_generic/components/cellRenderers/EmailCellRenderer";
import ExpiryCellRenderer from "../_generic/components/cellRenderers/ExpiryCellRenderer";
import StatusCellRenderer from "../_generic/components/cellRenderers/StatusCellRenderer";
import { useData } from "../_generic/contexts/DataContext";
import SelectAssociations from "./components/SelectAssociationsFilter";

const DataGrid = lazy(
  () => import('@mui/x-data-grid').then(module => ({ default: module.DataGrid }))
);

const GridToolbar = lazy(
  () => import('@mui/x-data-grid').then(module => ({ default: module.GridToolbar }))
);

function AssociationsMain() {
  const [data, setData] = useState([]);
  const [daysFilter, setDaysFilter] = useState(9999);
  const [filteredData, setFilteredData] = useState([]);

  const authData = useData();
  const UI = useUI();

  const user = authData.user;
  const isAssociation = !!user.association;

  useEffect(() => {
    const initialize = async () => {
      if (!isAssociation) {
        return false;
      }

      UI.showLoading();
      const { data: loadedData, status } = await getAssociationUserData();
      UI.hideLoading();

      if (status !== 200) {
        UI.showToast(loadedData?.error || loadedData?.message || loadedData);
        return;
      }

      setData(loadedData);
      filterChange(9999, loadedData);
    };

    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterChange = (value, customData) => {
    setDaysFilter(value);

    let dataToFilter = customData || data;

    const cutOffDate = moment.utc().subtract(value, "days");

    const newList = dataToFilter.filter((d) => {
      const creationDate = moment.utc(d.creation);
      return creationDate.isSameOrAfter(cutOffDate);
    });

    setFilteredData(newList);
  };

  const columns = [
    { field: "firstName", headerName: "First", width: 150 },
    { field: "lastName", headerName: "Last", width: 150 },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      renderCell: EmailCellRenderer
    },
    {
      field: "creation",
      headerName: "Joined",
      width: 150,
      renderCell: DateCellRenderer
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: StatusCellRenderer
    },
    {
      field: "expiry",
      headerName: "Expiry",
      width: 150,
      renderCell: ExpiryCellRenderer
    },
  ];

  return (
    <React.Fragment>
      <SelectAssociations onChange={filterChange} daysFilter={daysFilter} />
      <div style={{ height: "70vh" }}>
      <Suspense fallback={<React.Fragment>Loading</React.Fragment>}>
        <DataGrid
          className="grafoTableStyle"
          style={{ backgroundColor: "white" }}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row._id}
          rows={filteredData}
          columns={columns}
        />
        </Suspense>
      </div>
    </React.Fragment>
  );
}

export default AssociationsMain;
