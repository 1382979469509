function ImageView({ src, onHide }) {
  return (
    <div
      className="flexmiddle"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width:"100%",
        height:"100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex:"600",
        cursor:"pointer"
      }}
      onClick={onHide}
    >
       <img alt={src} src={src} className="mia" style={{ maxHeight: "85%", maxWidth: "85%", zIndex:"50" }} />
    </div>
  );
}

export default ImageView;
