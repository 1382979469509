const includedOptions = [
    {
        label: "Unlimited Clients",
        icon: "fas fa-users"
    },
    {
        label: "Unlimited Assessments",
        icon: "fas fa-child"
    },
    {
        label: "Unlimited Forms",
        icon: "fas fa-clipboard"
    }
]

export default includedOptions;