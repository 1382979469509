import moment from "moment";
import React from "react";

function FormViewer({ form, onHide }) {
  return (
    <div className="formViewer">
      <div onClick={onHide} className="title">
        <i className="fas fa-arrow-left"></i>{" "}
        {form.title ||
          "Posted on " + moment.utc(form.creation).format("Do MMMM YYYY")}
      </div>
      {form.answers?.map((q, i) => {
        return <FormViewQuestion key={i} question={q} />;
      })}
    </div>
  );
}

function FormViewQuestion({ question }) {

    let answer = question.answer;

    if(Array.isArray(answer)){
        answer = <React.Fragment>{answer.map(a => {
            return <React.Fragment><i className="fas fa-check" /> {a.value} <br /></React.Fragment>
        })}</React.Fragment>
    }

  return (
    <div className="formQSection">
      <div className="qTitle">{question.label}</div>
      <div>{answer}</div>
    </div>
  );
}

export default FormViewer;
