function SignupFormTemplate({ children }) {
  return (
    <div className="flexeven unauthenticatedContainer">
      <div className="artwork coverimage flexmiddle">
        <div>
          <div className="huge">
            It’s the
            <br />
            patient software you
            <br />
            always wanted
          </div>
          <div style={{ marginTop: "20px", marginBottom: "70px" }}>
            Create a free account and get full access to all features for 10
            days. Get started in 2 minutes. Change your clinical notes forever.
          </div>

          <div className="flexcenter">
            <img
              className="profiles"
              src="https://i.ibb.co/C668FwJ/Avatar-group.png"
              alt="Avatar-group"
              border="0"
            />
            <div className="small">
              Join hundreds of health care professionals
            </div>
          </div>
        </div>
        <div className="bottom flexbetween">
          <div>© grafo clinical notes 2022</div>
          <div>
            <i className="fas fa-envelope"></i> support@grafonotes.com
          </div>
        </div>
      </div>
      <div className="flexmiddle"><i onClick={() => window.location.href = "https://grafonote.com"} className="backSignup fa-solid fa-circle-arrow-left"></i>{children}</div>
    </div>
  );
}

export default SignupFormTemplate;
