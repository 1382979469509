import MainStage from "../../../newAssessments/components/MainStage";
import massageMapConfig from "../../../newAssessments/config/massageMapConfig";

function MassageAssessmentBox({ assessment: data }) {
	return (
		<div
			className="scrollbarmassage"
			style={{
				maxWidth: "100%",
				maxHeight: "100%",
				overflowY: "hidden",
				overflowX: "scroll",
				marginTop: -10,
				zoom: 0.8,
				paddingLeft: 40,
			}}>
			<MainStage
				config={massageMapConfig}
				painPoints={data?.painPoints || []}
				shapeSize={23}
			/>
		</div>
	);
}

export default MassageAssessmentBox;
