import PainPointList from "./components/PainPointList";

function PhysioNotes({ data, onChange }) {
	return (
		<div>
			<div>
				<input
					value={data.title || ""}
					onChange={(e) => onChange({ title: e.target.value })}
					style={{
						width: "100%",
						marginBottom: "15px",
						border: "0px",
						outline: "none",
						fontFamily: "inherit",
						fontWeight: "600",
						fontSize: "var(--large)",
					}}
					placeholder="✎ Assessment Title"
				/>
			</div>
			<PainPointList
				onChange={(val) => onChange({ painPoints: val })}
				painPoints={data?.painPoints || []}
			/>
		</div>
	);
}

export default PhysioNotes;
