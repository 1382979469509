import moment from "moment";
import POHeader from "./POHeader";

function POSuccess({ currentPage, startDate, userDetails }) {
  return (
    <div className="userOnboarding calendarView">
      <POHeader currentPage={currentPage} customTitle="Appointment Confirmed" />
      <div
        style={{
          backgroundColor: "white",
          fontSize: "15px",
          marginTop: "20px",
        }}
      >
        Your booking is confirmed for{" "}
        <b>{moment.utc(startDate).format("Do MMM YYYY [at] hh:mma")}</b>.
        <br />
        <br />
        You will receive a confirmation email to <b>{userDetails.email}</b> in
        the next couple of minutes. Please check your spam if you do not receive
        it.
        <br />
        <br />
        If you need to cancel, you can can cancel your appointment in the
        invite.
        <br />
        <br />
        Thanks for using GrafoNotes for this booking service.
      </div>
    </div>
  );
}

export default POSuccess;
