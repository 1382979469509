import { Link } from "@reach/router";
import React, { useState } from "react";
import { resetPassword } from "../../../services/auth";
import { useUI } from "../../../_generic/UIContext";
import GrafoButton from "../../_generic/GrafoButton";
import FormInput from "../templates/FormInput";
import SignupFormTemplate from "../templates/SignupFormTemplate";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const UI = useUI();

  const submit = async () => {
    if (email === "") {
      UI.showToast("Please enter a valid email address");
      return;
    }

    UI.showLoading();
    const { data, status } = await resetPassword(email);
    UI.hideLoading();

    if (status !== 200) {
      UI.showToast(
        data.error ||
          data.message ||
          "There was an error in submitting your details, please try again later"
      );
      return;
    }

    setSuccessMessage(true);
  };

  return (
    <SignupFormTemplate>
      <div className="uForm">
        <h2>Welcome Back</h2>
        {!successMessage && (
          <React.Fragment>
            <div className="description" style={{ marginBottom: "35px" }}>
              Please enter your email address.
            </div>
            <FormInput
              value={email}
              label="Email address"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <GrafoButton onClick={submit} fullWidth={true}>
              Submit
            </GrafoButton>
            <div style={{ marginTop: "25px" }} className="description">
              Need to login? <Link to="/login">Login</Link>
            </div>
          </React.Fragment>
        )}

        {successMessage && (
          <div>
            Your password has been reset! Check your inbox in the next few
            minutes.
          </div>
        )}
      </div>
    </SignupFormTemplate>
  );
}

export default ForgotPassword;
