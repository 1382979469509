import moment from "moment";
import React, { useState } from "react";
import { defaultBookingTimes } from "../../../../authenticated/Settings/calendarSettings/components/defaultCalendarTimeSettings";
import getDaysArray from "../logic/getDaysArray";
import getTimesArray from "../logic/getTimesArray";

const listOfDates = getDaysArray(moment.utc(), moment.utc().add(60, "days"));

function POCalendarSelection({
  physio,
  selectedDate,
  setSelectedDate,
  nextPage,
  calendarEntries,
  selectedService,
}) {
  const [dayList] = useState(listOfDates);

  const timesList = React.useMemo(() => {
    return getTimesArray(
      physio.calendarSettings?.openingHours || defaultBookingTimes
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isSelected = (dateToCheck) => {
    return dateToCheck.isSame(selectedDate, "day");
  };

  const isTimeSelected = (timeVal) => {
    return moment.utc(selectedDate).format("HH:mm") === timeVal;
  };

  const doDatesOverlap = (
    aStart,
    aEnd,
    bStart,
    bEnd,
    checkBackwards = true
  ) => {
    if (aStart < bEnd && bStart < aEnd) {
      return true;
    }

    if (checkBackwards) {
      return doDatesOverlap(bStart, bEnd, aStart, aEnd, false);
    }

    return false;
  };

  const daySelected = moment.utc(selectedDate).format("dddd").toLowerCase();
  const timesForDay = timesList[daySelected] || [];

  const timesForDayExcludingBookedTimes = timesForDay.filter((time) => {
    return !calendarEntries.some((ce) => {
      const ceStart = moment.utc(ce.startDateTime);
      const ceEnd = moment.utc(ceStart).add(ce.durationInMins, "minutes");

      if (!ceStart.isSame(moment.utc(selectedDate), "day")) {
        return false;
      }

      const timeToShowStart = moment.utc(ceStart).set({
        hours: time.value.substring(0, 2),
        minutes: time.value.substring(3, 5),
      });

      const timeToShowEnd = moment
        .utc(timeToShowStart)
        .add(selectedService.durationInMins, "minutes");

      if (doDatesOverlap(ceStart, ceEnd, timeToShowStart, timeToShowEnd)) {
        return true;
      }

      return false;
    });
  });

  return (
    <React.Fragment>
      <div className="poDateSelection">
        {dayList.map((l, i) => {
          const day = l.format("ddd");
          const date = l.format("DD");

          const tConfig = timesList[l.format("dddd").toLowerCase()];
          const isDisabled = !tConfig || !tConfig.length;

          return (
            <div
              onClick={() => {
                setSelectedDate(moment.utc(l).set({ hours: 9, minutes: 30 }));
              }}
              key={i}
              className={
                (isSelected(l) ? "selected" : "") +
                (isDisabled ? " disabled" : "")
              }
            >
              <div className="day">{day}</div>
              <div>{date}</div>
              <div className="month">{l.format("MMM")}</div>
            </div>
          );
        })}
      </div>

      {timesForDayExcludingBookedTimes.length > 0 && (
        <div className="poTimeSelection">
          {timesForDayExcludingBookedTimes.map((t, i) => {
            return (
              <div
                key={i}
                className={
                  "flex " + (isTimeSelected(t.value) ? "selectedTime" : "")
                }
                onClick={() => {
                  const newDateSelected = moment.utc(selectedDate).set({
                    hours: t.value.substring(0, 2),
                    minutes: t.value.substring(3, 5),
                  });

                  setSelectedDate(newDateSelected);
                  nextPage();
                }}
              >
                <div className="flexgrow">{t.label}</div>
                <div>
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {timesForDayExcludingBookedTimes.length === 0 && (
        <div>
          <i className="fa-solid fa-clock-seven"></i>
          You can't book appointments for this day! Try another date!
        </div>
      )}
    </React.Fragment>
  );
}

export default POCalendarSelection;
