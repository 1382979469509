
import { createHistory, LocationProvider } from "@reach/router";

import React from "react";
import "./styles/App.css";
import "./styles/Responsive.css";
import "./styles/Calendar.css";

import AuthenticatedContainer from "./components/authenticated/AuthenticatedContainer";
import UnauthenticatedContainer from "./components/unauthenticated/UnauthenticatedContainer";


function App() {
  
  // const isAuthenticated = true;
  const isAuthenticated = !!localStorage.getItem("grafoToken");

  let history = createHistory(window);

  if (isAuthenticated) {
    return (
      <LocationProvider history={history}>
        <AuthenticatedContainer />
      </LocationProvider>
    );
  } else {
    return <UnauthenticatedContainer />;
  }
}

export default App;
