
import isMobile from "../../../services/isMobile";

let calViewOptions = [
    "dayGridMonth",
    "timeGridWeek",
    "timeGridDay"
];

if(isMobile){
    calViewOptions = ["timeGridDay"];
}

const calendarConfig = {
    dayMaxEvents: 2,
    height: window.innerHeight - 100,
    initialView: calViewOptions[0],
    headerToolbar: {
        left: "title,search",
        center: !isMobile ? calViewOptions.join(",") : null,

    },
    customButtons: function(setShowSearch) {
        return {
            search: {
                text: <i className="fa-solid fa-fw fa-magnifying-glass"></i>,
                click: function(){
                    setShowSearch(true);
                }
            }
        }
    }
}

export default calendarConfig;