import { TextField } from "@mui/material";
import React, { useState } from "react";
import { doesPatientExistUnAuth } from "../../../../../services/patients";
import { submitPhysioDetails } from "../../../../../services/physios";
import { useUI } from "../../../../../_generic/UIContext";
import GrafoButton from "../../../../_generic/GrafoButton";

function POCustomerDetails({
  patientDetails: userDetails,
  setPatientDetails: onChange,
  physio,
  onSubmit
}) {
  const [hasCheckedForUser, setHasCheckedForUser] = useState(false);

  const UI = useUI();

  const changeVal = (e, key) => {
    if (key === "email") {
      setHasCheckedForUser(false);
    }

    let newDetails = key !== "email" ? userDetails : {};
    newDetails[key] = e.target.value;
    onChange({ ...newDetails });
  };

  const findUserInDBAndSubmit = async () => {
      let patientToSubmit = userDetails;

    try {
      if (
        userDetails.email.trim() === "" ||
        userDetails.email.indexOf("@") < 0
      ) {
        throw new Error("Please enter a valid email address");
      }

      UI.showLoading();

      const { data: patientData, status: patientStatus } =
        await doesPatientExistUnAuth(userDetails.email.trim(), physio._id);

      if (patientStatus !== 200) {
        throw new Error(
          patientData?.error ||
            "There was an error with the request, please contact support"
        );
      }

      if (patientData.patientExists) {
        patientToSubmit = { _id: patientData._id, email: userDetails.email.trim() };
      } else if (!userDetails.firstName || !userDetails.lastName) {
        setHasCheckedForUser(true);
        throw new Error(
          "Please enter your first name and last name before proceeding"
        );
      } else {
        const { data, status } = await submitPhysioDetails({
          patient: userDetails,
          physio: physio._id,
        });

        if (status !== 200) {
          throw new Error(
            data?.error ||
              data?.message ||
              "There was an error in creating your account, please contact your clinician"
          );
        }

        patientToSubmit = data.patient;
      }

      onSubmit(patientToSubmit)

      
    } catch (err) {
      UI.hideLoading();
      UI.showToast(
        err.message ||
          err.error ||
          "There was an error in creating your account, please contact your clinician"
      );
    }
  };

  return (
    <React.Fragment>
      <h2>Please provide your details</h2>
      <POField
        title="Email Address"
        placeholder="email@example.com"
        value={userDetails.email}
        onChange={(e) => changeVal(e, "email")}
      />
      {hasCheckedForUser && !userDetails._id && (
        <React.Fragment>
          <span style={{color:"red"}}>Please enter your first name and last name below before proceeding.</span>
          <br />
          <br />
          <div className="flexeven">
            <POField
              title="First Name"
              placeholder="Jack"
              value={userDetails.firstName}
              onChange={(e) => changeVal(e, "firstName")}
            />
            <POField
              title="Last Name"
              placeholder="Smith"
              value={userDetails.lastName}
              onChange={(e) => changeVal(e, "lastName")}
            />
          </div>
        </React.Fragment>
      )}
      <GrafoButton
        style={{ marginTop: "20px" }}
        onClick={findUserInDBAndSubmit}
      >
        Next
      </GrafoButton>
    </React.Fragment>
  );
}

function POField({ value, title, onChange, placeholder, type }) {
  return (
    <div className="poField">
      <div>{title}</div>
      <TextField
        fullWidth={true}
        type={type || "text"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
}

export default POCustomerDetails;
