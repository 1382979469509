import moment from "moment";
import React, { useEffect, useState } from "react";
import searchForValue from "../../../_generic/searchForValue";
import GrafoButton from "../../_generic/GrafoButton";
import GrafoSearchBar from "../_generic/components/GrafoSearchBar";
import GrafoTable from "../_generic/components/GrafoTable";
import { useData } from "../_generic/contexts/DataContext";

function FormsMain({ navigate }) {
  const data = useData();
  const [searchValue, setSearchValue] = useState();
  const [formList, setFormList] = useState([]);
  const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    getForms();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getForms = async () => {
    try {
      const formData = await data.getFormTemplates();

      setFormList(formData);
    } catch (err) {
      throw new Error(
        err.message || err.error || "There was an error in getting your forms"
      );
    }
  };

  const searchIt = (value) => {
    setSearchValue(value);
    setSearchList(searchForValue(value, formList, ["title"]));
  };

  const formatData = () => {
    let data = searchList.length > 0 ? searchList : formList;
    return data
      .sort((a, b) => {
        return moment(b.creation).format("X") - moment(a.creation).format("X");
      })
      .map((li) => {
        return {
          _id: li._id,
          title:
            li.title ||
            "Posted on " + moment.utc(li.creation).format("Do MMMM YYYY"),
          description: li.questions.length + " questions",
          creation: moment.utc(li.creation).format("Do MMM YYYY"),
        };
      });
  };

  return (
    <div>
      <div className="flex" style={{ marginBottom: "20px" }}>
        <GrafoSearchBar
          value={searchValue || ""}
          onChange={(value) => searchIt(value)}
        />
        <div className="flex" style={{ marginLeft: "30px" }}>
          <GrafoButton onClick={() => navigate("/forms/new")}>
            <i className="fas fa-plus"></i> New Form
          </GrafoButton>
        </div>
      </div>
      {formList.length > 0 && (
        <GrafoTable
          onClick={(row) => navigate("/forms/" + row._id)}
          data={formatData()}
          fields={["title", "description", "creation"]}
        />
      )}
    </div>
  );
}

export default FormsMain;
