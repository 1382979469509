import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React from "react";

const severityConfig = ["low", "moderate", "severe"];

function GenericAssessmentField({ data, onChange, placeholder, name }) {
  const changeValue = (key, newValue) => {
    let newObject = data || {};
    newObject[key] = newValue;

    onChange({ ...newObject });
  };


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <React.Fragment>
      <div
        className="flexeven genericAssessmentField"
      >
        {severityConfig.map((s, i) => {
          return (
            <div key={i}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        changeValue("severity", s);
                      } else {
                        changeValue("severity", null);
                      }
                    }}
                    checked={!!(data && data.severity === s)}
                  />
                }
                label={capitalizeFirstLetter(s)}
              />
            </div>
          );
        })}
      </div>
      <TextField
        fullWidth={true}
        placeholder={"Reason"}
        variant={"standard"}
        value={data?.reason || ""}
        onChange={(e) => changeValue("reason", e.target.value)}
      />
    </React.Fragment>
  );
}

export default GenericAssessmentField;
