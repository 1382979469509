const moment = require("moment-timezone");

const timezoneNameFilters = [
    "Etc/",
    "NZ-CHAT",
    "PST",
    "Pacific",
    "ROC",
    "ROK",
    "UCT",
    "Universal",
    "W-SU",
    "WET",
    "Zulu",
    "GMT",
    "HST",
    "GB",
    "EET",
    "EST",
    "Canada",
    "Brazil",
    "Australia",
    "Atlantic",
    "Africa",
    "US",
    "CST",
    "MET",
    "NZ",
    "PRC",
    "MST",
    "CET",
    "Mexico",
    "Indian",
    "Eire"
  ];

let allTimezones = moment.tz
  .names()
  .filter((tz) => tz.indexOf("/") > -1 && !timezoneNameFilters.some((fi) => tz.indexOf(fi) > -1));

export default allTimezones;