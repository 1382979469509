import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useData } from "../contexts/DataContext";
import Autocomplete from "@mui/material/Autocomplete";

function FindPatient({ onChange, includeNone }) {
  const data = useData();

  const [patients] = useState(
    [
      includeNone
        ? { _id: null, firstName: "Empty (Block Time)", lastName: "" }
        : null,
      ...data.user.patients
    ]
      .flat()
      .filter((dt) => dt)
  );

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={patients}
      fullWidth
      getOptionLabel={(option) => option.firstName + " " + option.lastName}
      onChange={(event, newValue) => {
        if (onChange) {
          onChange(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder="Search for a patient" />
      )}
    />
  );
}

export default FindPatient;
