import {
  Checkbox,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import isMobile from "../../../../../services/isMobile";
import GrafoButton from "../../../../_generic/GrafoButton";

function CreateStepper({
  tabLabels,
  tabIndex,
  setTabIndex,
  consent,
  setConsent
}) {
  const isDisabled = (nextIndex) => {
    return nextIndex < 0 || nextIndex >= tabLabels.length;
  };

  return (
    <div>
      <div style={{ padding: "5px 45px", backgroundColor: "white" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={consent}
              onChange={(e) => setConsent(e.target.checked)}
            />
          }
          label="I consent to this assessment and I consent to ongoing treatment"
        />
      </div>
      <div className="flexcenter viewPadding bottomWhite">
        <div>
          <GrafoButton
            disabled={isDisabled(tabIndex - 1)}
            onClick={() => setTabIndex(tabIndex - 1)}
          >
            {!isMobile ? "Previous" : "<"}
          </GrafoButton>
        </div>
        <div className="flexgrow">
          {!isMobile && <Stepper nonLinear activeStep={tabIndex}>
            {tabLabels.map((l) => (
              <Step key={l}>
                <StepLabel>{l}</StepLabel>
              </Step>
            ))} 
          </Stepper>}
        </div>
        <div>
          <GrafoButton
            disabled={isDisabled(tabIndex + 1)}
            onClick={() => setTabIndex(tabIndex + 1)}
          >
            {!isMobile ? "Next " : ">"}
          </GrafoButton>
        </div>
      </div>
    </div>
  );
}

export default CreateStepper;
