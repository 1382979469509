import PaymentDetails from "../payments/PaymentDetails";
import CalendarSettings from "../calendarSettings/CalendarSettings";
import YourProfile from "../profile/YourProfile";
import ManageServices from "../services/ManageServices";

const isSubscribedToCalendar = (user) => {
    return user.isCalendarEnabled;
}

const settingTabs = [
    {
        key: "profile",
        label: "Your Profile",
        mobileLabel: "Profile",
        component: YourProfile
    },
    {
        key: "services",
        label: "Your Services",
        mobileLabel: "Services",
        component: ManageServices,
        filter: isSubscribedToCalendar
    },
    {
        key: "time",
        label: "Time & Calendar",
        mobileLabel: "Calendar",
        component: CalendarSettings,
        filter: isSubscribedToCalendar
    },
    {
        key: "payments",
        label: "Payments",
        mobileLabel: "Paym..",
        component: PaymentDetails
    }
]

export default settingTabs;