import { Link, useLocation } from "@reach/router";
import React, { useState } from "react";
import { useEffect } from "react";
import { attemptLogin } from "../../../services/auth";

import { useUI } from "../../../_generic/UIContext";
import GrafoButton from "../../_generic/GrafoButton";
import GoogleAuth from "../components/GoogleAuth";
import mixpanel from "../../../services/_mixpanel";
import FormInput from "../templates/FormInput";
import SignupFormTemplate from "../templates/SignupFormTemplate";

function Login() {
  const UI = useUI();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const location = useLocation();

  useEffect(() => {
    checkAutoLogin();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkAutoLogin = async () => {
    if (location?.state?.autoLogin) {
      const user = location.state.user;

      if (user.type === "form") {
        setEmail(user.email);
        setPassword(user.password);
        submitForm(user.email, user.password);
      }

      if (user.type === "google") {
        const response = {
          profileObj: user,
          tokenId: user.tokenId,
        };

        submitGoogle(response);
      }
    }
  };

  const submitForm = async (t_email, t_password) => {
    if (t_email === "" || t_password === "") {
      UI.showToast("Please enter a valid email and password");
      return;
    }

    submitLogin({ email: t_email, password: t_password, type: "form" });
  };

  const submitGoogle = async (response) => {
    const profile = response?.profileObj;
    const error = response?.error;

    if (error || !profile) {
      UI.showToast(
        "We couldn't retrieve your personal details from your Google account"
      );
      return;
    }
    const token = response.tokenId;

    submitLogin({ email: profile.email, token, type: "google" });
  };

  const submitLogin = async (user) => {
    UI.showLoading();
    const { data, status } = await attemptLogin(user);
    UI.hideLoading();

    if (status !== 200) {
      mixpanel.identify(user.email);
      mixpanel.track("Account Error", { errorType: data.error });

      UI.showToast(data.error || data);
      return;
    }

    localStorage.setItem("grafoToken", data.token);

    window.location.href = "/";
  };

  return (
    <SignupFormTemplate>
      <div className="uForm">
        <h2>Welcome Back</h2>
        <div className="description" style={{ marginBottom: "35px" }}>
          Please enter your details. <Link to="/forgotPassword">Forgotten Password</Link>?
        </div>
        <FormInput
          value={email}
          label="Email address"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormInput
          value={password}
          label="Password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <GrafoButton
          fullWidth={true}
          onClick={() => submitForm(email, password)}
        >
          Submit
        </GrafoButton>
        <div
          className="description"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          OR
        </div>
        <GoogleAuth onChange={submitGoogle} />
        <div style={{ marginTop: "25px" }} className="description">
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </div>
      </div>
    </SignupFormTemplate>
  );
}

export default Login;
