function timeSelectionList(shortTimes) {
  const timeSelection = [];

  for (var i = 0; i < 24; i++) {
    const startTimeString = (i < 10 ? "0" : "") + i;
    const PMorAM = i < 12 ? "am" : "pm";
    let twelveHourStartTime = i < 12 ? i : i - 12;

    if (twelveHourStartTime === 0) {
      twelveHourStartTime = 12;
    }

    timeSelection.push({
      value: startTimeString + ":00",
      label: twelveHourStartTime + ":00" + PMorAM,
    });
    if (shortTimes) {
      timeSelection.push({
        value: startTimeString + ":15",
        label: twelveHourStartTime + ":15" + PMorAM,
      });
    }
    timeSelection.push({
      value: startTimeString + ":30",
      label: twelveHourStartTime + ":30" + PMorAM,
    });
    if (shortTimes) {
      timeSelection.push({
        value: startTimeString + ":45",
        label: twelveHourStartTime + ":45" + PMorAM,
      });
    }
  }

  return timeSelection;
}

export default timeSelectionList;
