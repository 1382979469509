import moment from "moment";
import React, { useState } from "react";

import GrafoButton from "../../../../../_generic/GrafoButton";
import CreateNote from "../CreateNote";

import NotesListAttachments from "./NotesListAttachments";

function NotesListMain({ patient, updateSilently }) {
  const [selectedNoteIndex, setSelectedNoteIndex] = useState(0);

  const notes = patient?.patientNotes
    ? patient.patientNotes.sort(
        (a, b) =>
          moment(b.creation).format("X") - moment(a.creation).format("X")
      )
    : [];

  const [showNewNote, setShowNewNote] = useState(false);
  const [defaultNoteValue, setDefaultNoteValue] = useState(null);
  const [showFullListMobile, setShowFullListMobile] = useState(false);

  return (
    <div className="flex notesListMain">
      <div
        className={"listContainer " + (showFullListMobile ? " showList" : "")}
      >
        <GrafoButton
          onClick={() => {
            if (showNewNote) {
              setShowNewNote(false);
              setDefaultNoteValue(null);

              window.setTimeout(() => {
                setShowNewNote(true);
              }, 200);
            } else {
              setDefaultNoteValue(null);
              setShowNewNote(true);
            }
          }}
          style={{ marginBottom: "20px", width: "100%" }}
        >
          <i className="fa-solid fa-circle-plus" style={{marginRight:"10px", padding:"0px"}} /> Add Note
        </GrafoButton>

        {notes.map((n, i) => {
          const isSelected = i === selectedNoteIndex;

          return (
            <div
              onClick={() => {
                setShowFullListMobile(!showFullListMobile);
                setSelectedNoteIndex(i);
                setShowNewNote(false);
              }}
              className={
                "flexcenter clickable " + (isSelected ? "selected" : "")
              }
              key={i}
              style={{
                // backgroundColor: "#f2f2f2",
                padding: "10px",
                width: "100%",
                cursor: "pointer",
                border:"1px solid #dcdcdc",
                
                borderTopLeftRadius: i === 0 ? "10px" : "0px",
                borderTopRightRadius: i === 0 ? "10px" : "0px"
              }}
            >
              <div className="flexgrow" style={{marginRight:"14px"}}>
                <div style={{ fontSize: "11px" }}>
                  {moment.utc(n.creation).format("Do MMM YYYY")}
                </div>
                <div>Treatment Notes</div>
              </div>
              <div >
              <i className="far fa-comment-alt nostyle" style={{fontSize:"24px", color:"grey"}} />
                </div>
              <div style={{marginLeft:"10px"}}>
                <i className="fas fa-chevron-right" style={{ color: "grey" }} />
              </div>
            </div>
          );
        })}

        
      </div>

      <div className="flexgrow">
        {showNewNote ? (
          <CreateNote
            updateSilently={updateSilently}
            patient={patient}
            defaultValue={defaultNoteValue}
            onHide={() => {
              setShowNewNote(false);
              setDefaultNoteValue(null);
            }}
          />
        ) : (
          <IndividualNote
            onEdit={() => {
              setDefaultNoteValue(notes[selectedNoteIndex]);
              setShowNewNote(true);
            }}
            note={notes[selectedNoteIndex] || {}}
          />
        )}
      </div>
    </div>
  );
}

function IndividualNote({ note, onEdit }) {
  const [showAttachments, setShowAttachments] = useState(false);

  return (
    <div className="individualNote">
      <div className="date">
        {moment.utc(note?.creation).format("Do MMMM YYYY")}
      </div>
      <div
        style={{ height: "auto" }}
        className="contentNote"
        dangerouslySetInnerHTML={{ __html: note?.content }}
      ></div>
      <div className="edit" onClick={onEdit}>
        <i className="fa-solid fa-pen-to-square"></i> Edit
      </div>

      {note.attachments && note.attachments.length > 0 && (
        <React.Fragment>
          {!showAttachments && (
            <GrafoButton
              style={{ backgroundColor: "black" }}
              onClick={() => setShowAttachments(true)}
            >
              <i classname="fas fa-camera-alt" /> {note.attachments.length}{" "}
              attachment(s)
            </GrafoButton>
          )}

          {showAttachments && (
            <NotesListAttachments attachments={note.attachments} />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default NotesListMain;
