import { MenuItem, Select, TextareaAutosize } from "@mui/material";
import { useState } from "react";
import GrafoButton from "../../../../_generic/GrafoButton";
import ProfileInput from "../../profile/components/ProfileInput";
import ConfirmRemoval from "./ConfirmRemoval";
import { minuteOptions } from "./serviceLogic";

function ServiceRow({ service, onChange, currencyItem}) {
  const [showConfirmRemoval, setShowConfirmRemoval] = useState(false);

  return (
    <div className="serviceListEntry" key={service._id}>
      {showConfirmRemoval && (
        <ConfirmRemoval
          onConfirm={() => {
            onChange({ ...service, status: "archived" });
          }}
          service={service}
          onCancel={() => setShowConfirmRemoval(false)}
        />
      )}
      <div className="flex">
        <div className="flexgrow">
          <ProfileInput
            title="Service Name"
            value={service.title}
            onChange={(val) => onChange({ ...service, title: val })}
            // onChange={(value) => setUser({ ...user, lastName: value })}
          />
        </div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <div
            style={{
              fontWeight: 600,
              fontSize: "12px",
              marginBottom: "10px",
            }}
          >
            Duration
          </div>
          <Select
            value={service.durationInMins}
            onChange={(e) =>
              onChange({ ...service, durationInMins: e.target.value })
            }
          >
            {minuteOptions.map((mo) => (
              <MenuItem key={mo.value} value={mo.value}>
                {mo.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <ProfileInput
            placeholder={"20"}
            title="Price"
            type="number"
            value={service.price}
            prefix={currencyItem.symbol}
            onChange={(val) => onChange({ ...service, price: val })}
            // onChange={(value) => setUser({ ...user, lastName: value })}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            fontWeight: 600,
            fontSize: "12px",
            marginBottom: "10px",
          }}
        >
          Service Description (optional)
        </div>
        <TextareaAutosize
          style={{
            width: "98%",
            border: "1px solid grey",
            borderRadius: "5px",
            fontFamily: "inherit",
            minHeight: "120px",
            padding: "10px",
          }}
          value={service.description}
          onChange={(e) =>
            onChange({ ...service, description: e.target.value })
          }
          placeholder="Add a description that can be seen in the appointment invite"
        />
      </div>
      <div style={{ textAlign: "right", marginTop: "10px" }}>
        <GrafoButton className="grafoButton transparent" onClick={() => {

            if(service._id.indexOf("new") > -1){
                onChange({...service, status: "archived"});
            } else {
                setShowConfirmRemoval(true);
            }
            

        }}>
          <i className="fa-solid fa-trash-can"></i> Remove Service
        </GrafoButton>
      </div>
    </div>
  );
}

export default ServiceRow;
