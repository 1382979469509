import { CircularProgress } from "@mui/material";
import * as React from "react";


import CheckoutOptions from "./CheckoutOptions";
import updatePatientData from "./DataContextLogic/updatePatientData";
import getFormTemplateData from "./DataContextLogic/getFormTemplateData";
import updateFormTemplateData from "./DataContextLogic/updateFormTemplateData";
import updateUserObjectData from "./DataContextLogic/updateUserObjectData";
import initializeDataProviderData from "./DataContextLogic/initializeDataProviderData";
import { useLocation } from "@reach/router";
import getServicesData from "./DataContextLogic/getServicesData";
import updateServicesData from "./DataContextLogic/updateServicesData";
import { useUI } from "../../../../_generic/UIContext";

const DataContext = React.createContext();

function DataProvider({ children }) {
  const UI = useUI();
  const location = useLocation();

  const [isLoading, setIsLoading] = React.useState(true);
  const [showCheckoutOptions, setShowCheckoutOptions] = React.useState(false);
  const [user, setUser] = React.useState();
  const [formTemplates, setFormTemplates] = React.useState();
  const [services, setServices] = React.useState();

  const updatePatient = async (patient) => {
    return updatePatientData(patient, user, setUser);
  };

  const getFormTemplates = async () => {
    return getFormTemplateData(formTemplates, setFormTemplates);
  };

  const updateFormTemplate = async (form) => {
    return updateFormTemplateData(form, formTemplates, setFormTemplates);
  };

  const updateUserObject = async (updates) => {
    return updateUserObjectData(updates, UI, user, setUser);
  };

  const getServices = async () => {
    return getServicesData(services, setServices, UI, updateServices);
  }
  
  const updateServices = async (updates) => {
    return updateServicesData(updates, services, setServices, UI);
  }

  const value = {
    // formTemplates,
    user,
    updatePatient,
    getFormTemplates,
    updateFormTemplate,
    updateUserObject,
    getServices,
    updateServices,
    // mixpanel
  };

  const initialize = async () => {
    await initializeDataProviderData(UI, setUser, setIsLoading, setShowCheckoutOptions, location)
  };

  React.useEffect(() => {
    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return (
      <div className="flexmiddle" style={{ width: "100vw", height: "100vh" }}>
        <div>
          <CircularProgress />
        </div>
      </div>
    );
  }




  return <DataContext.Provider value={value}>{showCheckoutOptions ? <CheckoutOptions /> : children}</DataContext.Provider>;
}

function useData() {
  const context = React.useContext(DataContext);
  if (context === undefined) {
    throw new Error("useDataContext must be used within a CountProvider");
  }
  return context;
}

export { DataProvider, useData };
