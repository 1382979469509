import MassageNotes from "../components/notes/MassageNotes/MassageNotes";

const massageMapConfig = {
    backgroundImage: "https://i.ibb.co/hCkFWW9/massage.png",
    allowDrawingLines: true,
    existingImages: [
        {
            id: "leftShoulder",
            src: "https://i.ibb.co/dc3sMfk/left-Shoulder.png",
            x: 355,
            y: 200,
            type: "img",
            shapeSizeWidth: 35,
            shapeSizeHeight: 40
        },
        {
            type: "img",
            id: "rightShoulder",
            src: "https://i.ibb.co/Dr6qJvn/right-Shoulder.png",
            x: 405,
            y: 200,
            shapeSizeWidth: 35,
            shapeSizeHeight: 40
        }
    ],
    draggableImages: [
        {
            id: "knot",
            label: "Knot",
            img: "https://i.ibb.co/yy7x38S/star-removebg-preview.png"
        },

        {
            id: "tightmuscle",
            label: "Tight Muscle",
            img: "https://i.ibb.co/YTJd4gD/close-removebg.png"
        },
        {
            id: "atrophy",
            label: "Atrophy",
            img: "https://i.ibb.co/hfWBjtS/Ellipse-1.png"
        },
        {
            id: "pain",
            label: "Pain",
            list: [
                {
                    id: "lowpain",
                    label: "Low Pain",
                    img: "https://i.ibb.co/M7GH1PX/Frame-52.png"
                },
                {
                    id: "medpain",
                    label: "Medium Pain",
                    img: "https://i.ibb.co/Nrvv7ZB/Frame-53.png"
                },
                {
                    id: "highpain",
                    label: "High Pain",
                    img: "https://i.ibb.co/2d0CR5q/Frame-54-removebg-preview.png"
                }
            ]

        }
    ],
    notesComponent: MassageNotes
}

export default massageMapConfig;