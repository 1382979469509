import useImage from "use-image";
import { Image } from "react-konva";

const URLImage = ({ image, shapeSize }) => {
	const [img] = useImage(image.src);
	return (
		<Image
			image={img}
			draggable={true}
			width={image.shapeSizeWidth || shapeSize}
			height={image.shapeSizeHeight || shapeSize}
			x={image.x}
			y={image.y}
			// I will use offset to set origin to the center of the image
			offsetX={img ? img.width / 2 : 0}
			offsetY={img ? img.height / 2 : 0}
		/>
	);
};

export default URLImage;
