import React from "react";

function StatsBar({ stats }) {
  if (stats.length > 4) {
    throw new Error("won't fit!");
  }

  return (
    <div className="statsBar">
      {stats.map((stat) => (
        <IndividualStat key={stat.title} stat={stat} />
      ))}
    </div>
  );
}

function IndividualStat({ stat }) {
  const isUp = stat?.percentage?.type === "up";

  return (
    <div className="stat">
      <div className="title">{stat.title}</div>
      <div className="flex">
        <div className={"flexgrow number " + (isUp ? "up" : "down")}>
          {stat.number}
        </div>
        {stat.percentage && (
          <div className={stat.percentage.type + " percentage"}>
            <i className={"fas " + (isUp ? "fa-arrow-up" : "fa-arrow-down")} />
            {stat.percentage.number}%
          </div>
        )}
      </div>
      <div className="description">{stat.description}</div>
    </div>
  );
}

export default StatsBar;
