import React, { useEffect, useState } from "react";

import mixpanel from "../../../services/_mixpanel";
import CreateMain from "./pages/create/CreateMain";
import { getFullPatient } from "../../../services/patients";
import { useUI } from "../../../_generic/UIContext";
import ClientViewContainer from "./container/ClientViewContainer";

function ClientViewMain({ patientID, onHide }) {
  const isNewClient = patientID === "new";

  const [showCreateAssessment, setShowCreateAssessment] = useState(isNewClient);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [fullPatient, setFullPatient] = useState({});

  const UI = useUI();

  useEffect(() => {
    mixpanel.track(!isNewClient ? "Client Viewed" : "New Client View", {
      patientID,
    });

    UI.showLoading();

    if (!isNewClient) {
      loadFullUser();
    } else {
      UI.hideLoading();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadFullUser = async () => {
    const { data, status } = await getFullPatient(patientID);

    if (status !== 200 || !data.length) {
      UI.showToast("There was an error in loading this patient");
      onHide();
      return;
    }

    setFullPatient({ ...data[0] });
    UI.hideLoading();
  };

  return (
    <React.Fragment>
      {showCreateAssessment && (
        <CreateMain
          patient={fullPatient}
          selectedAssessment={selectedAssessment}
          patientID={patientID}
          onHide={() => {
            if (isNewClient) {
              onHide();
            } else {
              setShowCreateAssessment(false);
            }
          }}
          onUpdateSilently={(value) => setFullPatient({...value})}
        />
      )}

      <ClientViewContainer
        onHide={onHide}
        isNewClient={isNewClient}
        patient={fullPatient}
        onEditAssessment={(value) => {setSelectedAssessment(value); setShowCreateAssessment(true)}}
        onUpdateSilently={(value) => setFullPatient({...value})}
      />
    </React.Fragment>
  );
}

export default ClientViewMain;
