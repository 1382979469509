import React, { useState, useRef, useCallback, useEffect } from "react";

import MapChanger from "../mapChanger.js/MapChanger";
import { nodeTypes } from "./Circles";
import Sidebar from "./Sidebar";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
} from "react-flow-renderer";

function BodyMapV2({
  onIndexChange,
  templates,
  selectedIndex,
  currentTemplate,
  elements,
  onChange,
  compressedView,
}) {
  const reactFlowWrapper = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(elements || []);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    // eslint-disable-next-line
    []
    
  );

  useEffect(() => {
      if(onChange){
          onChange(nodes);
      }
  }, [nodes]);

  useEffect(() => {
    setNodes(elements.filter(e => e.template === currentTemplate.key));
  }, [currentTemplate]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left - 20,
        y: event.clientY - reactFlowBounds.top - 20,
      });

      const newNode = {
        id: String(new Date().valueOf()),
        type,
        position,
        data: { color: event.dataTransfer.getData("color") },
        template: currentTemplate.key
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  return (
    <div className="dndflow flex">
      <ReactFlowProvider>
        {!compressedView && <Sidebar />}
        <div>
          <div style={compressedView ? { pointerEvents: "none" } : {}}>
            <div ref={reactFlowWrapper} className="reactflow-wrapper">
              <ReactFlow
                style={{ width: "200px", height: "400px" }}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDrop}
                onDragOver={onDragOver}
                className="touchdevice-flow"
                nodeTypes={nodeTypes}
                panOnScroll={false}
                maxZoom={1}
                panOnDrag={false}
                minZoom={1}
                nodesDraggable={!compressedView}
              ></ReactFlow>
            </div>

            <img alt="body" src={currentTemplate?.img} className="bodyImg" />
          </div>
          <MapChanger
            onChange={onIndexChange}
            templates={templates}
            selectedIndex={selectedIndex}
            compressedView={compressedView}
          />
        </div>
      </ReactFlowProvider>
    </div>
  );
}

export default BodyMapV2;
