import React, { useState } from "react";
import { useUI } from "../../../../../_generic/UIContext";
import GrafoButton from "../../../../_generic/GrafoButton";
import { useData } from "../../../_generic/contexts/DataContext";
import ProfileInput from "./ProfileInput";

function EditProfile() {
  const data = useData();
  const UI = useUI();

  const originalUser = data.user;

  const [user, setUser] = useState(originalUser);

  const changeVal = (key, val) => {
    const newUser = user;
    newUser[key] = val;
    setUser({ ...newUser });
  };

  const onSubmit = async () => {
    try {
      const response = await data.updateUserObject(user);
      setUser({ ...response });
    } catch (err) {
      UI.showToast(err.message || err.err || err);
    }
  };

  return (
    <React.Fragment>
      <div>
        <div className="flexeven">
          <ProfileInput
            title="First Name"
            value={user.firstName}
            className="profileInputMarginRight"
            onChange={(value) => changeVal("firstName", value)}
          />
          <ProfileInput
            title="Last Name"
            value={user.lastName}
            onChange={(value) => setUser({ ...user, lastName: value })}
          />
        </div>
      </div>
      <ProfileInput
        title="Practice Name"
        value={user.practiceName || ""}
        onChange={(value) => setUser({ ...user, practiceName: value })}
      />
      <ProfileInput
        title="Title"
        value={user.title || ""}
        placeholder="e.g PT, DPT, BA, APTA, FAPTA"
        onChange={(value) => setUser({ ...user, title: value })}
      />
      <div>
        <div className="flexeven">
          <ProfileInput
            title="Email (Read-only)"
            value={user.email}
            className="profileInputMarginRight"
            disabled={true}
          />
          <ProfileInput
            title="Phone Number"
            value={user.phoneNumber || ""}
            onChange={(value) => setUser({ ...user, phoneNumber: value })}
          />
        </div>
      </div>
      <ProfileInput
        title="Address"
        value={user.address || ""}
        onChange={(value) => setUser({ ...user, address: value })}
      />
      <GrafoButton onClick={onSubmit}>Save Changes</GrafoButton>
    </React.Fragment>
  );
}

export default EditProfile;
