function transformClientData(data){
    return data.map((row) => {
        return {
          _id: row._id,
          fullName: row.firstName + " " + row.lastName,
          ...row
        };
      });
}

export default transformClientData;