import POServices from "../components/POServices";
import POCalendarSelection from "../components/POCalendarSelection";
import POCustomerDetails from "../components/POCustomerDetails";


const poPages = [
    {
        name: "services",
        component: POServices
    },
    {
        name: "calendarSelection",
        component: POCalendarSelection
    },
    {
        name: "customerDetails",
        component: POCustomerDetails
    }
]

export default poPages;