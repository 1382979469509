const moment = require("moment-timezone");

function onTimeAdd(day, changeDayTimes, times, UI) {
  const lastTime = times[times.length - 1].end;
  const lastTimeAsMoment = moment.utc(lastTime, "H:mm");

  const newStart = moment.utc(lastTimeAsMoment).add(30, "minutes");
  const newEnd = moment.utc(newStart).add(30, "minutes");

  if (newStart.format("H:mm") === "0:00") {
    UI.showToast(
      "There's no more time in the day to add, please edit the times instead"
    );
    return;
  }

  changeDayTimes(day, [
    ...times,
    {
      start: newStart.format("H:mm"),
      end: newEnd.format("H:mm"),
    },
  ]);
}

function onCheckboxChange(e, day, changeDayTimes) {
  const newVal = e.target.checked;

  if (!newVal) {
    changeDayTimes(day, []);
  } else {
    changeDayTimes(day, [
      {
        start: "09:00",
        end: "17:00",
      },
    ]);
  }
}

function isTimeErrors(times){
    for(let i = 0; i < times.length; i++){
        const start = moment.utc(times[i].start, "H:mm");
        const end = moment.utc(times[i].end, "H:mm");

        const previousEnd = times[i-1] ? moment.utc(times[i-1].end, "H:mm") : null;

        if(doTimesOverlap(start, end) || isStartBeforePreviousEnd(previousEnd, start)){
            return {isErrors: true, index: i}
        }
    }

    return {isErrors: false}
}

function isStartBeforePreviousEnd(previousEnd, start){
    return !previousEnd ? false : start.isSameOrBefore(previousEnd);
}


function doTimesOverlap(start, end){
    return start.isAfter(end, "minute");
}

export { onTimeAdd, onCheckboxChange, isTimeErrors};
