function MassageNotes({ data, onChange }) {
	return (
		<div>
			<h3>Notes</h3>
			<textarea
				onChange={(e) =>
					onChange({
						notes: e.target.value,
					})
				}
				value={data.notes}
				style={{ minHeight: 120 }}
				placeholder="Add some notes about the assessment"
			/>
		</div>
	);
}

export default MassageNotes;
