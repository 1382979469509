import { addOrUpdateFormTemplates } from "../../../../../services/patients";

async function updateFormTemplateData(form, formTemplates, setFormTemplates){
    const { data, status } = await addOrUpdateFormTemplates(form);

    if (status !== 200) {
      throw new Error(data);
    }

    let newFormTemplates = formTemplates.filter(
      (f) => String(f._id) !== String(data._id)
    );

    newFormTemplates.push(data);

    setFormTemplates([...newFormTemplates]);

    return data;
}

export default updateFormTemplateData;