import moment from "moment";
import { useEffect, useState } from "react";
import {
  cancelCalendarEntryUnAuth,
  createCalendarEntryUnAuth,
  getCalendarDataUnAuth,
} from "../../../../services/calendar";
import isMobile from "../../../../services/isMobile";
import getParams from "../../../../services/params";
import { useUI } from "../../../../_generic/UIContext";
import POBookNow from "./components/POBookNow";
import POCancellation from "./components/POCancellation";
import POCheckoutBox from "./components/POCheckoutBox";
import POError from "./components/POError";
import POHeader from "./components/POHeader";
import POLoadingMain from "./components/POLoadingMain";
import POSuccess from "./components/POSuccess";
import poPages from "./config/poPages";

function PatientCalendarBooking() {
  const UI = useUI();

  const physioID = getParams().id;

  const [showCancellation, setShowCancellation] = useState(false);
  const [pageIndex, setPage] = useState(0);
  const [physio, setPhysio] = useState();
  const [services, setServices] = useState();
  const [selectedService, setSelectedService] = useState();
  const [patientDetails, setPatientDetails] = useState({});
  const [calendarEntries, setCalendarEntries] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment.utc().add(1, "day").set({ hours: 9, minutes: 30 })
  );

  const currentPage = poPages[pageIndex];

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const cancelIt = async () => {
    try {
      const calBookingID = getParams().calID;

      if(!calBookingID){
        setShowError(true);
        throw new Error("We couldn't cancel your appointment. Please contact your clinician to cancel your appointment.")
      }

      const {data, status} = await cancelCalendarEntryUnAuth(calBookingID, physioID);

      if(status !== 200){
        throw new Error(data?.error || data?.message);
      }

      setShowCancellation(true);
    } catch(err){
      setShowError(true);
      UI.showToast(err?.message || err?.error || "There was an error in cancelling your appointment, please contact support")
    }
  };

  const loadData = async () => {
    if (!physioID) {
      setShowError(true);
      UI.showToast("We couldn't find your page, please contact us");
      return;
    }

    if(getParams().cancellation){
      cancelIt();
      return;
    }

    const { data, status } = await getCalendarDataUnAuth(physioID);

    if (status !== 200) {
      setShowError(true);
      UI.showToast(
        data?.error ||
          data?.message
      );
      return;
    }

    setSelectedService(data.services[0]);
    setServices(data.services);
    setPhysio(data.physio);
    setCalendarEntries(data.calendar);
    setIsLoading(false);
  };

  const submit = async (patientData) => {

    try {
      const { data, status } = await createCalendarEntryUnAuth({
        patient: patientData._id,
        service: selectedService._id,
        timezone: physio.calendarSettings?.timezone || "Europe/London",
        startDateTime: moment.utc(selectedDate).format(),
        durationInMins: selectedService.durationInMins,
        price: selectedService.price,
        cancellationLink:
          window.location.origin + "/bookings?cancellation=true",
        currency: physio.currency || "GBP",
      }, physio._id);

      if (status !== 200) {
        throw new Error(
          data?.error
        );
      }

      UI.showSuccess("Appointment confirmed!")
      UI.hideLoading();

      setShowSuccess(true);

    } catch (err) {
      UI.showToast(err?.error || err?.message || "There was an error in creating your request, please contact support")
    }
  };

  if(showSuccess){
    return <POSuccess startDate={selectedDate} userDetails={physio}  />
  }

  if (showError) {
    return <POError currentPage={currentPage} />;
  }

  if (showCancellation) {
    return <POCancellation />;
  }

  return (
    <div className="userOnboarding calendarView">
      <POHeader
        practiceName={physio?.practiceName}
        onBack={() => {
          setPage(pageIndex - 1);
        }}
        allowBack={pageIndex > 0}
        isLoading={isLoading}
        currentPage={currentPage}
      />
      <div className="flexgrow flex mainContent" style={{ marginTop: "-15px" }}>
        {!isLoading && (
          <div
            className="whiteBox flexgrow"
            style={{ paddingRight: !isMobile ? "40px" : "" }}
          >
            <currentPage.component
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              services={services}
              physio={physio}
              calendarEntries={calendarEntries}
              nextPage={() => {
                setPage(pageIndex + 1);
              }}
              onSubmit={submit}
              currentPage={currentPage}
              setPatientDetails={setPatientDetails}
              patientDetails={patientDetails}
            />
          </div>
        )}
        {isLoading && <POLoadingMain />}
        <div>
          <div className="poCheckoutBox">
            <POCheckoutBox
              pageIndex={pageIndex}
              selectedDate={selectedDate}
              physio={physio}
              selectedService={selectedService}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      {pageIndex === 0 && !isLoading && <POBookNow onNext={() => setPage(pageIndex + 1)} />}
    </div>
  );
}

export default PatientCalendarBooking;
