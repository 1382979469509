import { Slider } from "@mui/material";
import { TextField } from "@mui/material";

function PainAssessmentField({ data, onChange, color }) {
  const fields = data.fields || {
    worst: { value: 1, reason: "" },
    best: { value: 1, reason: ""},
    rightNow: { value: 1, reason: "" },
  };

  const painConfig = [
    {
      name: "Worst",
      key: "worst",
    },
    {
      name: "Best",
      key: "best",
    },
    {
      name: "Right Now",
      key: "rightNow",
    },
  ];

  const changeValues = (key, value) => {
    let newFields = fields;
    newFields[key] = value;
    onChange({fields: newFields});
  };

  return (
    <div className="flexeven painAssessment">
      {painConfig.map((p, i) => (
        <Row
          {...p}
          {...data}
          color={color}
          name={p.name}
          value={fields[p.key]}
          onChange={(newVal) => {

            changeValues(p.key, newVal)
          }}
        />
      ))}
    </div>
  );
}

function Row({ name, value, reason, onChange, color }) {
  return (
    <div>
      <div style={{ fontSize: "14px" }}>{name}</div>
      <div className="flex">
        <div className="flexgrow">
          <Slider
            size="small"
            defaultValue={1}
            value={value?.value || 1}
            onChange={(e, newNum) => {
              onChange({ ...value, value: newNum })
            }}
            min={0}
            max={10}
            aria-label="Small"
            valueLabelDisplay="auto"
          />
        </div>
        <div
          style={{
            fontWeight: 800,
            fontSize: "11px",
            color,
            marginLeft: "10px",
          }}
        >
          {value?.value || 1} / 10
        </div>
      </div>
      <div>
        <TextField
          placeholder="Reason"
          variant="standard"
          value={value?.reason || ""}
          onChange={(e) => {
            onChange({ ...value, reason: e.target.value });
          }}
        />
        {reason}
      </div>
    </div>
  );
}

export default PainAssessmentField;
