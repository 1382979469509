import { Checkbox } from "@mui/material";
import React from "react";
import { numberAsCurrency } from "../../../../_generic/numberAsCurrency";
import getDuration from "../logic/getDuration";

function POServices({ services, physio, selectedService, setSelectedService }) {
  
  return (
    <React.Fragment>
      {services.map((s) => (
        <div
          key={s._id}
          onClick={() => setSelectedService(s)}
          className="poServiceList flex"
        >
          <div>
            <Checkbox checked={selectedService?._id === s._id} />
          </div>
          <div>
            <h2>{s.title}</h2>
            <div className="mins">{getDuration(s.durationInMins)}</div>
            <div>{s.description}</div>
          </div>
          <div className="price">
            {numberAsCurrency(s.price, physio.currency || "GBP")}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}
export default POServices;
