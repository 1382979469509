import { Checkbox } from "@mui/material";

import React, { useState } from "react";
import { useUI } from "../../../../../_generic/UIContext";
import GrafoButton from "../../../../_generic/GrafoButton";
import { useData } from "../../../_generic/contexts/DataContext";
import {
  isTimeErrors,
  onCheckboxChange,
  onTimeAdd,
} from "./calendarSettingsLogic";
import { defaultBookingTimes } from "./defaultCalendarTimeSettings";
import TimeSelection from "./TimeSelection.jsx";

function OpeningHours() {
  const data = useData();
  const { user } = data;
  const UI = useUI();

  const [openingHours, setOpeningHours] = useState(
    user?.calendarSettings?.openingHours || defaultBookingTimes
  );

  const anyErrors = Object.keys(openingHours).some(day => {
    const times = openingHours[day];
    const { isErrors } = isTimeErrors(times);
    return isErrors;
  });

  const [hasChanged, setHasChanged] = useState(false);

  const changeDayTimes = (day, value) => {
    setHasChanged(true);
    let newOpeningHours = openingHours;
    newOpeningHours[day] = value;
    setOpeningHours({ ...newOpeningHours });
  };

  const onSave = async () => {
    try {

      let newCalSettings = user.calendarSettings || {};
      newCalSettings.openingHours = openingHours;

      await data.updateUserObject({
        "calendarSettings":  newCalSettings
      });

    } catch(err){
      UI.showToast(err?.message || "There was an error with saving your changes")
    }
    
  }

  return (
    <React.Fragment>
      <GrafoButton onClick={onSave} disabled={!hasChanged || anyErrors}>
        <i className="fa-solid fa-floppy-disk"></i> Save Changes
      </GrafoButton>
      {Object.keys(openingHours).map((day) => {
        const times = openingHours[day];
        const { isErrors, index } = isTimeErrors(times);

        return (
          <React.Fragment key={day}>
            <div className={"flex timeSchedule " + (isErrors ? "error" : "")}>
              <div className="month">
                <div>
                  <Checkbox
                    onChange={(e) => onCheckboxChange(e, day, changeDayTimes)}
                    checked={times.length > 0}
                  />
                  {day}
                </div>
                <div className="errorMessage">
                  The times you have provided are invalid. Please check for any
                  overlaps in time. For example, check your start and end dates
                  don't overlap with the previous row.
                </div>
              </div>
              <div className="flexgrow">
                <TimeSelection
                  errorIndex={index}
                  onChange={(newTimes) => changeDayTimes(day, newTimes)}
                  times={times}
                  onAdd={() => onTimeAdd(day, changeDayTimes, times, UI)}
                />
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

export default OpeningHours;
