function getToken() {
  return localStorage.getItem("grafoToken")
}

async function callAPI(url, paramaters) {
  const body = paramaters.body;

  console.log("calling " + url, body);

  const response = await fetch("/.netlify/functions" + url, {
    ...paramaters,
    body: body ? JSON.stringify(body) : null,
    headers: {
      authorization: getToken(),
    },
  });



  let data;
  const status = response.status;

  if (status === 401) {
    localStorage.removeItem("grafoToken");
    window.location.href = "/login";
    return;
  }

  try {
    data = await response.json();
  } finally {
    // console.log({status, data});
    return { status, data };
  }
}

export { callAPI, getToken };
