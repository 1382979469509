import React, { useState } from "react";
import { useUI } from "../../../../../_generic/UIContext";
import GrafoButton from "../../../../_generic/GrafoButton";
import { useData } from "../../../_generic/contexts/DataContext";
import GeneralForm from "./GeneralForm";

function GeneralMain({ patient, patientID, navigate }) {
  const UI = useUI();
  const data = useData();
  const [changedPatient, setPatient] = useState(patient || {});
  const [showSave, setShowSave] = useState(false);

  const submit = async () => {
    try {
      UI.showLoading();
      const newPatient = await data.updatePatient(changedPatient);

      if (patientID === "new") {
        setPatient({ ...newPatient });
      }

      UI.hideLoading();

      UI.showSuccess();
      setShowSave(false);
    } catch (data) {
      UI.showToast(
        data?.error?.message ||
          "There was an error, please check that you have filled in the fields correctly"
      );
      UI.hideLoading();
    }
  };

  const setValue = (value) => {
    setPatient({ ...value });
    setShowSave(true);
  };

  return (
    <React.Fragment>
      <GrafoButton
        disabled={!showSave}
        style={{ marginBottom: "40px" }}
        onClick={() => submit()}
      >
        Save Changes
      </GrafoButton>
      <GeneralForm
        onChange={(value) => setValue(value)}
        patient={changedPatient}
      />
    </React.Fragment>
  );
}

export default GeneralMain;
