import { createCheckout } from "../../../../../services/payments";

async function redirectToCheckout(){
    const {data, status} = await createCheckout();

    if(status !== 200){
        throw new Error("Unfortunately we couldn't send you to Stripe to take your payment. Please contact us");
    }

    window.location.href = data.url;
}

export default redirectToCheckout;