
import { useNavigate } from "@reach/router";
import GrafoButton from "../../../../_generic/GrafoButton";

function FormError() {

    const navigate = useNavigate();
    
  return (
    <div>
      <h2>Hey there! 👋</h2>
      You need to create a form to be able to use it for your patients. You can click the blue button below to create your first form.
      <div><GrafoButton style={{marginTop:"40px"}} onClick={() => navigate("/forms/new")}>Build a Form</GrafoButton></div>
    </div>
  );
}

export default FormError;
