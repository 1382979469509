import { addOrUpdatePatient } from "../../../../../services/patients";
import mixpanel from "../../../../../services/_mixpanel";

async function updatePatientData(patient, user, setUser){
    const { data, status } = await addOrUpdatePatient(patient);

    if (status !== 200) {
      throw new Error(data);
    }

    let newUser = user;
    let patients = newUser.patients || [];

    let index = patients.findIndex(
      (pa) => String(pa._id) === String(data._id)
    );

    if(index === -1){
      mixpanel.track("Patient Added", {patient: data?._id});
      newUser.patients = [data];
    } else {
      mixpanel.track("Patient Details Updated");
      newUser.patients[index] = data;
    }

    setUser({ ...newUser });

    return data;
}

export default updatePatientData;