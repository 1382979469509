import { CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";
import { getPaymentPortal } from "../../../../services/payments";
import { useUI } from "../../../../_generic/UIContext";
import GrafoButton from "../../../_generic/GrafoButton";
import { useData } from "../contexts/DataContext";
import redirectToCheckout from "../contexts/helpers/redirectToCheckout";
import mixpanel from "../../../../services/_mixpanel";

function GrafoPaymentManagement() {
  const [isLoading, setIsLoading] = useState(false);
  const data = useData();
  const UI = useUI();

  const user = data.user;

  const colors = {
    trialing: "orange",
    active: "green",
  };

  const getColor = (status) => {
    if (!status) {
      return "red";
    }

    return colors[status] || "red";
  };

  const showPortal = async () => {
    mixpanel.track("Stripe", {actionType: "Portal Launch"});
    setIsLoading(true);

    const { data, status } = await getPaymentPortal();

    setIsLoading(false);

    if (status !== 200) {
      UI.showToast(
        "We couldn't load your payment account. Please contact support and we'll help you with your subscription."
      );
      return;
    }

    window.location.href = data.url;
  };

  const newCheckout = async () => {
    mixpanel.track("Stripe", {actionType: "New Checkout Session"});
    await redirectToCheckout();
  };

  return (
    <div className="stripeBox">
      {isLoading && (
        <div className="loading flexmiddle">
          <div>
            <CircularProgress />
          </div>
        </div>
      )}
        <div>
          <div className="header coverimage" />
          <div className="pad logo">
            <div className="square coverimage" />
          </div>

          <div className="pad">
            <div className="subTitle">Grafo {!data.user.isCalendarEnabled ? "Basic" : "Pro"} Membership</div>
            <div
              style={{
                color: getColor(user.status),
                textTransform: "uppercase",
                fontSize:"var(--small)",
                fontWeight:"600",
                marginBottom:"30px"
              }}
            >
              {user.status || "Not Active"}
            </div>

            {user.status ? (
              <React.Fragment>
                <GrafoButton onClick={showPortal}>
                  View Subscription
                </GrafoButton>

                {user.status !== "active" && <div
                  className="subscriptionExpiredMessage"
                  onClick={newCheckout}
                >
                  If your subscription has expired, <b>click here</b> to
                  generate a new subscription.
                </div>}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <GrafoButton onClick={newCheckout}>
                  Start a Subscription
                </GrafoButton>
              </React.Fragment>
            )}
          </div>
        </div>

    </div>
  );
}

export default GrafoPaymentManagement;
