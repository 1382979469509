import POHeader from "./POHeader";

function POError({currentPage}) {
  return (
    <div className="userOnboarding calendarView">
      <POHeader currentPage={currentPage} customTitle="No calendar found!" />
      <div
        style={{
          backgroundColor: "white",
          fontSize: "15px",
          marginTop: "20px",
        }}
      >
        We're really sorry, we couldn't find the page you were looking for.
        <br />
        <br />
        This is most likely because the link is incorrect, or the person you are
        looking to book with is no longer paying for our services.
        <br />
        <br />
        Please contact them directly so they know their link is no longer
        working.
      </div>
    </div>
  );
}

export default POError;
