function KeyBox({ config, shapeSize, dragUrl, onPenClick }) {
	return (
		<div
			style={{
				border: "1px solid #dedede",
				borderRadius: 10,
				padding: 20,
				marginRight: 20,
			}}>
			<h3 style={{ marginTop: 5 }}>
				Key{" "}
				<span style={{ fontWeight: "normal", fontSize: 14 }}>
					(drag and drop)
				</span>
			</h3>
			{config.draggableImages.map((el, i) => {
				let images = [el];

				if (el.list) {
					images = el.list;
				}

				return (
					<div
						style={{ marginBottom: 20 }}
						className="flexbetween flexcenter"
						key={i}>
						<div style={{ marginRight: 20 }}>{el.label}</div>
						{images.map((imgel, j) => {
							return (
								<img
									key={j}
									alt={imgel.label}
									src={imgel.img}
									style={{
										cursor: "grab",
										width: shapeSize,
									}}
									draggable="true"
									onDragStart={(e) => {
										dragUrl.current = e.target.src;
										dragUrl.label = imgel.label;
										dragUrl.id = imgel.id;
									}}
								/>
							);
						})}
					</div>
				);
			})}
			{config.allowDrawingLines && (
				<div
					style={{ marginBottom: 20 }}
					className="flexbetween flexcenter">
					<div style={{ marginRight: 20 }}>Pen</div>
					<div>
						<i
							onClick={() => onPenClick()}
							className="fas fa-pen"
							style={{ color: "var(--blue)", cursor: "pointer" }}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default KeyBox;
