import React from "react";
import assessmentConfig from "../assessmentConfig";

function Sidebar() {
  const onDragStart = (event, nodeType, color) => {
    event.dataTransfer.setData("color", color);
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <div>
      <div className="circleSelector">
        {assessmentConfig.colors.map((c) => (
          <Color
            key={c.color}
            color={c.color}
            name={c.name}
            onDragStart={(event) => onDragStart(event, "special", c.color)}
          />
        ))}
      </div>
    </div>
  );
}

function Color({ color, name, onDragStart }) {
  return (
    <div>
      <div
        style={{ backgroundColor: color }}
        key={new Date()}
        className="draggableCircle"
        draggable
        onDragStart={onDragStart}
      ></div>
      <div className="circleName">{name}</div>
    </div>
  );
}

export default Sidebar;
