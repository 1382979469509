import moment from "moment";
import React, { useState } from "react";
import { addOrUpdateFormForPatient } from "../../../../../services/patients";
import { useUI } from "../../../../../_generic/UIContext";
import GrafoButton from "../../../../_generic/GrafoButton";
import GrafoTable from "../../../_generic/components/GrafoTable";
import { useData } from "../../../_generic/contexts/DataContext";
import FormManage from "./FormManage";
import FormViewer from "./FormViewer";

function ClientFormsMain({ patientID, patient, updateSilently }) {
  const forms = patient?.patientForms || [];
  const [selected, setSelected] = useState({});
  const [showSelected, setShowSelected] = useState(false);
  const [showSpecificForm, setShowSpecificForm] = useState();
  const [newForm, setNewForm] = useState({});

  const UI = useUI();
  const data = useData();

  const submit = async () => {


    if (!newForm.answers) {
      UI.showToast("Please add some answers to the form")
      return;
    }


    UI.showLoading();
    const { data, status } = await addOrUpdateFormForPatient({
      ...newForm,
      patient: patientID,
    });
    UI.hideLoading();

    if (status === 200) {
      UI.showSuccess();

      let newPatient = patient;
      newPatient.patientForms = newPatient.patientForms.concat(data);
      setShowSelected(null);
      setNewForm({});
      updateSilently(newPatient);
    } else {
      UI.showToast(
        data.error ||
        data.message ||
        "There was an error in submitting the form"
      );
    }
  };

  if (showSpecificForm) {
    return (
      <FormViewer
        onHide={() => setShowSpecificForm(null)}
        form={showSpecificForm}
      />
    );
  }

  const hideSelected = () => {
    setShowSelected(false);
    setSelected(null);
    setNewForm({});
  };

  if (showSelected) {
    return (
      <React.Fragment>
        <FormManage
          onChange={(value) => {
            setNewForm(value);
          }}
          patientID={patientID}
          form={selected}
          data={data}
          onBack={hideSelected}
        />
        <GrafoButton
          onClick={submit}
          style={{ position: "absolute", top: 50, right: 30 }}
        >
          Save Assessment
        </GrafoButton>
      </React.Fragment>
    );
  }

  return (
    <div>
      <div
        onClick={() => {
          setNewForm({});
          setShowSelected(true);
          setSelected({});
        }}
        className="addForm"
      >
        + Add Form
      </div>
      {forms.length > 0 && (
        <GrafoTable
          onClick={(row) => setShowSpecificForm(row)}
          data={forms.map(f => {
            return { ...f, creationDateString: moment(f.creation).format("Do MMMM YYYY") }
          }).sort(
            (a, b) =>
              moment(b.creation).format("X") - moment(a.creation).format("X")
          )}
          fields={["title", "creationDateString"]}
        />
      )}
    </div>
  );
}

export default ClientFormsMain;
