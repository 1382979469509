function NewAuthenticatedError() {
  return (
    <div>
      <h2>Hey there! 👋</h2>
      <div>
        This page is for patients. If you want to see your form or calendar, try going in
        incognito mode.
      </div>
    </div>
  );
}

export default NewAuthenticatedError;
