import { FormControl, MenuItem, Select } from "@mui/material";

function SelectAssociations({ daysFilter, onChange }) {
  return (
    <div style={{ textAlign: "right" }}>
      <FormControl>
        <Select
          style={{ backgroundColor: "white", marginBottom: "20px" }}
          value={daysFilter}
          onChange={(e) => onChange(e.target.value)}
        >
          <MenuItem value={30}>Last 30 days</MenuItem>
          <MenuItem value={60}>Last 60 days</MenuItem>
          <MenuItem value={90}>Last 90 days</MenuItem>
          <MenuItem value={90}>Six Months</MenuItem>
          <MenuItem value={9999}>All time</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectAssociations;
