
import React from "react";

import AssessmentBox from "./AssessmentBox";

function AssessmentDescriptionMain({ elements, onChange, onRemove }) {
  const changeElements = (index, value) => {
    let newElements = elements;
    newElements[index] = { ...newElements[index], ...value };
    onChange([...newElements]);
  };

  const removeElements = (index) => {
    let newElements = elements;
    newElements.splice(index, 1);
    onChange([...newElements]);
  }

  return (
    <React.Fragment>
      {elements &&
        elements.length > 0 &&
        elements.map((el, key) => (
          <AssessmentBox
            elements={elements}
            element={el}
            key={key}
            onChange={(value) => changeElements(key, value)}
            onRemove={() => removeElements(key)}
          />
        ))}

    </React.Fragment>
  );
}

export default AssessmentDescriptionMain;
