
import { callAPI } from "./api";

async function getServices() {
  return callAPI("/ManageServices", {
    method: "GET",
  });
}

async function updateServices(updates) {
  return callAPI("/ManageServices", {
    method: "PUT",
    body: {updates}
  });
}

export {getServices, updateServices}
