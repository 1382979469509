import moment from "moment";
import React from "react";

function DateCellRenderer(params){
    return (
        <React.Fragment>
          <i className="fas fa-calendar" style={{ marginRight: "5px" }} />{" "}
          {moment.utc(params.value).format("Do MMM YYYY")}
        </React.Fragment>
      );
}

export default DateCellRenderer;