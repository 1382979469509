function MapChanger({ onChange, selectedIndex, templates, compressedView }) {
  
  const currentTemplate = templates[selectedIndex];

  const isDisabled = (direction) => {
    if (direction === "left") {
      return selectedIndex === 0;
    }

    if (direction === "right") {
      return selectedIndex === templates.length - 1;
    }

    throw new Error("incorrect direction key");
  };

  return (
    <div className={"flexcenter changeMapControls " +( compressedView ? "compressed":"")} >
      <div
        className={isDisabled("left") ? "disabled" : ""}
        onClick={() => onChange(selectedIndex - 1)}
      >
        <i className="fas fa-arrow-left" />
      </div>

      <div className="flexgrow">{currentTemplate.title}</div>

      <div
        className={isDisabled("right") ? "disabled" : ""}
        onClick={() => onChange(selectedIndex + 1)}
      >
        <i className="fas fa-arrow-right" />
      </div>
    </div>
  );
}

export default MapChanger;
