import { FormControl, TextField } from "@mui/material";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import GrafoButton from "../../../../_generic/GrafoButton";


function ReferAFriend({ user, UI }) {
  if (user.association) {
    return <ReferAssociation user={user} />;
  }

  return <ReferNormal user={user} UI={UI} />;
}

function ReferAssociation({ user, UI }) {

  const referralurl =
    window.location.origin + "?association=" + user.association;

  return (
    <React.Fragment>
      Thanks for signing up as an association! The people you refer get <b>50% off
      their first 6 months</b>, and you get <b>8% from each payment in their first 12
      months</b>!
      <br />
      <br />
      <div className="flexeven">
        <FormControl>
          <TextField value={referralurl} style={{ marginRight: "15px" }} />
          <CopyToClipboard
            options={{ debug: true }}
            text={
              "Join GrafoNotes today for an easy to use, digital notes system. 10 days free trial and then 50% off your first 6 months when you use my link. " +
              referralurl
            }
            onCopy={() => UI.showSuccess("Copied!")}
          >
            <GrafoButton className="grafoButton buttonRight">Copy</GrafoButton>
          </CopyToClipboard>
        </FormControl>
        <FormControl></FormControl>
      </div>
    </React.Fragment>
  );
}

function ReferNormal({ user, UI }) {
  const referralurl =
    window.location.origin +
    "?refer=" +
    user.firstName.charAt(0).toUpperCase() +
    user.lastName;

  return (
    <React.Fragment>
      For each friend you refer, you get <b>1 month free</b> and your{" "}
      <b>friend gets 25% off their first month</b>. Copy the link below to share
      with your friends.
      <br />
      <br />
      <div className="flexeven">
        <FormControl>
          <TextField value={referralurl} style={{ marginRight: "15px" }} />
          <CopyToClipboard
            options={{ debug: true }}
            text={
              "Join GrafoNotes today for an easy to use, digital notes system. 10 days free trial and then 25% off your first month when you use my link. " +
              referralurl
            }
            onCopy={() => UI.showSuccess("Copied!")}
          >
            <GrafoButton className="grafoButton buttonRight">Copy</GrafoButton>
          </CopyToClipboard>
        </FormControl>
        <FormControl></FormControl>
      </div>
    </React.Fragment>
  );
}

export default ReferAFriend;
