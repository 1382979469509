import { Input, InputAdornment } from "@mui/material";
import React from "react";

function GrafoSearchBar({ placeholder, value, setValue, className, onChange }) {
  return (
    <Input
    className={className}
      style={{
        border: "1px solid var(--blue)",
        padding: "10px",
        borderRadius: "4px",
        backgroundColor: "var(--white)",
      }}
      fullWidth={true}
      startAdornment={
        <InputAdornment position="start">
          <i className="fa-solid fa-magnifying-glass"></i>
        </InputAdornment>
      }
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder || "Search"}
    />
  );
}

export default GrafoSearchBar;
