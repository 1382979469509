import { useState } from "react";
import ClientViewTabs from "../tabs/ClientViewTabs";
import tabConfig from "../tabs/tabConfig";

function ClientViewContainer({ isNewClient, onHide, patient, onEditAssessment, onUpdateSilently }) {
  const patientID = patient._id;

  const [tabIndex, setTabIndex] = useState(0);
  const Component = tabConfig[tabIndex].component;

  return (
    <div className="clientView">
      <div className="header viewPadding">
        <div className="flexcenter">
          <div onClick={onHide}>
            <i
              className="fas fa-times"
              style={{ cursor: "pointer", marginRight: "15px" }}
            />
          </div>
          <div className="title flexgrow">
            {isNewClient
              ? "New Client"
              : patient.firstName + " " + patient.lastName}
          </div>
          <div></div>
        </div>

        <ClientViewTabs
          selected={tabIndex}
          tabsList={tabConfig}
          onChange={(index) => {
            setTabIndex(index);
          }}
        />
      </div>
      <div className="content viewPadding" style={{ paddingBottom: "50px" }}>
        <div style={{ backgroundColor: "white", padding: "30px" }}>
          <Component updateSilently={onUpdateSilently} patientID={patientID} onCreate={() => onEditAssessment({})} patient={patient} onEditAssessment={onEditAssessment} />
        </div>
      </div>
    </div>
  );
}

export default ClientViewContainer;
