
import routes from "../config/routes";

function isPathSelected(currentPath, requestedPath) {

    if(currentPath === requestedPath){
        return true;
    } else {
      return false;
    }

}

function getCurrentPath(currentPath) {
  return routes.find(
    (r) => r.path === currentPath
  );
}

export { isPathSelected, getCurrentPath };
