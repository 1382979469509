import URLImage from "./URLImage";
import useImage from "use-image";
import { Stage, Layer, Image, Line } from "react-konva";
import { useState } from "react";

function MainStage({
	config,
	stageRef,
	painPoints: images,
	setPainPoints: setImages,
	shapeSize,
	dragUrl,
	penClicked,
	setPenClicked,
}) {
	const [image] = useImage(config.backgroundImage);
	const [firstLineClickCoords, setFirstLineClickCoords] = useState();

	const bgImageWidth = 448;
	const bgImageHeight = 531;

	return (
		<div
			onDrop={(e) => {
				e.preventDefault();
				// register event position
				stageRef.current.setPointersPositions(e);

				let pointers = stageRef.current.getPointerPosition();
				pointers.x = pointers.x + 20;
				pointers.y = pointers.y + 15;
				// add image
				setImages(
					images.concat([
						{
							...pointers,
							src: dragUrl.current,
							type: "img",
							id: dragUrl.id,
							label: dragUrl.label,
						},
					])
				);
			}}
			onDragOver={(e) => e.preventDefault()}>
			<Stage
				onMouseMove={(e) => {
					if (firstLineClickCoords) {
						const coords = e.currentTarget.getPointerPosition();

						let newImages = images;
						newImages[images.length - 1] = {
							points: [
								firstLineClickCoords.x,
								firstLineClickCoords.y,
								coords.x,
								coords.y,
							],
							type: "line",
							stroke: "black",
							strokeWidth: 1,
						};

						setImages(newImages);
					}
				}}
				onMouseDown={(e) => {
					if (!penClicked) {
						return;
					}

					const coords = e.currentTarget.getPointerPosition();

					if (!firstLineClickCoords) {
						setImages(
							images.concat([
								{
									points: [coords.x, coords.y, coords.x, coords.y],
									type: "line",
									stroke: "black",
									strokeWidth: 1,
								},
							])
						);
						setFirstLineClickCoords(coords);
						return;
					}

					setImages(
						images.concat([
							{
								points: [
									firstLineClickCoords.x,
									firstLineClickCoords.y,
									coords.x,
									coords.y,
								],
								type: "line",
								stroke: "black",
								strokeWidth: 1,
							},
						])
					);

					setFirstLineClickCoords(null);

					setPenClicked(false);
				}}
				width={bgImageWidth}
				height={bgImageHeight}
				ref={stageRef}>
				<Layer>
					<Image
						x={0}
						y={0}
						width={bgImageWidth}
						height={bgImageHeight}
						image={image}
					/>
					{images.map((image, k) => {
						if (image.type === "img") {
							return (
								<URLImage
									key={k}
									shapeSize={shapeSize}
									image={image}
								/>
							);
						}

						if (image.type === "line") {
							console.log(image);
							return (
								<Line
									key={k}
									{...image}
								/>
							);
						}
					})}
				</Layer>
			</Stage>
		</div>
	);
}

export default MainStage;
