import moment from "moment";
import { getUser } from "../../../../../services/user";
import mixpanel from "../../../../../services/_mixpanel";

async function initializeDataProviderData(UI, setUser, setIsLoading, setShowCheckoutOptions, location) {
  const { data, status } = await getUser();

  //enabled if subscribed, or they're trialling
  data.isCalendarEnabled = !!data?.calendarSettings.subscribed || !data.status;

  if (status !== 200) {
    UI.showToast(
      "Uh-oh, we couldn't connect or there was an error, please logout and login again"
    );
    return;
  }

  window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "hhe43a82",
    name: data.firstName + " " + data.lastName, // Full name
    email: data.email, // Email address
    created_at: moment.utc(data.creation).unix(),
    user_id: data._id,
  });

  mixpanel.identify(data.email);
  mixpanel.people.set({
    ...data,
    $email: data.email,
    $name: data.firstName + " " + data.lastName,
    patients: null,
  });
  mixpanel.track("Accessed Platform");

  setUser(data);

  const urlParams = Object.fromEntries(
    new URLSearchParams(location.search)
  );

  if (urlParams.checkoutCancelled && data.status) {
    setIsLoading(false);
    setShowCheckoutOptions(true);
    return;
  }

  const trialDays = 10;
  const creation = moment.utc(data.creation);
  const endTrial = moment(creation).add(trialDays, "days").endOf("day");
  const today = moment.utc();

  const hasExpired = today.isAfter(endTrial);

  if (urlParams.session_id) {
    mixpanel.track("Stripe", { actionType: "Checkout Complete" });
    mixpanel.track("Sign Up");
    setIsLoading(true);
    window.setTimeout(() => {
      window.location = "/";
    }, 8000);
  } else if (["trialing", "active"].indexOf(data.status) > -1) {
    setIsLoading(false);
  } else if (hasExpired && !urlParams.session_id) {
    mixpanel.track("Stripe", { actionType: "Payment Expired" });
    setIsLoading(false);
    setShowCheckoutOptions(true);
  } else {
    setIsLoading(false);
  }
}

export default initializeDataProviderData;
