import { Skeleton } from "@mui/material";

function POLoadingMain() {
  return (
    <div className="whiteBox flexgrow">
      <Skeleton variant="rectangular" width={"50%"} height={38} />
      <Skeleton
        style={{ marginTop: "20px" }}
        variant="rectangular"
        width={"60%"}
        height={38}
      />
      <Skeleton
        style={{ marginTop: "20px" }}
        variant="rectangular"
        width={"40%"}
        height={38}
      />
      <Skeleton
        style={{ marginTop: "20px" }}
        variant="rectangular"
        width={"30%"}
        height={38}
      />
      <Skeleton
        style={{ marginTop: "20px" }}
        variant="rectangular"
        width={"45%"}
        height={38}
      />
      <Skeleton
        style={{ marginTop: "20px" }}
        variant="rectangular"
        width={"20%"}
        height={38}
      />
    </div>
  );
}

export default POLoadingMain;
