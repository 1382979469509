const formValidation = {
  name: {
    validation: (value) => {
      return value.split(" ").length >= 2;
    },
    message: "Please enter your full name",
  },
  email: {
    validation: (value) => {
      return value.indexOf("@") > -1 && value.indexOf(" ") === -1 && value.indexOf(".") > -1;
    },
    message: "Please enter a valid email address",
  },
  phoneNumber: {
    validation: (value) => {
      return value.length > 7;
    },
    message: "Please enter a valid phone number"
  },
  password: {
    validation: (value) => {
      return (
        value.length >= 7 &&
        value.toUpperCase() !== value &&
        value.toLowerCase() !== value
      );
    },
    message:
      "Upper & lower case and 7 characters in length",
  },
};

export default formValidation;
