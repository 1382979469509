import { updateUser } from "../../../../../services/user";

async function updateUserObjectData(updates, UI, user, setUser){
    UI.showLoading();
    const {data, status} = await updateUser(updates);
    UI.hideLoading();

    if(status !== 200){
      throw new Error("Update failed");
      
    }

    setUser({...user, ...data});
    return data;
}

export default updateUserObjectData;