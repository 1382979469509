import { TextField } from "@mui/material";
import React from "react";
import formValidation from "./FormValidation";

function FormInput({ value, onChange, label, type, validationKey, variant, focused }) {
  const validation = formValidation[validationKey];
  
  const showError =
    validation && value && value.length > 0 && !validation.validation(value);

  return (
    <React.Fragment>
      <div className="formInputContainer">
        <TextField
          error={!!showError}
          className="formInput"
          fullWidth={true}
          value={value}
          label={label}
          type={type}
          variant={variant || "outlined"}
          onChange={(e) => onChange(e)}
          focused={focused}
        />
        {showError && <div className="validation">{validation.message}</div>}
      </div>
    </React.Fragment>
  );
}

export default FormInput;
