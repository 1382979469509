import { List, ListItemButton, ListItemText } from "@mui/material";

import { Link, useLocation } from "@reach/router";
import React from "react";
import routes from "../config/routes";
import { useData } from "../contexts/DataContext";
import { isPathSelected } from "../utils/routeHelpers";

function Menu({showMobMenu, onHide }) {
  const location = useLocation();
  const data = useData();
  const user = data.user;

  return (
    <List className={"menu " + (showMobMenu ? "showMobMenu" : "")} onClick={onHide}>
      
      <div style={{ padding: "20px 30px" }}>
      <i className="mobileArrow fas fa-times-circle" />
        <img
          style={{ width: "140px" }}
          src="https://i.ibb.co/mCsfZQx/Grafo-logo.png"
          alt="Grafo-logo"
          border="0"
        />
      </div>
      {routes
        .filter((r) => r.show)
        .map((itm, key) => {
          return (
            <Link to={itm.path} key={key}>
              <ListItemButton
                className="menuButton"
                selected={isPathSelected(location.pathname, itm.path)}
              >
                <ListItemText>
                  <i className={"fas fa-fw fa-" + itm.icon} /> {itm.name}
                </ListItemText>
              </ListItemButton>
            </Link>
          );
        })}
      {user?.admin && (
        <Link to="/admin">
          <ListItemButton className="menuButton">
            <ListItemText>
              <i className="fas fa-fw fa-lock" /> Admin
            </ListItemText>
          </ListItemButton>
        </Link>
      )}
      {user?.association && (
        <Link to="/association">
          <ListItemButton className="menuButton">
            <ListItemText>
              <i className="fas fa-fw fa-lock" /> Associations
            </ListItemText>
          </ListItemButton>
        </Link>
      )}
      <ListItemButton
        className="menuButton coverimage"
        onClick={() => window.location.href = window.location.origin + "?product_tour_id=365545"}
        style={{
          marginTop:"30px",
          color: "white",
          backgroundImage: "url(https://i.ibb.co/kcbXzHZ/BK.jpg)",
        }}
      >
        Want to take a grafo tour?
      </ListItemButton>
    </List>
  );
}

export default Menu;
