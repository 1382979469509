import POHeader from "./POHeader";

function POCancellation({currentPage}){
    return (
        <div className="userOnboarding calendarView">
          <POHeader currentPage={currentPage} customTitle="Cancellation Successful" />
          <div
            style={{
              backgroundColor: "white",
              fontSize: "15px",
              marginTop: "20px",
            }}
          >
           Your cancellation has been confirmed! We have sent you a confirmation to your email address.
            <br /><br />Thanks for using <b>GrafoNotes</b>!
          </div>
        </div>
      );
}

export default POCancellation;