import AssessmentSummaryTitle from "./AssessmentSummaryTitle";

function AssessmentSummarySection({ title, fields, showColons, children}) {
  return (
    <div style={{marginBottom:"70px"}}>
      <AssessmentSummaryTitle>{title}</AssessmentSummaryTitle>
      {fields && fields.map((f, i) => {
        return (
          <div style={{marginBottom:"10px"}} key={i}>
            <b>{f.key}</b>{showColons && ":"} {f.value}
          </div>
        );
      })}
      {children}
    </div>
  );
}

export default AssessmentSummarySection;
