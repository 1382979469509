import moment from "moment";

function generateClientStats(data){

    const totals = getTotals(data);

    return [
        totalClients(totals),
        newClients(totals)
    ];
}

function getTotals(data){
    const lastMonth = moment.utc().subtract(1, "month");

    return data.reduce((accumulator, currentObj) => {
        const joined = moment.utc(currentObj.creation);

        const isCreatedThisMonth = createdThisMonth(joined);

        return {
            total: accumulator.total + 1,
            lastMonthTotal: !isCreatedThisMonth ? accumulator.lastMonthTotal + 1 : accumulator.lastMonthTotal,
            newClients: isCreatedThisMonth ? accumulator.newClients + 1 : accumulator.newClients,
            lastMonthNewClients: accumulator.lastMonthNewClients + (lastMonth.isSame(joined, "month") ? 1 : 0),
            totalAssessments: accumulator.totalAssessments + (currentObj.assessments || 0),
        }

    }, {total: 0, lastMonthTotal: 0, newClients: 0, lastMonthNewClients: 0, totalAssessments: 0});
}

function createdThisMonth(joinDate){
    return joinDate.isSame(moment.utc(), "month");
}

function totalClients(totals){
    return {
        title: "Total Clients",
        number: totals.total,
        percentage: getPercentage(totals.total, totals.lastMonthTotal),
        description: "last month: " + totals.lastMonthTotal
    }
}

function newClients(totals){

    return {
        title: "New Clients",
        number: totals.newClients,
        percentage: getPercentage(totals.newClients, totals.lastMonthNewClients),
        description: "last month: " + totals.lastMonthNewClients
    }
}




function getPercentage(partialValue, totalValue) {
    const number = (partialValue - totalValue) * 100;

    return {
        number: number === Infinity ? 100 : number,
        type: partialValue >= totalValue ? "up" : "down"
    }
 } 


export default generateClientStats;