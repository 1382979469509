import { useState } from "react";
import { addPatientNote } from "../../../../../services/patients";
import { useUI } from "../../../../../_generic/UIContext";
import GrafoButton from "../../../../_generic/GrafoButton";
import NotesMain from "./NotesMain";
import SOAPNotes from "./NotesDefaults/SOAPNotes";
import { useData } from "../../../_generic/contexts/DataContext";
import MassageNotes from "./NotesDefaults/MassageNotes";

function CreateNote({ onHide, patient, updateSilently, defaultValue }) {
	const { user } = useData();
	const patientType = user?.patientTypes[0] || "physio";
	const UI = useUI();

	const [newNote, setNewNote] = useState({
		content: defaultValue
			? defaultValue.content
			: patientType === "massage"
			? MassageNotes
			: SOAPNotes,
	});

	const submitNote = async () => {
		let note = {
			...newNote,
			patient: patient._id,
		};

		if (defaultValue?._id) {
			note = { ...defaultValue, ...note };
		}

		UI.showLoading();
		const { data, status } = await addPatientNote(note);
		UI.hideLoading();

		if (status === 200) {
			let newPatient = patient;

			if (defaultValue?._id) {
				newPatient.patientNotes = newPatient.patientNotes.map((pn) => {
					if (pn._id === defaultValue._id) {
						return data;
					} else {
						return pn;
					}
				});
			} else {
				newPatient.patientNotes = newPatient.patientNotes.concat(data);
			}

			updateSilently({ ...newPatient });
			UI.showSuccess();
			setNewNote("");
			onHide();
		} else {
			UI.showToast(
				data.error || data.message || "Oops! We couldn't upload the notes"
			);
		}
	};

	return (
		<div
			className="addnotecontainer"
			style={{ backgroundColor: "rgb(249 249 249)", padding: "15px" }}>
			<NotesMain
				notes={newNote}
				onChange={setNewNote}
			/>

			<div
				style={{
					position: "absolute",
					bottom: "10px",
					right: "10px",
					zindex: "10",
				}}>
				<GrafoButton
					style={{ backgroundColor: "#bebebe", marginRight: "15px" }}
					onClick={onHide}>
					Close
				</GrafoButton>
				<GrafoButton onClick={() => submitNote()}>Save</GrafoButton>
			</div>
		</div>
	);
}

export default CreateNote;
