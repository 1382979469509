import React, { useState } from "react";
import List from "react-smooth-draggable-list";
import RemoveConfirmation from "./RemoveQuestionConfirmation";
import SelectModal from "./SelectModal";
import DBStandard from "./dragBoxComponents/Standard/DBStandard";
import DBSection from "./dragBoxComponents/Standard/DBSection";

const components = {
  standard: DBStandard,
  section: DBSection
}

function DraggableBoxes({
  data,
  order,
  changeOrder,
  changeValue,
  onRemove,
  onClone,
}) {
  const [showSelectModal, setShowSelectModal] = useState();
  const [showRemoveConfirm, setShowRemoveConfirm] = useState();
  if (!data.length) {
    return <div>Loading...</div>;
  }
  return (
    <React.Fragment>
      {showRemoveConfirm && (
        <RemoveConfirmation
          onConfirm={() => {
            onRemove(showRemoveConfirm);
            setShowRemoveConfirm();
          }}
          onHide={() => setShowRemoveConfirm()}
        />
      )}
      <List
        order={order}
        onReOrder={(newOrder) => changeOrder(newOrder)}
        rowHeight={110}
      >
        {data.map((item, i) => {
          const Component = components[item.type] || components.standard;
          return <List.Item
            className="dragRow flex"
            key={i}
            dragHandle={< DragHandle />}
          >
            <div className="flexmiddle">
              <div className="actions">
                <i
                  className="fas fa-copy"
                  onClick={() => onClone(item)}
                />
                <i
                  className="fas fa-trash-alt"
                  onClick={() => setShowRemoveConfirm(item)}
                />
              </div>
            </div>
            <div className="flexgrow">
              <Component setShowSelectModal={setShowSelectModal} onClone={onClone} setShowRemoveConfirm={setShowRemoveConfirm} item={item} changeValue={changeValue} />
            </div>


          </List.Item>
        })}
      </List>
      {showSelectModal && (
        <SelectModal
          item={data.find((d) => d.key === showSelectModal.key)}
          onHide={() => setShowSelectModal(null)}
          onChange={(val) => {
            changeValue(data.find((d) => d.key === showSelectModal.key), val, "data");
          }}
        />
      )}
    </React.Fragment>
  );
}

function DragHandle(props) {
  return (
    <div className="dragHandle flexmiddle" {...props}>
      <i className="fas fa-arrows-alt"></i>
    </div>
  );
}

export default DraggableBoxes;
