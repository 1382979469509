import { Skeleton } from "@mui/material";
import React from "react";
import isMobile from "../../../../../services/isMobile";

const pageConfig = {
  services: "Select a Service",
  calendarSelection: !isMobile ? "Select a Date & Time" : "Select a Date",
  customerDetails: "Your Details",
};

function POHeader({
  currentPage,
  isLoading,
  customTitle,
  allowBack,
  onBack,
  practiceName,
}) {
  const pageTitle =
    customTitle || pageConfig[currentPage.name] || "Your Booking";

  return (
    <div style={{ marginBottom: "30px" }} className="POHeader">
      <img
        alt="logo"
        style={{ width: "150px" }}
        src="https://i.ibb.co/5WQL7qF/grafo-dark.png"
      />
      {!isLoading && (
        <React.Fragment>
          <h1
            onClick={() => {
              if (allowBack) {
                onBack();
              }
            }}
          >
            {allowBack && <i className="fa-solid fa-arrow-left-long back"></i>}
            {pageTitle}
          </h1>
          {isMobile && <div><i className="fa-solid fa-shield"></i> {practiceName}</div>}
        </React.Fragment>
      )}
      {isLoading && (
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          variant="rectangular"
          width={"20%"}
          height={38}
        />
      )}
    </div>
  );
}

export default POHeader;
