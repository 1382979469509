const assessmentConfig = {
    templates: [
        {
            title: "Front",
            key: "front",
            img: "https://i.ibb.co/p1FDsVp/Vector.png"
        },
        {
            title: "Back",
            key: "back",
            img: "https://i.ibb.co/hWL90n2/bodyback.png",

        },
        {
            title: "Left",
            key: "left",
            img: "https://i.ibb.co/H2vQpsX/bodyleft.png"
        },
        {
            title: "Right",
            key: "right",
            img: "https://i.ibb.co/xh8VVns/bodyright.png"
        }
    ],
    colors: [
        {
            color: "#EBAF3B", 
            name: "Pain",
            key: "pain",
            type: "pain"
        },
        {
            color: "#5AB2EC",
            name: "Spasm",
            key: "spasm"
        },
        {
            color: "#E14045",
            name: "Stiffness",
            key: "stiffness"
        },
        {
            color: "#57D242",
            name: "Pins/Needles",
            key:"pinsNeedles"
        },
        {
            color: "#9E2FF2",
            name: "Tingling",
            key:"tingling"
        },
        {
            color: "#FFF6A1",
            name: "Alt/Sensation",
            key:"sensation"
        }
    ],
};

export default assessmentConfig;