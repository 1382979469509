const SOAPFields = [
  "Subjective",
  "Objective",
  "Treatments (Rx)",
  "Assessment",
  "Plan",
  "Past Medical History",
];

const SOAPNotes =
  "<h2>Treatments (Soap Notes)</h2>" +
  SOAPFields.map((s) => "<p><b>" + s + ":</b></p><br /><br />").join("");

export default SOAPNotes;
