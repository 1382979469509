const currencies = [
    {
        "name": "Pounds",
        "key": "GBP",
        "symbol": "£"
    },
    {
        "name": "Dollars",
        "key": "USD",
        "symbol": "$"
    },
    {
        "name": "Euros",
        "key": "EUR",
        "symbol": "€"
    }
]

export default currencies;