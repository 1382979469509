import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import FormInput from "../../../../unauthenticated/templates/FormInput";
import { getPath, setPath } from "../../../_generic/utils/objectHelpers";

function GeneralForm({ patient, onChange }) {
  const [showMedicalInsurance, setShowMedicalInsurance] = useState(
    !!patient?.medicalInsurance?.provider
  );

  const getDOB = () => {
    const dob = getPath(patient, "dob", "");

    if (typeof dob === "string" && dob !== "") {
      return moment(dob).format("YYYY-MM-DD");
    }

    return dob;
  };

  const setValue = (key, value) => {
    const newValue = patient;
    setPath(newValue, key, value);
    onChange({ ...newValue });
  };

  return (
    <React.Fragment>
      <div className="formSection">
        <div className="flexeven">
          <FormInput
            label="First Name"
            validationKey="firstName"
            value={getPath(patient, "firstName", "")}
            onChange={(e) => setValue("firstName", e.target.value)}
          />
          <FormInput
            label="Last Name"
            validationKey="lastName"
            value={getPath(patient, "lastName", "")}
            onChange={(e) => setValue("lastName", e.target.value)}
          />
        </div>
        <div className="flexeven">
          <FormInput
            label="Email Address"
            validationKey="email"
            value={getPath(patient, "email", "")}
            onChange={(e) => setValue("email", e.target.value)}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={getPath(patient, "gender", "")}
              label="Gender"
              onChange={(e) => setValue("gender", e.target.value)}
            >
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
              <MenuItem value={"other"}>Undisclosed</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="flexeven">
          <FormInput
            label="Date of Birth"
            validationKey="dob"
            value={getDOB()}
            type="date"
            onChange={(e) => setValue("dob", e.target.value)}
          />
          <FormInput
            label="Phone"
            validationKey="phone"
            value={getPath(patient, "phone", "")}
            onChange={(e) => setValue("phone", e.target.value)}
          />
        </div>
        <div>
          <FormInput
            label="Address"
            validationKey="address"
            value={getPath(patient, "address", "")}
            onChange={(e) => setValue("address", e.target.value)}
          />
        </div>
        <div className="flexeven">
          <FormInput
            label="Occupation"
            validationKey="occupation"
            value={getPath(patient, "occupation", "")}
            onChange={(e) => setValue("occupation", e.target.value)}
          />
          <FormInput
            label="Emergency Contact"
            validationKey="emergency"
            value={getPath(patient, "emergency", "")}
            onChange={(e) => setValue("emergency", e.target.value)}
          />
        </div>
      </div>
      <div className="formSection dark">
        <div className="title">Medical Information</div>
        <FormInput
          label="Surgery Name (optional)"
          validationKey="address"
          value={getPath(patient, "medical.surgery", "")}
          onChange={(e) => setValue("medical.surgery", e.target.value)}
        />
        <FormInput
          label="Doctor's Name"
          validationKey="address"
          value={getPath(patient, "medical.doctor", "")}
          onChange={(e) => setValue("medical.doctor", e.target.value)}
        />
        <div className="flexeven">
          <FormInput
            label="Email Address"
            validationKey="address"
            value={getPath(patient, "medical.email", "")}
            onChange={(e) => setValue("medical.email", e.target.value)}
          />
          <FormInput
            label="Phone"
            validationKey="medical.address"
            value={getPath(patient, "medical.address", "")}
            onChange={(e) => setValue("medical.address", e.target.value)}
          />
        </div>
      </div>
      <div className="formSection">
        <div className="title" style={{ marginTop: "50px" }}>
          Medical Insurance{" "}
          <Checkbox
            checked={showMedicalInsurance}
            onChange={(e) => setShowMedicalInsurance(e.target.checked)}
          />
        </div>
        {showMedicalInsurance && (
          <React.Fragment>
            <FormInput
              label="Insurance Provider Name"
              validationKey="address"
              value={getPath(patient, "medicalInsurance.provider", "")}
              onChange={(e) =>
                setValue("medicalInsurance.provider", e.target.value)
              }
            />
            <div className="flexeven">
              <FormInput
                label="Membership Number"
                validationKey="address"
                value={getPath(
                  patient,
                  "medicalInsurance.membershipNumber",
                  ""
                )}
                onChange={(e) =>
                  setValue("medicalInsurance.membershipNumber", e.target.value)
                }
              />
              <FormInput
                label="Authorization Number"
                validationKey="address"
                value={getPath(
                  patient,
                  "medicalInsurance.authorizationNumber",
                  ""
                )}
                onChange={(e) =>
                  setValue(
                    "medicalInsurance.authorizationNumber",
                    e.target.value
                  )
                }
              />
            </div>
            <FormInput
              label="Details of Claim"
              validationKey="address"
              value={getPath(patient, "medicalInsurance.claimDetails", "")}
              onChange={(e) =>
                setValue("medicalInsurance.claimDetails", e.target.value)
              }
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

export default GeneralForm;
