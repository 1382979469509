import { Link, useLocation, useNavigate } from "@reach/router";
import React, { useState } from "react";
import { changePassword} from "../../../services/auth";
import { useUI } from "../../../_generic/UIContext";
import GrafoButton from "../../_generic/GrafoButton";
import FormInput from "../templates/FormInput";
import SignupFormTemplate from "../templates/SignupFormTemplate";

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const UI = useUI();
  const navigate = useNavigate();

  const urlParams = Object.fromEntries(
    new URLSearchParams(useLocation().search)
  );

  const email = urlParams.email;
  const codeGiven= urlParams.code;

  if(!email || !codeGiven){
      UI.showToast("Your link is not valid. If this continues to be a problem please contact us");
  }

  const submit = async () => {
    if (password.length < 7) {
      UI.showToast("Please enter a valid password that is 7 characters in length");
      return;
    }

    UI.showLoading();
    const { data, status } = await changePassword(email, codeGiven, password);
    UI.hideLoading();

    if (status !== 200) {
      UI.showToast(
        data.error ||
          data.message ||
          "There was an error in submitting your details, please try again later"
      );
      return;
    }

    setSuccessMessage(true);
  };

  return (
    <SignupFormTemplate>
      <div className="uForm">
        <h2>Change Password</h2>
        {!successMessage && (
          <React.Fragment>
            <div className="description" style={{ marginBottom: "35px" }}>
              Please enter your new password.
            </div>
            <FormInput
              value={password}
              label="New Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <GrafoButton onClick={submit} fullWidth={true}>
              Submit
            </GrafoButton>
            <div style={{marginTop:"20px"}} className="description"><Link to="/login">Click here to Login</Link></div>
          </React.Fragment>
        )}

        {successMessage && (
          <div>
            Your password has been reset! Click here to login.
            <GrafoButton onClick={() => navigate("/login")}>
              Login Now
            </GrafoButton>
          </div>
        )}
      </div>
    </SignupFormTemplate>
  );
}

export default ChangePassword;
