import { useState } from "react";
import { postFullAssessmentData } from "../../../../../services/patients";
import { useUI } from "../../../../../_generic/UIContext";
import { useData } from "../../../_generic/contexts/DataContext";
import AssessmentTemplate from "../assessments/AssessmentTemplate";
import FormManage from "../forms/FormManage";
import GeneralForm from "../general/GeneralForm";
import NotesMain from "../notes/NotesMain";
import SOAPNotes from "../notes/NotesDefaults/SOAPNotes";
import AssessmentSummary from "../summary/AssessmentSummary";
import CreateHeader from "./CreateHeader";
import CreateStepper from "./CreateStepper";
import MassageNotes from "../notes/NotesDefaults/MassageNotes";

function CreateMain({
  patientID,
  onHide,
  patient,
  selectedAssessment,
  onUpdateSilently,
}) {
  const data = useData();

  const tabLabels = [
    "Details",
    "Questions",
    "Body Chart",
    "Treatments",
    "Summary",
  ];

  const patientTypes = data?.user?.patientTypes || []
  const patientType = patientTypes.indexOf("massage") > -1 ? "massage" : "physio";


  const [generalData, setGeneralData] = useState(patient || {});
  const [assessmentData, setAssessmentData] = useState(
    selectedAssessment?.assessment || {
      title: "",
      painPoints: [],
      templateVersion: patientType
    }
  );

  const [consent, setConsent] = useState(!!patient?._id);

  const [formData, setFormData] = useState(selectedAssessment?.form || {});

  const [notesData, setNotesData] = useState(selectedAssessment?.notes || { content: patientType === "massage" ? MassageNotes : SOAPNotes });

  const [tabIndex, setTabIndex] = useState(0);

  const UI = useUI();

  const onSubmit = async () => {
    try {
      UI.showLoading();

      if (!consent) {
        throw new Error("You must gain consent at the bottom of this page to continue")
      }


      const patient = await data.updatePatient(generalData);

      let submissionData = {
        patient: patient._id,
        form: formData,
        assessment: assessmentData,
        notes: notesData,
      };

      const { data: submitData, status } = await postFullAssessmentData(
        submissionData
      );

      if (status !== 200) {
        throw new Error(submitData?.error || submitData?.message || submitData);
      }

      UI.hideLoading();

      UI.showSuccess();

      window.location.href = "?loadPatient=" + patient._id;
    } catch (err) {
      UI.showToast(
        err.message ||
        err.error ||
        "There was an error in submitting your request"
      );
      UI.hideLoading();
    }
  };

  return (
    <div className="createAssessment clientView flexcolumn">
      <CreateHeader
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        tabLabels={tabLabels}
        patient={generalData}
        onHide={onHide}
        onSubmit={onSubmit}
      />
      <div className="flexgrow viewPadding" style={{ overflowY: "auto" }}>
        <div
          style={{
            marginTop: "20px",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          {tabIndex === 0 && (
            <GeneralForm patient={generalData} onChange={setGeneralData} />
          )}

          {tabIndex === 1 && (
            <FormManage
              onChange={(value) => {
                setFormData(value);
              }}
              data={data}
              form={formData}
              patientID={patientID}
            />
          )}

          {tabIndex === 2 && (
            <AssessmentTemplate
              onChange={setAssessmentData}
              assessment={assessmentData}
            />
          )}

          {tabIndex === 3 && (
            <NotesMain notes={notesData} onChange={setNotesData} />
          )}

          {tabIndex === 4 && (
            <AssessmentSummary
              assessment={assessmentData}
              notes={notesData}
              form={formData}
              patient={generalData}
            />
          )}
        </div>
      </div>

      <CreateStepper
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        tabLabels={tabLabels}
        setConsent={setConsent}
        consent={consent}
      />
    </div>
  );
}

export default CreateMain;
