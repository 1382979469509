import { callAPI } from "./api"

function createCheckout(){
    return callAPI("/Payments", {
        method: "POST",
        body: {
            returnURL: window.location.href,
            cancelURL: window.location.href+"?checkoutCancelled=true"
        }
    });
}

function getPaymentPortal(){
    return callAPI("/Payments?returnURL="+window.location.href, {
        method: "GET",
    })
}

async function updateSubscription(planType){
    return callAPI("/Payments", {
        method: "PUT",
        body: {
            planType
        }
    })
}

export {createCheckout, getPaymentPortal, updateSubscription}