import { updateServices } from "../../../../../services/services";
import getServicesData from "./getServicesData";

async function updateServicesData(updates, services, setServices, UI){

    UI.showLoading();
    const {data, status} = await updateServices(updates);
    UI.hideLoading();

    if(status !== 200){
        throw new Error(data.error || data.message || "There was an error in updating your services, please try again");
    }
    
    const newServices = await getServicesData(services, setServices, UI, null, true);

    return newServices;

}

export default updateServicesData;