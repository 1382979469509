import React from "react";
import GoogleLogin from "react-google-login";

function GoogleAuth({ onChange, buttonText }) {
  return (
    <GoogleLogin
      className="googleAuth"
      clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENTID}
      buttonText={buttonText || "Login"}
      onSuccess={onChange}
      onFailure={onChange}
      cookiePolicy={"single_host_origin"}
    />
  );
}

export default GoogleAuth;
