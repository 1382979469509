import moment from "moment";

function transformStatus(data){
    return data.map(d => {
        return {...d, ...getTempStatus(d) }
    })
}

function getTempStatus(d){
    const creationDate = moment(d.creation);
    const expiryDate = moment(creationDate).add(10, "days");
    const today = moment();

    let status = "";
    let expiry = "";

    if(d.status){

        if(d.status === "trialing"){
            status = "Trialling - Card Provided";
        }
        status = capitalizeFirstLetter(d.status);

    } else if(today.isAfter(expiryDate)) {

        status = "Trial Expired"

    } else {

        status = "Trialling";
        expiry = Math.abs(today.diff(expiryDate, 'days'))+1;
        
    }


    return {status, expiry};
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

export {transformStatus, getTempStatus};