import { MenuItem, Select } from "@mui/material";
import React from "react";

function DBStandard({
	onClone,
	setShowRemoveConfirm,
	item,
	changeValue,
	setShowSelectModal,
}) {
	return (
		<>
			<div className="flexgrow">
				<div>
					<input
						className="noStyle"
						value={item.label}
						onChange={(e) => changeValue(item, e.target.value, "label")}
					/>
				</div>
				<div className="flexcenter">
					<div>
						<Select
							className="select"
							value={item.type}
							onChange={(e) => changeValue(item, e.target.value, "type")}>
							<MenuItem value={"string"}>Text</MenuItem>
							<MenuItem value={"date"}>Date</MenuItem>
							<MenuItem value="selection">Single select</MenuItem>
							<MenuItem value="multiselection">Multi select</MenuItem>
							<MenuItem value="section">Section Title</MenuItem>
						</Select>
					</div>
					{item.type.indexOf("select") > -1 && (
						<React.Fragment>
							<div
								style={{
									border: "1px solid #dedede",
									marginLeft: "20px",
									padding: "6px",
									backgroundColor: "#f5f5f5",
									marginTop: "5px",
									borderRadius: "5px",
									cursor: "pointer",
									color: "var(--blue)",
								}}
								onClick={() => setShowSelectModal(item)}>
								<i className="fas fa-pencil"></i> Edit Choices
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		</>
	);
}

export default DBStandard;
