import React, { useState } from "react";
import { updateSubscription } from "../../../../services/payments";
import { useUI } from "../../../../_generic/UIContext";
import { useData } from "../../_generic/contexts/DataContext";
import SettingSection from "../_generic/SettingsSection";
import ConfirmChangePlan from "./ConfirmChangePlan";
import PaymentPlans from "./PaymentPlans";
import paymentPlanTemplate from "./paymentPlanTemplate";

function ChangePlan({ customDescription }) {
  const { user } = useData();
  const UI = useUI();

  const [selectedNewPlanKey, setSelectedNewPlanKey] = useState();

  const onSubscriptionChange = async () => {

    const planKey = selectedNewPlanKey;

    setSelectedNewPlanKey(null);

    UI.showLoading();
    const { data, status } = await updateSubscription(planKey);

    if (status !== 200) {
        UI.hideLoading();
      UI.showToast(
        data?.error ||
          data?.message ||
          "There was an error with changing your subscription, please contact support"
      );
      return;
    }

    window.setTimeout(() => {
        window.location.href = window.location.href;
    }, 4000);

    
  };

  const onRequestedChange = async (planKey) => {
    setSelectedNewPlanKey(planKey);
  };

  return (
    <React.Fragment>
      {selectedNewPlanKey && (
        <ConfirmChangePlan
          onConfirm={onSubscriptionChange}
          onCancel={() => setSelectedNewPlanKey(null)}
          planType={selectedNewPlanKey}
        />
      )}
      <SettingSection title="Change Your Plan">
        {customDescription ? (
          customDescription
        ) : (
          <React.Fragment>
            You are currently on the {user.isCalendarEnabled ? "Pro" : "Basic"}{" "}
            plan.
          </React.Fragment>
        )}
        <PaymentPlans
          onChange={onRequestedChange}
          templates={paymentPlanTemplate}
        />
      </SettingSection>
    </React.Fragment>
  );
}

export default ChangePlan;
