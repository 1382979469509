import { Skeleton } from "@mui/material";
import moment from "moment";
import { numberAsCurrency } from "../../../../_generic/numberAsCurrency";
import getDuration from "../logic/getDuration";

function POCheckoutBox({ selectedService, physio, isLoading, selectedDate, pageIndex }) {
  if (isLoading) {
    return <div>
         <Skeleton style={{marginTop:"20px"}} variant="rectangular" width={"100%"} height={38} />
         <Skeleton style={{marginTop:"20px"}} variant="rectangular" width={"70%"} height={38} />
         <Skeleton style={{marginTop:"20px"}} variant="rectangular" width={"50%"} height={38} />
         <Skeleton style={{marginTop:"20px"}} variant="rectangular" width={"70%"} height={38} />
    </div>;
  }

  const dateAsString = selectedDate && pageIndex > 1 ? (moment.utc(selectedDate).format("Do MMM [at] hh:mma") + " | ") : null;

  return (
    <div>
      <h2><i className="fa-solid fa-shield"></i> {physio.practiceName}</h2>
      <div >
        <div className="flex">
          <b>{selectedService.title}</b> 
          <span className="price">({numberAsCurrency(selectedService.price, physio.currency || "GBP") })</span>
          
        </div>
        <div className="mins">{dateAsString} {getDuration(selectedService.durationInMins)}</div>
        <div className="info">
        <i className="fa-solid fa-circle-info"></i> For help with bookings, please contact your clinician.
        </div>
      </div>
    </div>
  );
}

export default POCheckoutBox;
