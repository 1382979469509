import React from "react";

import physioMapConfig from "./config/physioMapConfig";

import KeyBox from "./components/KeyBox";
import MainStage from "./components/MainStage";
import massageMapConfig from "./config/massageMapConfig";
import GrafoButton from "../../../../_generic/GrafoButton";

const shapeSize = 23;

let timeout;

const NewAssessments = ({ patientTypes, onChange, assessment: data }) => {
	const dragUrl = React.useRef();
	const stageRef = React.useRef();
	const screenshotRef = React.useRef();

	const [patientType, setPatientType] = React.useState(
		patientTypes[0] || "physio"
	);
	const [penClicked, setPenClicked] = React.useState(false);

	const configOptions = {
		massage: massageMapConfig,
		physio: physioMapConfig,
	};

	const currConfig = configOptions[patientType];

	const NotesComponent = currConfig.notesComponent;

	const existingPainPoints =
		data?.painPoints && data?.painPoints.length > 0
			? data?.painPoints
			: currConfig.existingImages;

	return (
		<div
			className="flex"
			ref={screenshotRef}>
			<div style={{ alignSelf: "top" }}>
				<KeyBox
					shapeSize={shapeSize}
					dragUrl={dragUrl}
					config={currConfig}
					onPenClick={() => setPenClicked(!penClicked)}
				/>
				<GrafoButton
					onClick={() => {
						let newPainPoints = existingPainPoints;
						newPainPoints.pop();
						onChange("painPoints", newPainPoints);
						// setData({ ...data, painPoints: newPainPoints });
					}}
					style={{ width: "90%", marginTop: 10, borderRadius: 20 }}>
					<i
						className="fas fa-refresh"
						style={{ marginRight: 10 }}
					/>
					Undo
				</GrafoButton>
			</div>

			<div
				style={{
					alignSelf: "center",
					cursor: penClicked ? "crosshair" : "auto",
				}}>
				<MainStage
					penClicked={penClicked}
					config={currConfig}
					stageRef={stageRef}
					painPoints={existingPainPoints}
					setPainPoints={(val) => {
						onChange("painPoints", val);
						// setData({ ...data, painPoints: val });
					}}
					setPenClicked={setPenClicked}
					shapeSize={shapeSize}
					dragUrl={dragUrl}
				/>
			</div>

			<div
				className="flexgrow"
				style={{ alignSelf: "top" }}>
				<NotesComponent
					data={data}
					onChange={(dt) => {
						for (var key in dt) {
							onChange(key, dt[key]);
						}

						// setData({ ...data, ...dt });
					}}
				/>
			</div>
		</div>
	);
};

export default NewAssessments;
