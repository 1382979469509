import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import isMobile from "../../../../../services/isMobile";

function FormEditor({ template, onChange, answers }) {

  const onEdit = (index, value) => {
    let editedQuestions = template.questions;
    editedQuestions[index].answer = value;

    editedQuestions = editedQuestions.map(e => {
      if (e.type.indexOf("selection") === -1 || e.answer) {
        return e;
      }

      return { ...e, answer: e.data ? e.data.filter(ed => ed.default) : "" }
    })

    onChange([...editedQuestions]);
  };
  return (
    template.questions?.map((q, i) => {
      const answer = answers?.find((a) => q.key === a.key);

      return (
        <FormQuestion
          key={i}
          question={q}
          answer={answer?.answer || ""}
          onChange={(value) => onEdit(i, value)}
        />
      );
    }) || <div>No questions to show, please edit your form and try again</div>
  );
}

function FormQuestion({ question, onChange, answer }) {

  const notesID = question.key + "_notes"


  const answerExists = answer !== "" && (!Array.isArray(answer) || answer.filter(a => a.key !== notesID)?.length > 0);


  return (
    <div className={"formEditorQuestion " + question?.type}>
      <div className="label">{question.label}</div>
      <div>
        {["text", "string", "date"].indexOf(question.type) > -1 && (
          <TextField
            value={answerExists ? answer : ""}
            onChange={(e) => onChange(e.target.value)}
            type={question.type === "date" ? "date" : "text"}
            fullWidth
            placeholder={
              question.type === "date" ? "Select" : "Enter something here"
            }
          />
        )}

        {question.type.indexOf("select") > -1 && (
          <>
            <div>
              {question.data && question.data.map((qd) => {

                let isChecked = answerExists && !!answer?.find((a => a.key === qd.key));

                if (!isChecked && !answerExists) {
                  isChecked = qd.default;
                }

                return (
                  <div key={qd.key}
                    className="formSelections" style={{ verticalAlign: 'middle' }}>
                    <FormGroup

                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={(event) => {
                              const checked = event.target.checked;

                              let newAnswer = answer === "" ? [] : answer;

                              if (!checked) {
                                newAnswer = newAnswer.filter(na => na.key !== qd.key);
                              } else {

                                if (question.type === "selection") {
                                  newAnswer = newAnswer.filter(na => na.key === notesID);
                                }

                                newAnswer.push(qd);
                              }

                              onChange(newAnswer);
                            }}
                          />
                        }
                        label={qd.value}
                      />
                    </FormGroup>
                  </div>
                );
              })}

              {question?.data?.length < 3 && <div className="formSelections" style={{ width: isMobile ? "100%" : "32%" }}>
                <TextField inputProps={{
                  style: {
                    padding: 10
                  }

                }}
                  value={answer ? answer?.find(a => a.id === notesID)?.value : ""}
                  onChange={(e) => {

                    const value = e.target.value;

                    let newAnswers = answerExists ? answer : [];

                    let foundIndex = newAnswers.findIndex(na => na.key === notesID);

                    if (foundIndex === -1) {
                      foundIndex = newAnswers.length;
                    }

                    newAnswers[foundIndex] = { key: notesID, value };

                    onChange(newAnswers)

                  }}
                  style={{ verticalAlign: 'center', width: "100%" }}
                  placeholder="Actions taken / details" />
              </div>}

            </div>

          </>
        )}
      </div>
    </div>
  );
}

export default FormEditor;
