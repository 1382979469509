import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { consentWording } from "./consentConfig";

function SignatureConsent({ setConsent, sigRef }) {
    const [showSignMessage, setShowSignMessage] = useState(true);

  return (
    <React.Fragment>
      {consentWording}{" "}
      <b>Please sign in the box below using your finger or mouse.</b>
      <br /> <br />
      <div>
        {showSignMessage && <div
          style={{
            position: "absolute",
            top: "40%",
            fontSize: "30px",
            color: "#c9c9c9",
            fontWeight: 600,
            textAlign: "center",
            width: "100%",
            pointerEvents: "none"
          }}
        >
          SIGN HERE
        </div>}
        <SignatureCanvas
          onEnd={() => {
            setConsent(true);
          }}
          ref={sigRef}
          onBegin={() => {
              setShowSignMessage(false);
          }}
          penColor="black"
          canvasProps={{
            width: window.innerWidth - 40,
            height: 200,
            className: "sigCanvas",
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default SignatureConsent;
