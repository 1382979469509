import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useUI } from "../../../../../_generic/UIContext";
import ImageView from "./ImageView";
import UploadWidget from "./UploadWidget";

function NotesMain({ notes, onChange }) {
  const [showSrc, setShowSrc] = useState(null);
  const UI = useUI();

  const changeValue = (key, value) => {
    let newValue = notes || {};
    newValue[key] = value;
    onChange({ ...newValue });
  };

  return (
    <React.Fragment>
      {showSrc && <ImageView src={showSrc} onHide={() => setShowSrc(null)} />}
      <ReactQuill
        theme="snow"

        value={notes.content}
        preserveWhitespace={true}
        onChange={(value) => {
          changeValue("content", value);
        }}
      />
      <div style={{ marginTop: "15px" }}>
        <div className="imageList">
          <div
            className="flexmiddle"
            style={{
              border: "2px solid var(--blue)",
              color: "var(--blue)",
              borderRadius: "6px",
            }}
          >
            <i
              className="mia fas fa-camera-alt"
              style={{ fontSize: "var(--large)" }}
            />
            <UploadWidget
              onSuccess={(url) => {
                let images = notes?.attachments || [];
                changeValue("attachments", images.concat(url));
              }}
              onFailure={() => {
                UI.showToast("There was an error in uploading your image");
              }}
            />
          </div>
          {notes?.attachments?.map((i) => (
            <div
              key={i}
              className="coverimage"
              style={{ backgroundImage: "url(" + i + ")" }}
              onClick={() => setShowSrc(i)}
            >
              <i
                onClick={(e) => {
                  let images = notes.attachments || [];
                  changeValue(
                    "attachments",
                    images.filter((img) => img !== i)
                  );
                  e.stopPropagation();
                }}
                className="fas fa-trash-alt"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "10px",
                  right: "5px",
                  fontSize: "var(--small)",
                }}
              ></i>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default NotesMain;
