import React, { useEffect, useState, lazy } from "react";
import { getAllUsers } from "../../../services/user";
import { useUI } from "../../../_generic/UIContext";
import { useData } from "../_generic/contexts/DataContext";


import EmailCellRenderer from "../_generic/components/cellRenderers/EmailCellRenderer";
import StatusCellRenderer from "../_generic/components/cellRenderers/StatusCellRenderer";
import ExpiryCellRenderer from "../_generic/components/cellRenderers/ExpiryCellRenderer";
import DateCellRenderer from "../_generic/components/cellRenderers/DateCellRenderer";
import { Suspense } from "react";

const DataGrid = lazy(
  () => import('@mui/x-data-grid').then(module => ({ default: module.DataGrid }))
);

const GridToolbar = lazy(
  () => import('@mui/x-data-grid').then(module => ({ default: module.GridToolbar }))
);

function AdminView() {
  const [userData, setUserData] = useState([]);
  const [counts, setCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const data = useData();
  const UI = useUI();

  const user = data.user;
  const isAdmin = user.admin;

  useEffect(() => {
    const initialize = async () => {
      if (!isAdmin) {
        return false;
      }

      UI.showLoading();
      const { data, status } = await getAllUsers();
      UI.hideLoading();

      if (status !== 200) {
        setIsLoading(false);
        UI.showToast(data?.error || data?.message || data);
        return;
      }

      // setUserData(transformStatus(data.data));
      setUserData(data.data);
   
      setCounts(data.counts);
      setIsLoading(false);
    };

    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isAdmin) {
    return <div>Uh-oh, you can't access this page!</div>;
  }

  if (isLoading) {
    return <div>loading</div>;
  }


  const columns = [
    { field: "firstName", headerName: "First", width: 120 },
    { field: "lastName", headerName: "Last", width: 120 },
    { field: "email", headerName: "Email", width: 120, renderCell: EmailCellRenderer},
    { field: "phoneNumber", headerName: "Phone No.", width:120},
    { field: "referredBy", headerName: "Referrer", width: 110 },
    { field: "associatedBy", headerName: "Associated By", width: 110},
    { field: "creation", headerName: "Joined", width: 110, renderCell: DateCellRenderer },
    { field: "status", headerName: "Status", width: 110, renderCell: StatusCellRenderer },
    { field: "expiry", headerName: "Expiry", width: 120, renderCell: ExpiryCellRenderer },
  ];

  return (
    <React.Fragment>
      <div className="flex" style={{ marginBottom: "25px" }}>
        {Object.keys(counts).map((k, i) => {
          return (
            <div key={i} style={{ padding:"10px", backgroundColor:"white", textAlign:"center", minWidth:"160px", border: "1px solid grey", marginRight: "15px", borderRadius:"10px" }}>
              <h2>{counts[k]}</h2>
              <div style={{fontSize:"0.8em"}}>{k}</div>
            </div>
          );
        })}
      </div>
      <div style={{ height: "65vh" }}>
        <Suspense fallback={<React.Fragment>Loading</React.Fragment>}>
        <DataGrid
          style={{ backgroundColor: "white" }}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row._id}
          rows={userData}
          columns={columns}
        />
        </Suspense>
      </div>
    </React.Fragment>
  );
}

export default AdminView;
