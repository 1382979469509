import React from "react";
import GrafoTable from "../../_generic/components/GrafoTable";

function NoData() {
  const data = [
    {
      fullName: "Jack Smith",
      phone: "+447533029230",
      email: "jack@smith.com",
    },
  ];

  return (
    <React.Fragment>
      <GrafoTable data={data} />
      <div className="recommendation">To get started, create you first Client!</div>
    </React.Fragment>
  );
}

export default NoData;
