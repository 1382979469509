import React, { useEffect, useState } from "react";
import searchForValue from "../../../_generic/searchForValue";
import GrafoButton from "../../_generic/GrafoButton";
import GrafoSearchBar from "../_generic/components/GrafoSearchBar";
import GrafoTable from "../_generic/components/GrafoTable";
import { useData } from "../_generic/contexts/DataContext";
import StatsBar from "../_generic/layout/StatsBar";

import NoData from "./components/NoData";
import generateClientStats from "./logic/generateClientStats";
import transformClientData from "./logic/transformData";
import "../../../styles/ClientView.css";
import { useLocation } from "@reach/router";
import { useUI } from "../../../_generic/UIContext";
import ClientViewMain from "../ClientView/ClientViewMain";
import isMobile from "../../../services/isMobile";

function ClientsMain({ navigate }) {
  const data = useData();

  const UI = useUI();
  const params = Object.fromEntries(new URLSearchParams(useLocation().search));
  const patientData = transformClientData(data.user.patients || []);
  const [searchData, setSearchData] = useState(patientData);
  const [searchField, setSearchField] = useState("");

  const [clientView, setClientView] = useState(null);

  const searchValue = (value) => {
    setSearchField(value);
    setSearchData(searchForValue(value, patientData, ["fullName", "email"]));
  };

  

  useEffect(() => {
    checkForParamaters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkForParamaters = () => {
    if (params.noPatientFound) {
      UI.showToast("We couldn't find your patient! Try searching again.");
    }

    if(params.loadPatient){
      const foundPatient = patientData.find(pd => pd._id === params.loadPatient);

      if(!foundPatient){
        UI.showToast("We couldn't find your patient! Contact us if it continues to be an issue");
        return;
      }

      setClientView(foundPatient);
    }

    if(params.addNew){
      setClientView({_id: "new"})
    }
  };

  return (
    <React.Fragment>
      <div className="clientsContainer">
        <StatsBar stats={generateClientStats(patientData)} />
        <div className="flex searchBarButtons sticky">
          <GrafoSearchBar
            placeholder="Search Clients"
            value={searchField}
            onChange={(value) => searchValue(value)}
          />
          <div className="flex buttonGroup" style={{ marginLeft: "10px" }}>
            <GrafoButton onClick={() => setClientView({ _id: "new" })}>
              <i className="fas fa-user-plus" /> New {!isMobile ? "Client":""}
            </GrafoButton>
          </div>
        </div>
        <div className="flexgrow">
          {patientData.length > 0 && (
            <GrafoTable
              fields={!isMobile ?  ["fullName", "email", "phone"] : ["fullName", "email"]}
              data={searchData}
              onClick={(patient) => setClientView(patient)}
            />
          )}
          {!patientData.length && <NoData />}
        </div>
      </div>
      {clientView && (
        <ClientViewMain onHide={() => setClientView(null)} patientID={clientView._id} patient={clientView} />
      )}
    </React.Fragment>
  );
}

export default ClientsMain;
