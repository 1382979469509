import { Link } from "@reach/router";
import React, { useEffect, useState } from "react";
import { createUser } from "../../../services/auth";
import { useUI } from "../../../_generic/UIContext";
import GrafoButton from "../../_generic/GrafoButton";
import GoogleAuth from "../components/GoogleAuth";
import FormInput from "../templates/FormInput";
import formValidation from "../templates/FormValidation";
import { navigate } from "@reach/router";
import mixpanel from "../../../services/_mixpanel";
import SignupFormTemplate from "../templates/SignupFormTemplate";
import getParams from "../../../services/params";

function Signup() {
  const UI = useUI();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [discountCode, setDiscountCode] = useState(localStorage.getItem("refer"));

  const storeReferralInLocalStorage = () => {
    const { refer } = getParams();

    if (refer) {
      UI.showToast(
        "Congrats! You'll get £5 off once your 10 day free trial is finished."
      );
      localStorage.setItem("refer", refer);
      setDiscountCode(refer);
    }
  };

  const storeAssociationInLocalStorage = () => {
    const { association } = getParams();

    if (association) {
      UI.showToast(
        "Congrats! You'll get 50% off for 6 months once your 10 day free trial is finished."
      );
      localStorage.setItem("association", association);
    }
  };

  useEffect(() => {
    storeReferralInLocalStorage();
    storeAssociationInLocalStorage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const googleSubmit = (response) => {
    const profile = response?.profileObj;
    const error = response?.error;

    if (error || !profile) {
      UI.showToast(
        "We couldn't retrieve your personal details from your Google account, try signing up manually."
      );
      return;
    }

    const accountObject = {
      name: profile.name,
      email: profile.email,
      type: "google",
      token: response.accessToken,
      tokenId: response.tokenId,
    };

    createAccount(accountObject);
  };

  const formSubmit = () => {
    if (
      !isValid("name", name) ||
      !isValid("email", email) ||
      !isValid("password", password) ||
      !isValid("phoneNumber", phoneNumber)
    ) {
      UI.showToast("Please enter your details correctly");
      return;
    }

    const accountObject = {
      name,
      email,
      password,
      phoneNumber,
      type: "form",
    };

    createAccount(accountObject);
  };



  const createAccount = async (user) => {
    UI.showLoading();
    const associatedBy = localStorage.getItem("association");
    const isMassage = associatedBy === "LSM";

    const { data, status } = await createUser({
      ...user,
      referredBy: localStorage.getItem("refer"),
      associatedBy,
      patientTypes: [isMassage ? "massage" : "physio"]
    });
    UI.hideLoading();

    if (status !== 200) {
      let error = data.error;

      if (error.indexOf("duplicate") > -1) {
        error = "Your email already exists, please login";
        mixpanel.identify(user.email);
        mixpanel.track("Account Error", { errorType: "accountAlreadyExists" });
      }

      UI.showToast(error);
      return;
    }

    mixpanel.identify(user.email);
    mixpanel.track("Lead");

    navigate("/login", { state: { autoLogin: true, user } });
  };

  const isValid = (key, value) => {
    return formValidation[key].validation(value);
  };

  return (
    <SignupFormTemplate>
      <div className="uForm">
        <h2>Create an Account</h2>
        <div className="description" style={{ marginBottom: "35px" }}>
          Start your 10 day free trial.
        </div>
        {discountCode && (
          <div
            style={{
              fontSize: "12px",
              backgroundColor: "#ececee",
              padding: "5px",
              borderRadius: "10px",
              display: "inline-block",
              marginBottom: "10px",
            }}
          >
            Discount Code: <b>{discountCode}</b>
          </div>
        )}

        <FormInput
          value={name}
          label="Full Name"
          type="text"
          onChange={(e) => setName(e.target.value)}
          validationKey="name"
        />
        <FormInput
          value={email}
          label="Email address"
          type="email"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          validationKey="email"
        />
        <FormInput
          value={phoneNumber}
          label="Phone Number"
          type="tel"
          onChange={(e) => setPhoneNumber(e.target.value)}
          validationKey="phoneNumber"
        />
        <FormInput
          value={password}
          label="Password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          validationKey="password"
        />
        <GrafoButton fullWidth={true} onClick={formSubmit}>
          Get Started
        </GrafoButton>
        <div
          className="description"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          OR
        </div>
        <GoogleAuth onChange={googleSubmit} buttonText={"Sign Up"} />
        <div className="description" style={{ marginTop: "25px" }}>
          Already have an account? <Link to="/login">Login</Link>
        </div>
      </div>
    </SignupFormTemplate>
  );
}

export default Signup;
