import { Checkbox, FormControlLabel } from "@mui/material";
import { consentWording } from "./consentConfig";

function CheckboxConsent({consent, setConsent}){
    return (
        <FormControlLabel
          control={
            <Checkbox
              checked={consent}
              onChange={(e) => setConsent(e.target.checked)}
            />
          }
          label={consentWording}
        />);
}

export default CheckboxConsent;