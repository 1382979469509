import { callAPI } from "./api";

async function addOrUpdatePatient(patient) {
  return callAPI("/patients", { method: "PUT", body: { patient } });
}

async function addOrUpdateAssessment(assessment) {
  return callAPI("/patientAssessments", {
    method: "PUT",
    body: { assessment },
  });
}

async function getAssessments(patient){
    return callAPI("/patientAssessments?patient="+patient, {
        method: "GET",
      });
}

async function getAllFormTemplates(){
  return callAPI("/patientFormTemplates", {
      method: "GET",
    });
}

async function addStandardFormTemplatesToUser(){
  return callAPI("/patientFormTemplates", {
    method: "POST",
    body: {
      templates: true
    }
  })
}

async function addOrUpdateFormTemplates(form){
  return callAPI("/patientFormTemplates", {
      method: "PUT",
      body: {
        form
      }
    });
}

async function getFormsForPatient(patient){
  return callAPI("/patientForms?patient="+patient, {method: "GET"});
}

async function addOrUpdateFormForPatient(form){
  return callAPI("/patientForms", {
    method: "PUT",
    body: {
      form: form
    }
  });
}

async function postFullAssessmentData(data){
  return callAPI("/patients", {
    method: "POST",
    body: data
  })
}

async function addPatientNote(data){
  return callAPI("/patientNotes", {
    method: "PUT",
    body: data
  })
}

async function getFullPatient(patient){
  return callAPI("/patients?patient="+patient, {method: "GET"});
}

async function doesPatientExistUnAuth(email, physio){
  return callAPI("/auth?eventType=checkPatientByEmail&email="+email+"&physioID="+physio, {method: "GET"})
}
export {doesPatientExistUnAuth, addStandardFormTemplatesToUser, addPatientNote, getFullPatient, postFullAssessmentData, addOrUpdateFormForPatient, getFormsForPatient, addOrUpdateFormTemplates, addOrUpdatePatient, addOrUpdateAssessment, getAssessments, getAllFormTemplates};
