import AdminView from "../../Admin/AdminView";
import AssociationsMain from "../../Associations/AssociationsMain";
import CalendarMain from "../../Calendar/CalendarMain";
import ClientsMain from "../../ClientSearch/ClientsMain";
import FormsMain from "../../Forms/FormsMain";
import FormViewMain from "../../Forms/FormView.js/FormViewMain";
import NewAuthenticatedError from "../../NewMain/NewAuthenticatedError";
import SettingsMain from "../../Settings/SettingsMain";

const routes = [
    {
        path: "/",
        name: "Clients",
        icon: "users",
        component: ClientsMain,
        show: true,
        default: true
    },
    {
        path: "/forms/",
        name: "Forms",
        icon: "clipboard-list",
        component: FormsMain,
        show: true
    },
    {
        path: "/forms/:id",
        name: "Forms",
        icon: "clipboard-list",
        component: FormViewMain,
        show: false
    },
    {
        path: "/calendar",
        name: "Calendar",
        icon: "calendar-days",
        component: CalendarMain,
        show: true
    },
    {
        path: "/settings",
        name: "Settings",
        icon: "cog",
        component: SettingsMain,
        show: true
    },
    {
        path: "/new",
        name: "New",
        icon: "cog",
        component: NewAuthenticatedError,
        show: false
    },
    {
        path: "/bookings",
        name: "Bookings",
        icon: "cog",
        component: NewAuthenticatedError,
        show: false
    },
    {
        path: "/admin",
        name: "Admin",
        icon: "cog",
        component: AdminView,
        show: false
    },
    {
        path: "/association",
        name: "Associations",
        icon: "cog",
        component: AssociationsMain,
        show: false
    },
    
];

export default routes;