import { CircularProgress, TextareaAutosize } from "@mui/material";
import { useLocation } from "@reach/router";
import { useEffect, useRef, useState } from "react";
import {
  getPhysioDetails,
  submitPhysioDetails,
} from "../../../../services/physios";
import { useUI } from "../../../../_generic/UIContext";
import FormManage from "../../../authenticated/ClientView/pages/forms/FormManage";

import GeneralForm from "../../../authenticated/ClientView/pages/general/GeneralForm";

import GrafoButton from "../../../_generic/GrafoButton";
import CheckboxConsent from "./consent/CheckboxConsent";
import SignatureConsent from "./consent/SignatureConsent";

function PatientOnboarding() {
  const [patient, setPatient] = useState({});
  const [notes, setNotes] = useState("");
  const [form, setForm] = useState({});
  const [showError, setShowError] = useState(false);
  const [formTemplate, setFormTemplate] = useState();
  const [user, setUser] = useState({ name: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [consent, setConsent] = useState(false);
  const location = useLocation();
  const signatureRef = useRef(null);

  const urlParams = Object.fromEntries(new URLSearchParams(location.search));

  const askForSignature = urlParams.signatureConsent;
  const physioID = urlParams.physio;
  const formID = urlParams.form;

  const UI = useUI();

  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initialize = async () => {
    if (!physioID) {
      setShowError(true);
      return;
    }

    const { data, status } = await getPhysioDetails(physioID, formID);
    setIsLoading(false);

    if (status !== 200) {
      setShowError(true);
      return;
    }

    setFormTemplate(data.form);
    setUser(data.physio);
  };

  const submit = async () => {
    if (!patient.firstName || !patient.lastName || !patient.email) {
      UI.showToast(
        "Please enter your first name, last name and email before proceeding"
      );
      return;
    }

    if (!consent) {
      UI.showToast("Please provide your consent at the bottom of the page");
      return;
    }

    let submission = { patient, physio: physioID };

    if (form && formTemplate && Object.keys(form).length > 0) {
      submission.form = {
        ...form,
        form: formTemplate._id,
        title: formTemplate.title,
      };
    }

    if (notes !== "") {
      submission.notes = { content: notes };
    }

    const attachment = !askForSignature ? null : signatureRef.current.toDataURL('base64string');

    if(attachment){
      submission.notes = submission.notes || {content: "Signature provided for consent, see attachments."};
      submission.notes.attachments = attachment;
    }

    UI.showLoading();
    const { data, status } = await submitPhysioDetails(submission);
    UI.hideLoading();

    if (status === 200) {
      setSuccessMessage(true);
    } else {
      UI.showToast(
        data.error ||
          data.message ||
          "There was an error in submitting your details"
      );
    }
  };

  if (successMessage) {
    return (
      <div style={{ width: "100vw", height: "100vh" }}>
        <div
          className="mia"
          style={{
            backgroundColor: "white",
            padding: "25px",
            width: window.innerWidth > 800 ? "auto" : "90%",
          }}
        >
          <h2>Thanks for Submitting!</h2>
          <div>Your details have been submitted.</div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", paddingTop: "50px" }}>
        <CircularProgress />
      </div>
    );
  }

  if (showError) {
    return (
      <div style={{ width: "100vw", height: "100vh" }}>
        <div
          className="mia"
          style={{
            backgroundColor: "white",
            padding: "25px",
            width: window.innerWidth > 800 ? "auto" : "90%",
          }}
        >
          Hey there 👋 <br />
          <br />
          We're really sorry but we couldn't find the physiotherapist associated
          with this link.
          <br />
          <br />
          Please let your physiotherapist know. If you are the physiotherapist,
          please check your link hasn't changed or contact support.
        </div>
      </div>
    );
  }

  return (
    <div className="userOnboarding">
      <div style={{ marginBottom: "30px" }}>
        <img
          alt="logo"
          style={{ width: "150px" }}
          src="https://i.ibb.co/5WQL7qF/grafo-dark.png"
        />
        <h1>Assessment with {user.name}</h1>
        Please fill in as much information as possible to help speed up your
        assessment.
      </div>

      <div>
        <h2>1. General Information</h2>
        <GeneralForm patient={patient} onChange={setPatient} />
      </div>
      {formTemplate && (
        <div>
          <h2>2. Assessment Questionnaire</h2>
          <FormManage template={formTemplate} form={form} onChange={setForm} />
        </div>
      )}
      <div>
        <h2>3. Other Information That Would Be Useful To Know</h2>
        <TextareaAutosize
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          style={{
            width: "100%",
            border: "1px solid grey",
            borderRadius: "5px",
            fontFamily: "inherit",
            minHeight: "200px",
          }}
        />
      </div>
      <div style={{ marginBottom: "50px" }}>
        <h2>4. Consent</h2>
        {!askForSignature ? (
          <CheckboxConsent consent={consent} setConsent={setConsent} />
        ) : (
          <SignatureConsent sigRef={signatureRef} consent={consent} setConsent={setConsent} />
        )}
      </div>
      <div style={{ marginBottom: "150px" }}></div>
      <div style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 50 }}>
        <GrafoButton className="submitNew grafoButton" onClick={() => submit()}>
          Submit Details
        </GrafoButton>
      </div>
    </div>
  );
}

export default PatientOnboarding;
