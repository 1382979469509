import { callAPI } from "./api";

async function createCalendarEntry(appointment) {
  return callAPI("/CalendarWithAuth", { method: "POST", body: { appointment } });
}

async function createCalendarEntryUnAuth(appointment, physio) {
  return callAPI("/CalendarWithPatient", { method: "POST", body: { appointment, physio: physio } });
}


async function getCalendarDataUnAuth(physioID){
  return callAPI("/CalendarWithPatient?physio="+physioID, { method: "GET" });
}

async function getCalendarDataAuth(){
  return callAPI("/CalendarWithAuth", { method: "GET" });
}

async function cancelCalendarEntryWithAuth(calendarID){
  return callAPI("/CalendarWithAuth", {method: "DELETE", body: {
    _id: calendarID
  }})
}

async function cancelCalendarEntryUnAuth(calendarID, physio){
  return callAPI("/CalendarWithPatient", {method: "DELETE", body: {
    _id: calendarID,
    physio
  }})
}

export {
    cancelCalendarEntryUnAuth,
    createCalendarEntry,
    getCalendarDataUnAuth,
    getCalendarDataAuth,
    cancelCalendarEntryWithAuth,
    createCalendarEntryUnAuth
}