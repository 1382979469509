import PhysioNotes from "../components/notes/PhysioNotes/PhysioNotes"

const physioMapConfig = {
    notesComponent: PhysioNotes,
    backgroundImage: "https://i.ibb.co/pvsBh1J/smallerfullbody.png",
    draggableImages: [
        {
            id: "pain",
            label: "Pain",
            img: "https://i.ibb.co/gPzz2WJ/bgremoved1.png"
        },
        {
            id: "spasm",
            label: "Spasm",
            img: "https://i.ibb.co/NWkDHx4/blue.png"
        },
        {
            id: "stiffness",
            label: "Stiffness",
            img: "https://i.ibb.co/3SCbDs7/green.png"
        },
        {
            id: "pinsneedles",
            label: "Pins/Needles",
            img: "https://i.ibb.co/mBqP6YS/purple.png"
        },
        {
            id: "tingling",
            label: "Tingling",
            img: "https://i.ibb.co/L88DvPf/red.png"
        },
        {
            id: "altsensation",
            label: "Alt/Sensation",
            img: "https://i.ibb.co/rHtv4H9/yellow.png"
        },
    ]
}

export default physioMapConfig