import { Checkbox } from "@mui/material";
import GrafoButton from "../../../_generic/GrafoButton";
import { useData } from "../../_generic/contexts/DataContext";

function PaymentPlans({ templates, onChange }) {
  const { user } = useData();

  return (
    <div className="paymentPlans">
      {templates.map((t, i) => (
        <IndividualPlan template={t} onChange={onChange} key={i} user={user} />
      ))}
    </div>
  );
}

function IndividualPlan({ template, user, onChange }) {
  const isEnabled = template.isEnabled(user);

  return (
    <div>
      <div className="checkbox">
        <Checkbox
          checked={isEnabled}
          onClick={() => {
            if (!isEnabled) {
              onChange(template.key);
            }
          }}
        />
      </div>
      <div className="mainContent">
        <div className="mainTitle flexbetween">
          <div>{template.name}</div>
          <div>{template.tag && <div className="tag">{template.tag}</div>}</div>
        </div>

        <div className="price">
          £{template.price} <sup>/m</sup>
        </div>

        <div className="description">{template.description}</div>
        {!isEnabled && (
          <div>
            <GrafoButton onClick={() => onChange(template.key)}>
              {template.callToAction}
            </GrafoButton>
          </div>
        )}
        <hr className="separator" />

        <div className="featuresContainer">
          {template.features.map((f, i) => {
            return (
              <div key={i}>
                <i className="fa-solid fa-circle-check"></i>
                {f}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PaymentPlans;
