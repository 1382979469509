import { CircularProgress, Snackbar } from "@mui/material";
import * as React from "react";

const UIContext = React.createContext();

function UIProvider({ children }) {
  const [toastVisible, setToastVisible] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [successVisible, setSuccessVisible] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const showToast = (message) => {
    setToastMessage(message);
    setToastVisible(true);
  };

  const showSuccess = (message) => {
    setSuccessMessage(message);
    setSuccessVisible(true);
  };

  const value = {
    showToast,
    showLoading: () => setIsLoading(true),
    hideLoading: () => setIsLoading(false),
    showSuccess,
  };

  return (
    <UIContext.Provider value={value}>
      {children}
      {toastVisible && (
        <Toast onClose={() => setToastVisible(false)} message={toastMessage} />
      )}
      {isLoading && <LoadingScreen />}
      {successVisible && (
        <SuccessMessage
          message={successMessage}
          onHide={() => setSuccessVisible(false)}
        />
      )}
    </UIContext.Provider>
  );
}

function useUI() {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error("useUI must be used within a CountProvider");
  }
  return context;
}

function Toast({ onClose, message }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={true}
      autoHideDuration={6000}
      onClose={onClose}
      message={message}
    />
  );
}

function SuccessMessage({ message, onHide }) {
  React.useEffect(() => {
    window.setTimeout(() => {
      onHide();
    }, 2000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="mia" style={{zIndex:"500"}}>
      <div
        style={{
          backgroundColor: "rgb(94 179 94 / 94%)",
          textAlign: "center",
          color: "white",
          padding: "40px 30px",
          borderRadius: "5px",
        }}
        className="animate__fadeInUp animate__animated animate__faster"
      >
        <div>
          <i
            className="fas fa-check-circle"
            style={{ fontSize: "var(--largest)", marginBottom: "10px" }}
          ></i>
        </div>
        <div>{message || "Save successful!"}</div>
      </div>
    </div>
  );
}

function LoadingScreen() {
  return (
    <div className="darkOverlay flexmiddle" style={{zIndex:"500"}}>
      <CircularProgress />
    </div>
  );
}

export { UIProvider, useUI };
