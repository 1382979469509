import React from "react";
import isMobile from "../../../../services/isMobile";
import { useUI } from "../../../../_generic/UIContext";
import GrafoButton from "../../../_generic/GrafoButton";
import GrafoPaymentManagement from "../components/GrafoPaymentManagement";
import { useData } from "./DataContext";
import redirectToCheckout from "./helpers/redirectToCheckout";

function CheckoutOptions() {
  const data = useData();
  const UI = useUI();

  const user = data.user;

  const launchCheckout = async () => {

    try {
      UI.showLoading();
      await redirectToCheckout();
      UI.hideLoading();
    } catch (err) {
      UI.hideLoading();
      UI.showToast(err?.message || err);
    }
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          backgroundImage: "url(https://i.ibb.co/kcbXzHZ/BK.jpg)",
          padding: "20px",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div style={{ backgroundColor: "white", padding: "30px", maxWidth: isMobile ? "97vw" : "60vw", borderRadius: "10px", maxHeight: "80vh", overflowY: "auto" }}>
          {user.status && (
            <React.Fragment>
              <h2>Please update your payment details</h2>
              <div style={{ marginBottom: "30px" }}>
                We can't find an active subscription on your account. If you think
                this is a mistake, please contact us on our website. Try updating your payment details to retake the payment.
              </div>
              <GrafoPaymentManagement />
            </React.Fragment>
          )}
          {!user.status && (
            <React.Fragment
            >
              <div
                style={{
                  border: "1px solid #bebebe",
                  padding: "25px",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  overflow: "hidden"
                }}
              >
                <div style={{ position: "absolute", top: 0, right: 0, fontSize: "12px", backgroundColor: "white", color: "black", padding: "10px", cursor: "pointer", borderBottomLeftRadius: "10px" }} onClick={() => {
                  localStorage.removeItem("grafoToken");
                  localStorage.clear()
                  window.location.href = "/login";
                }}>Logout</div>
                <h2>Your Trial Has Ended</h2>
                <div style={{ marginBottom: "30px", maxWidth: "400px" }}>
                  Thanks for trialling Grafo Notes! GrafoNotes is £19.99 per month and you can cancel anytime. <br /><br />Please provide your bank
                  details to continue using our services.
                  <br />
                  <br></br>
                  <GrafoButton onClick={launchCheckout}>
                    Provide Bank Details
                  </GrafoButton>
                  <div style={{ marginTop: "15px" }}><a style={{ color: "white", fontSize: "var(--small)" }} rel="noreferrer" target="_blank" href="https://www.grafonote.com/contact">Need help? Contact Us</a></div>
                </div>
              </div>
            </React.Fragment>
          )}

        </div>

      </div>
    </div>
  );
}

export default CheckoutOptions;
