import { callAPI } from "./api";

function getUser() {
  return callAPI("/users", { method: "GET" });
}

function updateUser(updates) {
  return callAPI("/users", { method: "PUT", body: { updates } });
}

function getAllUsers(){
  return callAPI("/Admin", {method: "GET"});
}

export { getUser, updateUser, getAllUsers };
