const notesTemplateConfig = [
    {
        heading: "Client Profile",
        description: "Example:  Jo is a 38 year old man who is married and has 2 children.  He is self-employed and a plumber by trade and which means that he is doing travelling as well as working in very confined spaces which sometimes leads to strains in his low back and neck. He has a muscular build and exercises 4 times a week and does running (3 miles) and as well as weights in the gym. He has a positive outlook in life and appears to be a happy-go-lucky character."
    },
    {
        heading: "Reason For Sports Massage",
        description: "Example: Pre event massage done focusing on both lower extremities as well as back so as to prepare the muscles that were going to be used as Jo was going to do a 2 mile run."
    },
    {
        heading: "Client Feedback",
        description: "Example: (verbal) Jo reported that his legs felt ready and primed the run. (tissue) Tissues felt warmer, loose and erythema(redness) was noticed on the areas which were worked on"
    },
    {
        heading: "Home Care Advice",

        list: [
            "Maintaining good levels of hydration and the importance of re - hydration",
            "Healthy eating and info on balanced diets",
            "Rest and relaxation",
            "Immediate aftercare information provided",
            "Short-term / immediate effects of the treatment",
            "Stretching methods and techniques"
        ]
    },
    {
        heading: "Self-reflective practice & self-analysis",
        description: "Indicate aspects of treatment which you felt you did well and/or which you felt you could improve on"

    }

]

const notesTemplate = notesTemplateConfig.map(nt => {
    return "<br><br><b>" + nt.heading + "</b>" + (nt.description ? "<i><br />" + nt.description + "</i><br />" : "") + (!nt.list ? "" : "<ul>" + nt.list.map(listItem => {
        return "<li>" + listItem + "</li>"
    }).join("") + "</ul>")
}).join(" ")

console.log(notesTemplate)

export default notesTemplate