import { getAllFormTemplates } from "../../../../../services/patients";

async function getFormTemplateData(formTemplates, setFormTemplates) {
  if (formTemplates) {
    return formTemplates;
  }

  const { data, status } = await getAllFormTemplates();

  if (status !== 200) {
    throw new Error(data);
  }

  setFormTemplates(data);

  return data;
}

export default getFormTemplateData;
