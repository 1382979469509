import isMobile from "../../../../../services/isMobile";
import GrafoButton from "../../../../_generic/GrafoButton";

function CreateHeader({ tabLabels, tabIndex, patient, onHide, onSubmit }) {

  const isActive= () => {
    return patient && patient.firstName && patient.lastName;
  }
  return (
    <div
      className="header flexeven flexcenter viewPadding"
      style={{ paddingBottom: "15px" }}
    >
      <div onClick={onHide}>
        <i
          className="fas fa-times"
          style={{ cursor: "pointer" }}
          
        />{" "}
        {!isMobile && (patient.firstName && patient.lastName ? (patient?.firstName + " " + patient?.lastName) : "New Client")}
        {isMobile && "Close"}
      </div>
      {!isMobile && <div className="flexgrow" style={{ textAlign: "center" }}>
        {tabLabels[tabIndex]}
        <br />
        <span style={{fontSize:"var(--small)"}}>{tabIndex + 1} / {tabLabels.length}</span>
      </div>}
      <div style={{textAlign:"right"}}>
        <GrafoButton disabled={!isActive()} onClick={onSubmit}>Save & Exit</GrafoButton>
      </div>
    </div>
  );
}

export default CreateHeader;
