import moment from "moment";
import React from "react";
import AssessmentBox from "./AssessmentBox";

function ViewAssessmentList({ patient, onCreate, editAssessment }) {
  const assessments = !patient?.patientAssessments
    ? []
    : patient.patientAssessments.map((ass) => {
        const form = !patient.patientNotes
          ? {}
          : patient.patientForms.find(
              (f) => String(f.assessment) === String(ass._id)
            );
        const notes = !patient.patientNotes
          ? {}
          : patient.patientNotes.find(
              (n) => String(n.assessment) === String(ass._id)
            );
            
        return { assessment: ass, form, notes };
      }).sort((a, b) => moment(b.assessment.creation).format("X") - moment(a.assessment.creation).format("X"));

  return (
    <div className="assessmentBoxContainer">
      <div className="assessmentBox new" onClick={onCreate}>
        <div>+ Add New Assessment</div>
      </div>
      {assessments.map((ass, key) => (
        <AssessmentBox
          assessmentData={ass}
          key={key}
          onClick={() => editAssessment(ass)}
        />
      ))}
    </div>
  );
}

export default ViewAssessmentList;
