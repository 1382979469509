import { useEffect, useState } from "react";
import { useUI } from "../../../../_generic/UIContext";
import GrafoButton from "../../../_generic/GrafoButton";
import { useData } from "../../_generic/contexts/DataContext";
import DraggableBoxes from "./DraggableBoxes";

const defaultQuestions = [
  {
    key: new Date().valueOf(),
    label: "Default Section",
    type: "section"
  },
  {
    key: new Date().valueOf() + 1,
    label: "Type your question here",
    type: "string",
  },
  {
    key: new Date().valueOf() + 2,
    label: "Type your question here",
    type: "string",
  },
];


function FormViewMain({ id, navigate }) {

  const UI = useUI();

  const [formTitle, setFormTitle] = useState("");
  const [order, setOrder] = useState();
  const [questions, setQuestions] = useState([]);

  const data = useData();

  useEffect(() => {
    loadForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadForm = async () => {
    UI.showLoading();

    const formData = await data.getFormTemplates();

    let newQuestions = defaultQuestions;

    if (id !== "new") {
      const currentForm = formData.find((f) => f._id === id);

      if (currentForm.questions && currentForm.questions.length) {
        newQuestions = currentForm.questions;

        setFormTitle(currentForm.title || "")
      }

      if (!currentForm) {
        UI.showToast("Your form wasn't found");
        UI.hideLoading();
        navigate("/forms");
        return;
      }
    }

    setQuestions(newQuestions);

    setOrder([...Array(newQuestions.length).keys()]);

    UI.hideLoading();
  };


  const isNewForm = !!id;

  const submitForm = async () => {
    const submitObject = {
      _id: id === "new" ? null : id,
      title: formTitle,
      questions: order.map((o) => questions[o]),
    };

    UI.showLoading();
    await data.updateFormTemplate(submitObject);
    navigate("/forms");
    UI.hideLoading();
    UI.showSuccess();
  };

  return (
    <div style={{ backgroundColor: "white", padding: "20px" }}>
      <div className="header viewPadding" style={{ marginBottom: "25px" }}>
        <div className="flexcenter" style={{ paddingBottom: "30px" }}>
          <div className="title flexgrow">
            <i style={{ marginRight: "10px" }} className="fas fa-pencil blue" />
            <input
              className="noStyle"
              placeholder={"New Form Name"}
              value={formTitle}
              style={{ width: "90%" }}
              onChange={(e) => setFormTitle(e.target.value)}
            />
          </div>
          <div>
            <GrafoButton onClick={() => submitForm()}>
              <i className="fas fa-save"></i> Save
            </GrafoButton>
            {!isNewForm && (
              <GrafoButton
                style={{ backgroundColor: "#F44444", marginLeft: "40px" }}
              >
                <i className="fas fa-trash-alt"></i> Delete
              </GrafoButton>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="draggable">
          <DraggableBoxes
            data={questions}
            order={order}
            changeOrder={(newOrder) => {
              setOrder(newOrder);
            }}
            changeValue={(question, value, key) => {

              let newQuestions = questions;
              const index = newQuestions.findIndex(
                (q) => q.key === question.key
              );

              newQuestions[index][key] = value;
              setQuestions([...newQuestions]);
            }}
            onClone={(question) => {
              let newQuestions = questions;
              let questionWithNewID = { ...question, key: new Date() };
              newQuestions.push(questionWithNewID);

              setQuestions([...newQuestions]);
              setOrder([...order, order.length]);
            }}
            onRemove={(question) => {
              let newQuestions = [];

              order.forEach((o) => {
                const questionToPush = questions[o];

                if (questionToPush.key !== question.key) {
                  newQuestions.push(questions[o]);
                }
              });

              setQuestions(newQuestions);
              setOrder(
                Array.from({ length: newQuestions.length }, (v, k) => k)
              );
            }}
          />
          <div
            className="addBox"
            onClick={() => {
              setQuestions([
                ...questions,
                {
                  type: "string",
                  label: "Question Text",
                  key: new Date().valueOf(),
                },
              ]);
              setOrder([...order, order.length]);
            }}
          >
            + Add New Question
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormViewMain;
