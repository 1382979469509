const getDuration = (durationInMins) => {
  if (durationInMins < 60) {
    return durationInMins + " mins";
  }

  const hours = Math.floor(durationInMins / 60);
  const mins = durationInMins - 60 * hours;

  if (mins === 0) {
    return hours + " hours";
  }

  return hours + "h " + mins;
};

export default getDuration;
