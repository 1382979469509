import moment from "moment";

const getDaysArray = function(start, end) {
    const startDate = moment.utc(start).startOf("day");
    const endDate = moment.utc(end).startOf("day");

    let days = [];
    let currDate = moment.utc(startDate).add(1, "day");

    while(currDate.isSameOrBefore(endDate, "day")){
        days.push(currDate);
        currDate = moment.utc(currDate).add(1, "day");
    }

    return days;
};


export default getDaysArray;