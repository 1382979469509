
import { Router } from "@reach/router";
import React from "react";


function RouterManager(props){
    const routes = props.routes;

    return <Router>
        {routes.map((itm, key) => {
            const Component = itm.component;
            return <Component key={key} {...props} {...itm} />
        })}
    </Router>
}

export default RouterManager;