import moment from "moment";
import { defaultBookingTimes } from "../../../../authenticated/Settings/calendarSettings/components/defaultCalendarTimeSettings";
import timeSelectionList from "../../../../authenticated/Settings/calendarSettings/components/timeSelectionList";

function getTimesArray(timesProvided) {
  
  const list = timeSelectionList();

  let timeConfig = timesProvided || defaultBookingTimes;

  let newTimeConfig = {};

  Object.keys(timeConfig).forEach(dayName => {
    const timeConfigForDay = timeConfig[dayName];

    if(!timeConfigForDay || !timeConfigForDay.length){
        return [];
    }

    newTimeConfig[dayName] = getTimeListForDay(list, timeConfigForDay)
  });

  return newTimeConfig;
}

function getTimeListForDay(timeList, dayTimeConfig){
    return timeList.filter(tl => {
        const timeRowValue = setTimeOnUTCDate(tl.value)

        return dayTimeConfig.some(dtc => {
            const start = setTimeOnUTCDate(dtc.start)
            const end = setTimeOnUTCDate(dtc.end)

            return timeRowValue.isSameOrAfter(start, "minutes") && timeRowValue.isSameOrBefore(end, "minutes")
        });
    })
}

function setTimeOnUTCDate(timeVal){
    return moment.utc().set({hours: timeVal.substring(0, 2), minutes: timeVal.substring(3, 5)})
}

export default getTimesArray;
