import ChangePassword from "../pages/ChangePassword";
import ForgotPassword from "../pages/ForgotPassword";
import Login from "../pages/Login";
import PatientCalendarBooking from "../pages/PatientCalendarBooking/PatientCalendarBooking";
import PatientOnboarding from "../pages/PatientOnboarding/PatientOnboardingMain";
import Signup from "../pages/Signup";

const unauthenticatedRoutes = [
    {
        path: "/login",
        component: Login,
        
    },
    {
        path: "/signup",
        component: Signup,
        default: true
    },
    {
        path: "/new",
        component: PatientOnboarding,
    },
    {
        path: "/forgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/changePassword",
        component: ChangePassword,
    },
    {
        path: "/bookings",
        component: PatientCalendarBooking
    }
];

export default unauthenticatedRoutes;