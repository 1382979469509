
import assessmentConfig from "../assessmentConfig";
import GenericAssessmentField from "./components/GenericAssessmentFields";
import PainAssessmentField from "./components/PainAssessmentField";

function AssessmentBox({ onChange, onRemove, element, elements }) {
  

  const data = element.data;

  const color = data.color || assessmentConfig.colors[0].color;

  const getConfig = (color) => {
    return assessmentConfig.colors.find((c) => c.color === color);
  };

  const config = getConfig(color);

  const symptoms = element.symptoms || "";

  const type = config?.key || "pain";
  const name = config?.name || "Pain";

  const getPlotNumber = () => {
    return (
      elements
        .filter((k) => k.data.color === color)
        .findIndex(({ id }) => id === element.id) + 1
    );
  };

  let EditableComponent =
    type === "pain" ? PainAssessmentField : GenericAssessmentField;

  return (
    <div className="assessmentDescription flex">
      <div>
        <div className={"flex"}>
          <div>
            <div className="circle color" style={{ backgroundColor: color }} />
          </div>
          <div>
            <div className="title" style={{ color }}>
              Plot {getPlotNumber()}
              <div className="painType">{name}</div>
            </div>
          </div>
        </div>
        <textarea
          style={{
            backgroundColor: "#FAFAFA",
            marginTop: "10px",
            fontFamily: "inherit",
            height: "80px",
            width: "250px",
            border: "1px solid #bebebe",
            fontSize: "11px",
          }}
          value={symptoms}
          onChange={(e) => onChange({symptoms: e.target.value})}
          placeholder="Symptoms"
        />
      </div>

      <div className="flexgrow flex ratingBox"  >
        <div className="flexgrow">
          <div style={{ fontSize: "11px", fontWeight: 600, color }}>
            {name} Rating
          </div>
          <EditableComponent color={color} onChange={onChange} data={element} />
        </div>

        <div className="remove" onClick={onRemove}>
          <i className="fas fa-trash-alt" />
        </div>
      </div>
    </div>
  );
}

export default AssessmentBox;
