import moment from "moment";
import { cancelCalendarEntryWithAuth, createCalendarEntry } from "../../../../../services/calendar";

async function loadServicesForAppointments(
  setServices,
  defaultValue,
  dataContext,
  UI,
  onSelect
) {
  try {
    const services = await dataContext.getServices();
    setServices(services);
    onSelect(defaultValue || services[0]?._id, services);
  } catch (err) {
    UI.showToast(
      err.message || "We couldn't load your services, please contact support"
    );
  }
}

async function cancelAppointment(_id, UI){
  UI.showLoading();
  const {data, status} = await cancelCalendarEntryWithAuth(_id);
  UI.hideLoading();

  if(status !== 200){
    UI.showToast(data?.error || data?.message || "We couldn't cancel your invite due to an error, please contact support")
    return
  }

  window.location = window.location.href;
}

async function createAppointment(appointment, UI) {
   
  UI.showLoading();
  const { data, status } = await createCalendarEntry(appointment);
  UI.hideLoading();

  if (status !== 200) {
    UI.showToast(
      data?.error ||
        data?.message ||
        "There was an error in creating this appointment, please try again or contact support"
    );
    return;
  }

  window.location = window.location.href;

  return data;
}

function editAppointmentTime(
  val,
  startDate,
  setStartDate,
  setDurationInMins,
  service
) {
  const { start, end } = val[0];

  const newStart = moment.utc(startDate).set({
    hours: start.substring(0, 2),
    minutes: start.substring(3, 5),
  });
  const newEnd = moment.utc(startDate).set({
    hours: end.substring(0, 2),
    minutes: end.substring(3, 5),
  });

  setStartDate(newStart);

  let endInMins = newEnd.diff(newStart, "minutes");

  if (!moment.utc(startDate).isSame(newStart) && service?.durationInMins) {
    endInMins = service?.durationInMins;
  }

  setDurationInMins(endInMins);
}

export { cancelAppointment, loadServicesForAppointments, createAppointment, editAppointmentTime };
