import { MenuItem, Select } from "@mui/material";

function CurrencySelector({ onChange, currency, currencies }) {
  return (
    <div className="currencyContainer">
      <div>Currency</div>
      <Select
        value={currency}
        onChange={(e) => onChange(e.target.value)}
        className="currencySelection"
      >
        {currencies.map((c) => {
          return (
            <MenuItem key={c.key} value={c.key}>
              {c.name} ({c.key})
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}

export default CurrencySelector;
