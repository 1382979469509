import React from "react";
import GrafoPaymentManagement from "../../_generic/components/GrafoPaymentManagement";
import { useData } from "../../_generic/contexts/DataContext";
import includedOptions from "../profile/components/includedOptions";
import SettingSection from "../_generic/SettingsSection";
import ChangePlan from "./ChangePlan";

function PaymentDetails() {
  const { user } = useData();

  return (
    <React.Fragment>
      <SettingSection title="Your Plan">
        <div className="flex">
          <div>
            <GrafoPaymentManagement />
          </div>
          <div className="includedInPlan">
            <div className="subTitle">Included in your plan</div>
            {includedOptions.map((io) => (
              <div className="planBenefits" key={io.label}>
                <i className={"fa-fw " + io.icon} />
                {io.label}
              </div>
            ))}
            {user.isCalendarEnabled && (
              <div className="planBenefits">
                <i className="fa-fw fa-solid fa-calendar-days" />
                Calendar & Bookings
              </div>
            )}
          </div>
        </div>
      </SettingSection>
      {user.status && <ChangePlan />}
    </React.Fragment>
  );
}

export default PaymentDetails;
