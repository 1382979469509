
import { callAPI } from "./api";

async function getPhysioDetails(physio, form) {
  return callAPI("/patientSubmission?physio=" + physio + "&form=" + form, {
    method: "GET",
  });
}

async function submitPhysioDetails(details) {
  return callAPI("/patientSubmission", {
    method: "POST",
    body: details
  });
}

export {getPhysioDetails, submitPhysioDetails}
