
import AssessmentDescriptionMain from "./assessmentDescription/AssessmentDescription";
import { useState } from "react";
import assessmentConfig from "./assessmentConfig";
import React from "react";
import { TextareaAutosize } from "@mui/material";

import BodyMapV2 from "./circleMapperV2/BodyMap";
import isMobile from "../../../../../services/isMobile";
import NewAssessments from "../newAssessments/NewAssessments";
import { useData } from "../../../_generic/contexts/DataContext";

function AssessmentTemplate({ assessment, onChange }) {

  console.log({ assessment })

  const { user } = useData();


  const templates = assessmentConfig.templates;

  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
  const allElements = assessment?.painPoints || [];
  const title = assessment?.title || "";
  const primaryComplaint = assessment?.primaryComplaint || "";


  const currentTemplate = templates[selectedTemplateIndex];
  const currentKey = currentTemplate.key;
  const currentElements = allElements.filter(
    (itm) => itm.template === currentKey
  );

  const changeValue = (key, value) => {
    console.log({ key, value })
    let newValue = assessment;
    newValue[key] = value;
    onChange({ ...newValue });
  };

  const onElementChange = (newvalues) => {
    const oldElements = allElements.filter(
      (itm) => itm.template !== currentKey
    );

    const newElements = [
      ...oldElements,
      newvalues.map((itm) => {
        return { ...itm, template: currentKey };
      }),
    ].flat();

    changeValue("painPoints", newElements);
  };


  if (assessment?.templateVersion === "massage") {
    return <NewAssessments assessment={assessment} onChange={changeValue} patientTypes={[assessment?.templateVersion]} />
  }

  return (
    <React.Fragment>
      <div className="flex bodyMapContainer">
        <BodyMapV2
          onIndexChange={setSelectedTemplateIndex}
          onChange={onElementChange}
          elements={allElements}
          templates={templates}
          selectedIndex={selectedTemplateIndex}
          currentTemplate={currentTemplate}
        />
        <div className="flexgrow innerBodyMap">
          <div className="flex">
            <div>
              <i className="fas fa-pencil" style={{ color: "var(--blue)" }} />
            </div>{" "}
            <div className="flexgrow">
              <input
                value={title}
                onChange={(e) => changeValue("title", e.target.value)}
                style={{
                  width: "100%",
                  marginBottom: "15px",
                  border: "0px",
                  outline: "none",
                  fontFamily: "inherit",
                  fontWeight: "600",
                  fontSize: "var(--large)",
                }}
                placeholder="Assessment Title"
              />
            </div>
          </div>
          <AssessmentDescriptionMain
            currentTemplate={currentTemplate}
            elements={currentElements}
            onChange={onElementChange}
          />
        </div>
      </div>
      <TextareaAutosize
        placeholder={"History of Primary Complaint"}
        style={{
          width: isMobile ? "93%" : "98%",
          height: "150px",
          padding: "10px",
          fontFamily: "inherit",
          borderRadius: "5px",
          border: "1px solid #dedede",
          backgroundColor: "#FBFEFF",
          marginTop: "30px",
        }}
        value={primaryComplaint}
        onChange={(e) => changeValue("primaryComplaint", e.target.value)}
      />
    </React.Fragment>
  );
}

export default AssessmentTemplate;
