function DBSection({ item, changeValue }) {
	const currData = item.data || {};

	return (
		<div className="flexgrow">
			<div>
				<div>Section Title: </div>
				<input
					className="noStyle"
					style={{ fontWeight: "bold", fontSize: 17 }}
					value={item.label}
					onChange={(e) => changeValue(item, e.target.value, "label")}
				/>
			</div>
		</div>
	);
}

export default DBSection;
