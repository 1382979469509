function searchForValue(value="", data=[], keysToSearch=[]){
    if(!value){
        return data;
    }
    
    return data.filter((itm) => {
        return keysToSearch.some(key => doesMatch(value, itm[key]));
    });
}

function doesMatch(valueToSearch, value){
    if(!value || !valueToSearch){
        return false;
    }

    const lowercaseValue = value.toLowerCase();
    const lowercaseValueToSearch = valueToSearch.toLowerCase();

    return lowercaseValueToSearch.indexOf(lowercaseValue) > -1 || lowercaseValue.indexOf(lowercaseValueToSearch) > -1
}

export default searchForValue;