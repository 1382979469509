import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useUI } from "../../../../../_generic/UIContext";
import { useData } from "../../../_generic/contexts/DataContext";
import { loadServicesForAppointments } from "./editAppointmentLogic";

function SelectServices({ readOnly, defaultValue, allowNone, onChange }) {
  const data = useData();
  const [services, setServices] = useState();
  const [selected, setSelected] = useState();
  const UI = useUI();

  const onSelect = (id, useTheseServicesInstead) => {

    const servicesToLook = useTheseServicesInstead || services;

    const selectedService = id === "none" ? {_id: "none"} : servicesToLook.find((s) => s._id === id);


    onChange(selectedService);
    setSelected(selectedService);
  };

  useEffect(() => {
    loadServicesForAppointments(
      setServices,
      defaultValue,
      data,
      UI,
      onSelect,
    );
    // eslint-disable-next-line
  }, []);

  const findLabel = (id) => {
    const found = services.find((s) => s._id === id);

    if (!found) {
      return "Other (custom)";
    }

    return found.title;
  };

  return (
    <Select
      placeholder="Select Service"
      fullWidth
      onChange={(e) => {
        onSelect(e.target.value);
      }}
      displayEmpty={true}
      renderValue={(answer) =>
        !answer ? (
          <span style={{ color: "grey" }}>Select a service</span>
        ) : (
          findLabel(answer)
        )
      }
      readOnly={readOnly}
      value={selected?._id || ""}
    >
      {services &&
        services.map((s) => <MenuItem key={s._id} value={s._id}>{s.title}</MenuItem>)}
      {allowNone && <MenuItem value={"none"}>Other (Custom)</MenuItem>}
    </Select>
  );
}

export default SelectServices;
