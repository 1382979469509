import { ThemeProvider } from "@mui/material";
import React from "react";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import themeOptions from "./_generic/themeOptions";
import { UIProvider } from "./_generic/UIContext";
import { createRoot } from 'react-dom/client';


const container = document.getElementById('App');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <ThemeProvider theme={themeOptions}><UIProvider><App /></UIProvider></ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
