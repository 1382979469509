import RouterManager from "../../_generic/RouteManager";
import unauthenticatedRoutes from "./config/routes";
import React from "react";

function UnauthenticatedContainer() {
  const routes = unauthenticatedRoutes;

  return <RouterManager routes={routes} />;
}

export default UnauthenticatedContainer;
