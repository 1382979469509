const CustomNodeComponent = ({ data }) => {
    return (
      <div className="draggableCircle" style={{ backgroundColor: data.color, width: "30px", height: "30px" }}></div>
    );
  };

  const nodeTypes = {
    special: CustomNodeComponent,
  };

  export {CustomNodeComponent, nodeTypes}