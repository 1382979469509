import assessmentConfig from "../assessments/assessmentConfig";
import MassageAssessmentSummaryPain from "./MassageAssessmentSummaryPain";

function AssessmentSummaryPain({ assessment }) {
  const getConfig = (color) => {
    return assessmentConfig.colors.find((c) => c.color === color);
  };

  if (assessment?.templateVersion === "massage") {
    return <MassageAssessmentSummaryPain assessment={assessment} />
  }

  return (
    <div>
      {assessment.painPoints &&
        assessment.painPoints.map((as, i) => (
          <IndividualSummaryPain key={i} config={getConfig(as?.data?.color)} row={as} />
        ))}
    </div>
  );
}

function IndividualSummaryPain({ row, config }) {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div
      style={{
        borderBottom: "1px solid grey",
        paddingBottom: "10px",
        marginBottom: "10px",
      }}
    >
      <div>
        <b style={{ color: config.color }}>
          {config.name || row.symptoms || "Pain"}
        </b>
        {row.painType ? ": " + row.painType : ""}
      </div>
      {row.reason && (
        <div>
          <b>Reason</b>: {row.reason}
        </div>
      )}
      {row.severity && (
        <div>
          <b>Severity</b>: {row.severity}
        </div>
      )}
      {row.fields && (
        <div>
          {Object.keys(row.fields).map((k) => {
            const keyrow = row.fields[k];
            return (
              <div key={k}>
                <b>{capitalizeFirstLetter(k)}</b> - Rating: {keyrow?.value},
                Reason: {keyrow?.reason}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default AssessmentSummaryPain;
