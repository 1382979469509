import isMobile from "../../../../../services/isMobile";
import GrafoButton from "../../../../_generic/GrafoButton";

function POBookNow({onNext}) {
  return (
    <div className="poBookNow">
      <GrafoButton fullWidth={isMobile} onClick={onNext}>Book Now</GrafoButton>
    </div>
  );
}

export default POBookNow;
