import { FormControl, TextField } from "@mui/material";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import GrafoButton from "../../../../_generic/GrafoButton";

function CopyBookingURL({userID, UI}){

    const url = window.location.origin + "/bookings?id="+ userID;

   return (<React.Fragment>

      Provide this URL to your patients to allow them to directly book into your calendar.
      <br />
      <br />
      <div className="flexeven">
        <FormControl>
          <TextField value={url} style={{ marginRight: "15px" }} />
          <CopyToClipboard
            options={{ debug: true }}
            text={
              "Use my booking link, provided by GrafoNotes! " +
              url
            }
            onCopy={() => UI.showSuccess("Copied!")}
          >
            <GrafoButton className="grafoButton buttonRight">Copy</GrafoButton>
          </CopyToClipboard>
        </FormControl>
      </div>
    </React.Fragment>
  );
}

export default CopyBookingURL;