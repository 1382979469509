import { Button, Checkbox, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import GrafoButton from "../../../_generic/GrafoButton";

function SelectModal({ item, onHide, onChange }) {
	const data = item.data || [];

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		maxHeight: "90%",
		overflowY: "auto",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
	};

	const changeEntry = (key, entry, propertyName = "value") => {
		let newData = data;
		newData.find((nd) => nd.key === key)[propertyName] = entry;
		onChange(newData);
	};

	const addEntry = (entry) => {
		let newData = data;
		newData.unshift({
			key: new Date().valueOf(),
			value: entry,
		});

		onChange(newData);
	};

	const removeEntry = (key) => {
		let newData = data;

		newData = newData.filter((d) => d.key !== key);

		onChange(newData);
	};

	return (
		<Modal
			open={true}
			onClose={onHide}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description">
			<Box sx={style}>
				<div className="flexbetween">
					<div>
						<GrafoButton onClick={() => addEntry("")}>Add Choice +</GrafoButton>
					</div>
					<div></div>
				</div>
				{data.map((d) => {
					let defaultButtonClasses = {};

					if (d.default) {
						defaultButtonClasses = {
							color: "white",
							backgroundColor: "var(--blue)",
						};
					}
					return (
						<div
							key={d.key}
							className="flexcenter"
							style={{ marginTop: 15 }}>
							<div
								className="flexgrow"
								style={{ position: "relative" }}>
								<TextField
									fullWidth
									placeholder="Enter a choice"
									value={d.value}
									onChange={(e) => changeEntry(d.key, e.target.value)}
									size="small"
								/>
								<i
									className="fas fa-times-circle"
									style={{
										color: "red",
										position: "absolute",
										cursor: "pointer",
										fontSize: "25px",
										padding: "15px",
										top: -8,
										right: -5,
									}}
									onClick={() => removeEntry(d.key)}
								/>
							</div>
							<div style={{ marginLeft: 10 }}>
								<Button
									variant="outlined"
									onClick={() => {
										changeEntry(d.key, !d.default, "default");

										if (item.type !== "selection") {
											return;
										}

										const otherExisting = data.find(
											(dat) => dat.key !== d.key && dat.default
										);

										if (otherExisting) {
											changeEntry(otherExisting.key, false, "default");
										}
									}}
									style={{
										...defaultButtonClasses,
										paddingTop: 7,
										paddingBottom: 7,
									}}>
									Default
								</Button>
							</div>
						</div>
					);
				})}
				<div style={{ marginTop: 20 }}>
					<Checkbox
						onChange={() => {}}
						checked={item.notes}
						style={{ marginRight: -5, marginLeft: -10 }}
					/>
					Allow additional notes
				</div>
			</Box>
		</Modal>
	);
}

export default SelectModal;
