import moment from "moment";
import { useState } from "react";
import GrafoButton from "../../../../../_generic/GrafoButton";
import assessmentConfig from "../assessmentConfig";
import BodyMapV2 from "../circleMapperV2/BodyMap";
import MassageAssessmentBox from "./MassageAssessmentBox/MassageAssessmentBox";

function AssessmentBox({ assessmentData, onClick }) {
  const assessment = assessmentData.assessment;
  const [index, setIndex] = useState(0);

  const templates = assessmentConfig.templates;
  const currentTemplate = templates[index];

  const templateVersion = assessment?.templateVersion;

  console.log({ assessment })

  return (
    <div className="assessmentBox">
      <div className="date">
        {moment.utc(assessment.creation).format("Do MMMM YYYY")}
      </div>
      <div className="title">
        {assessment.title || moment.utc(assessment.creation).fromNow()}
      </div>
      <div className="bodymapcontainer">
        {templateVersion === "massage" ? <MassageAssessmentBox assessment={assessment} /> : <BodyMapV2
          onIndexChange={setIndex}
          compressedView={true}
          elements={assessment.painPoints.filter(
            (el) => el.template === currentTemplate.key
          )}
          templates={templates}
          selectedIndex={index}
          currentTemplate={currentTemplate}
        />}
      </div>
      <GrafoButton
        style={{ marginTop: "15px" }}
        onClick={onClick}
        fullWidth={true}
      >
        <i className="fas fa-pencil"></i> Edit Assessment
      </GrafoButton>
    </div>
  );
}

export default AssessmentBox;
